<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-content-wrapper">
        <main id="js-page-content" role="main" class="page-content">
          <div class="d-flex flex-grow-1 p-0">
            <div
              class="flex-wrap flex-shrink-0 position-relative slide-on-mobile slide-on-mobile-left bg-body p-3 bg-white mr-2"
              id="js-slide-left"
            >
              <div
                class="row fullsize-table"
                style="overflow-x: hidden; overflow-y: auto"
              >
                <div class="col-12 bg-body stickTop">
                  <div class="col-12 p-0">
                    <button
                      class="btn btn-info w-100"
                      id="clientes-new"
                      @click="
                        showFormChamado = true;
                        loadingModalChamado = false;
                      "
                    >
                      <i class="fal fa-1x fa-plus mr-1"></i> Novo Atendimento
                    </button>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <form
                    @submit.prevent="getAtendimentos"
                    id="filterForm"
                    class="row"
                  >
                    <div class="col-12 mt-2">
                      <input
                        type="search"
                        placeholder="Pesquisar por Palavra-Chave"
                        autocomplete="off"
                        class="form-control"
                        data-table-api="search"
                        id="filter-search"
                        v-model="searchQuery"
                      />
                    </div>
                    <div class="col-12 mt-2">
                      <select
                        class="select2 form-control"
                        id="setorAtendimentos"
                        v-model="searchFilterSetor"
                        v-select="searchFilterSetor"
                      >
                        <option value="">Todos os Setores</option>
                        <option
                          v-for="setor in setores.values"
                          :key="setor.ID"
                          :value="setor.ID"
                        >
                          {{ setor.NOME }}
                        </option>
                      </select>
                    </div>

                    <div class="col-12 mt-2">
                      <select
                        class="select2 form-control"
                        id="UrgenciaUrgencia"
                        v-model="searchFilterUrgencia"
                        v-select="searchFilterUrgencia"
                      >
                        <option value="">Todas Urgências</option>
                        <option value="1">Urgência Baixa</option>
                        <option value="2">Urgência Média</option>
                        <option value="3">Urgência Alta</option>
                      </select>
                    </div>
                    <div class="col-12 mt-2">
                      <select
                        class="select2 form-control"
                        id="clientesClientes"
                        v-model="searchFilterCliente"
                        v-select="searchFilterCliente"
                      >
                        <option value="">Selecione o cliente</option>
                        <option
                          v-for="client in clientes.values"
                          :value="client.ID"
                          :key="client.ID"
                        >
                          {{ client.NOME }}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 mt-2">
                      <select
                        class="select2 form-control"
                        id="situacaoAtendimentos"
                        v-model="searchFilterSituacao"
                        v-select="searchFilterSituacao"
                      >
                        <option value="">
                          Todas Situações de Atendimentos
                        </option>
                        <option value="1">Atendimentos Abertos</option>
                        <option value="3">Atendimentos Em Andamento</option>
                        <option value="2">Atendimentos Fechados</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div class="col-12 stickBottom bg-body mt-2">
                  <div class="row mt-2">
                    <div class="col-8">
                      <button
                        class="btn btn-success mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        type="submit"
                        form="CTable-Filter-Form"
                        id="btn-filter"
                        @click="getAtendimentos"
                      >
                        <i class="fal fa-search mr-1"></i>
                        Pesquisar
                      </button>
                    </div>
                    <div class="col-4">
                      <button
                        class="btn btn-light mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        data-toggle="tooltip"
                        title=""
                        data-placement="top"
                        type="button"
                        id="btn-clear-filter"
                        data-original-title="Limpar Filtro"
                        @click="clearFilter"
                      >
                        <i class="fal fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="slide-backdrop"
                data-action="toggle"
                data-class="slide-on-mobile-left-show"
                data-target="#js-slide-left"
              ></div>
            </div>
            <div class="d-flex flex-column flex-grow-1 bg-white">
              <div class="row h-100">
                <div class="col-12">
                  <div class="panel h-100">
                    <div class="panel-hdr">
                      <h2>
                        <i class="subheader-icon fal fa-comment"></i>
                        <span class="fw-300">Atendimentos</span>
                      </h2>
                    </div>
                    <div
                      class="panel-container show custom-height-kamban h-100"
                    >
                      <div class="panel-content h-100">
                        <div v-if="!pesquisaRealizada">
                          <AguardandoPesquisa />
                        </div>
                        <div v-else class="h-100">
                          <div
                            v-if="!loading"
                            class="row h-100"
                            style="height: 100%"
                          >
                            <div class="col-4 h-100">
                              <div
                                class="w-100 p-1"
                                style="
                                  overflow: auto;
                                  width: auto;
                                  height: 100%;
                                "
                              >
                                <div>
                                  <h5 class="m-0">Abertos</h5>
                                  <hr class="m-0" />
                                </div>
                                <div
                                  v-if="computedAtendimentos.abertos.length > 0"
                                >
                                  <ul
                                    id="js-msgr-listfilter"
                                    class="list-unstyled m-0 js-list-filter py-2"
                                  >
                                    <AtendimentoCardList
                                      v-for="atendimento in computedAtendimentos.abertos"
                                      :atendimento="atendimento"
                                      :key="atendimento.id"
                                      @edit="editAtendimento"
                                    />
                                  </ul>
                                </div>
                                <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
                                <NenhumAtendimentoEncontado2x v-else />
                              </div>
                            </div>
                            <div class="col-4 h-100 overflow-auto">
                              <div
                                class="w-100 p-1"
                                style="
                                  overflow: auto;
                                  width: auto;
                                  height: 100%;
                                "
                              >
                                <div>
                                  <h5 class="m-0">Em Andamento</h5>
                                  <hr class="m-0" />
                                </div>
                                <div
                                  v-if="
                                    computedAtendimentos.em_andamento.length > 0
                                  "
                                >
                                  <ul
                                    id="js-msgr-listfilter"
                                    class="list-unstyled m-0 js-list-filter py-2"
                                  >
                                    <AtendimentoCardList
                                      v-for="atendimento in computedAtendimentos.em_andamento"
                                      :atendimento="atendimento"
                                      :key="atendimento.id"
                                      @edit="editAtendimento"
                                    />
                                  </ul>
                                </div>
                                <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
                                <NenhumAtendimentoEncontado2x v-else />
                              </div>
                            </div>
                            <div class="col-4 h-100">
                              <div
                                class="w-100 p-1"
                                style="
                                  overflow: auto;
                                  width: auto;
                                  height: 100%;
                                "
                              >
                                <div>
                                  <h5 class="m-0">Fechados</h5>
                                  <hr class="m-0" />
                                </div>
                                <div
                                  v-if="
                                    computedAtendimentos.fechados.length > 0
                                  "
                                >
                                  <ul
                                    id="js-msgr-listfilter"
                                    class="list-unstyled m-0 js-list-filter py-2"
                                  >
                                    <AtendimentoCardList
                                      v-for="atendimento in computedAtendimentos.fechados"
                                      :atendimento="atendimento"
                                      :key="atendimento.id"
                                      @edit="editAtendimento"
                                    />
                                  </ul>
                                </div>
                                <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
                                <NenhumAtendimentoEncontado2x v-else />
                              </div>
                            </div>
                          </div>
                          <Loading v-else />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>

  <ModalCenteredLarge
    :show="showFormChamado"
    :title="
      currentChamado
        ? 'Editar Atendimento ' + currentChamado
        : 'Novo Atendimento'
    "
    @close="hideForm()"
    @save="submitChamado"
    :loading="loadingModalChamado"
  >
    <ChamadoForm
      ref="chamadoFormRef"
      :phone="contact?.phone"
      :modelValue="currentChamado"
      :newCliente="clienteAtual"
      @toggle="toggleModalCliente"
      @showLightBox="toggleLightBox"
      :submitChamado="submitChamado"
      @editCliente="editCliente"
    />
  </ModalCenteredLarge>

  <ModalCenteredLarge
    :show="showFormCliente"
    :title="currentCliente ? 'Editar Cliente' : 'Novo Cliente'"
    :loading="loadingModalCliente"
    @save="submitCliente"
    @close="hideFormCliente()"
  >
    <ClientesForm
      ref="clientesFormRef"
      :modelValue="currentCliente"
      @newCliente="hideFormCliente"
    />
  </ModalCenteredLarge>

  <vue-easy-lightbox
    :visible="lightboxVisible"
    :imgs="lightboxImages"
    @hide="lightboxVisible = false"
  />
</template>

<script>
/**
 Este código importa vários componentes e bibliotecas do Vue.js que são utilizados no componente HomePage.vue. Os componentes incluem:

 - Loading: Um componente indicador de carregamento.
 - AtendimentoCardList: Um componente que exibe uma lista de solicitações de serviço.
 - ChamadoForm: Um componente de formulário para criar ou editar solicitações de serviço.
 - ModalCenteredLarge: Um componente modal para exibir formulários e outros conteúdos.
 - AguardandoPesquisa: Um componente que exibe uma mensagem enquanto aguarda uma pesquisa.
 - ClientesForm: Um componente de formulário para criar ou editar clientes.

 O código também importa a biblioteca Axios para fazer requisições HTTP e a biblioteca jQuery para manipulação do DOM.
 */
import { ref, reactive } from "vue";
import Loading from "../generals/LoadingDefault.vue";
import { api as api } from "@/axios.js";
import AtendimentoCardList from "./AtendimentoCardList.vue";
import ChamadoForm from "@/components/Home/Forms/ChamadoForm.vue";
import ModalCenteredLarge from "../modals/ModalCenteredLarge.vue";
import AguardandoPesquisa from "../generals/AguardandoPesquisa.vue";
import $ from "jquery";
import ClientesForm from "@/components/Clients/forms/ClientesForm.vue";
import NenhumAtendimentoEncontado2x from "../generals/NenhumAtendimentoEncontado2x.vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  /**
   * Importa e registra vários componentes do Vue.js utilizados no componente HomePage.vue.
   * - Loading: Um componente indicador de carregamento.
   * - AtendimentoCardList: Um componente que exibe uma lista de solicitações de serviço.
   * - ChamadoForm: Um componente de formulário para criar ou editar solicitações de serviço.
   * - ModalCenteredLarge: Um componente modal para exibir formulários e outros conteúdos.
   * - AguardandoPesquisa: Um componente que exibe uma mensagem enquanto aguarda uma pesquisa.
   * - ClientesForm: Um componente de formulário para criar ou editar clientes.
   */
  components: {
    VueEasyLightbox,
    Loading,
    AtendimentoCardList,
    ChamadoForm,
    ModalCenteredLarge,
    AguardandoPesquisa,
    ClientesForm,
    NenhumAtendimentoEncontado2x,
  },
  /**
   * Define as propriedades de dados para o componente HomePage.
   * @returns {Object} - Um objeto contendo as propriedades de dados para o componente.
   * - atendimentos: Um array de solicitações de serviço.
   * - clientes: Um array de clientes.
   * - setores: Um array de departamentos.
   * - loading: Um booleano indicando se o componente está carregando.
   * - pesquisaRealizada: Um booleano indicando se uma pesquisa foi realizada.
   * - showFormChamado: Um booleano indicando se o formulário de solicitação de serviço deve ser exibido.
   * - currentChamado: A solicitação de serviço atualmente selecionada.
   * - currentCliente: O cliente atualmente selecionado.
   * - chamadoFormRef: Uma referência ao componente de formulário de solicitação de serviço.
   * - clientesFormRef: Uma referência ao componente de formulário de cliente.
   * - searchQuery: A consulta de pesquisa inserida pelo usuário.
   * - searchFilterSetor: O filtro de departamento selecionado pelo usuário.
   * - searchFilterSituacao: O filtro de status selecionado pelo usuário.
   * - searchFilterUrgencia: O filtro de urgência selecionado pelo usuário.
   * - searchFilterCliente: O filtro de cliente selecionado pelo usuário.
   * - showFormCliente: Um booleano indicando se o formulário de cliente deve ser exibido.
   */
  data() {
    return {
      loadingModalChamado: true,
      loadingModalCliente: true,
      lightboxImages: [],
      lightboxVisible: false,
      clienteAtual: "",
      atendimentos: reactive([]),
      clientes: reactive([]),
      setores: reactive([]),
      loading: ref(false),
      pesquisaRealizada: ref(false),
      showFormChamado: ref(false),
      currentChamado: ref(null),
      currentCliente: ref(null),
      chamadoFormRef: ref(null),
      clientesFormRef: "",
      searchQuery: ref(""),
      searchFilterSetor: ref(""),
      searchFilterSituacao: ref(""),
      searchFilterUrgencia: ref(""),
      searchFilterCliente: ref(""),
      showFormCliente: ref(false),
    };
  },
  /**
   * Calcula um objeto com três arrays representando os diferentes status dos atendimentos (solicitações de serviço).
   * O objeto possui as seguintes propriedades:
   * - abertos: Um array de atendimentos com status_id 1 (abertos).
   * - em_andamento: Um array de atendimentos com status_id 2 (em andamento).
   * - fechados: Um array de atendimentos com status_id 3 (fechados).
   * @returns {Object} Um objeto com os arrays de atendimentos calculados.
   */
  computed: {
    computedAtendimentos() {
      let arrAtendimentos = {
        abertos: [],
        em_andamento: [],
        fechados: [],
      };

      this.atendimentos.values.forEach((element) => {
        const { status_id } = element;
        switch (status_id) {
          case 1:
            arrAtendimentos.abertos.push(element);
            break;
          case 3:
            arrAtendimentos.em_andamento.push(element);
            break;
          case 2:
            arrAtendimentos.fechados.push(element);
            break;
          default:
            break;
        }
      });
      return arrAtendimentos;
    },
  },
  methods: {
    /**
     * Alterna a visibilidade do formulário de cliente.
     * Quando o formulário é exibido, a propriedade `currentCliente` é definida como `null`.
     * Quando o formulário é oculto, o método `editAtendimento` é chamado com a propriedade `currentChamado`.
     */
    toggleModalCliente() {
      this.showFormCliente = true;

      setTimeout(() => {
        this.loadingModalCliente = false;
      }, 1000);
    },

    editCliente(clientPhoneId) {
      const [phone, clientId] = clientPhoneId.split("-");
      this.showFormCliente = true;
      this.currentCliente = clientId;

      this.$nextTick(() => {
        this.$refs.clientesFormRef.getCliente(clientId).then(() => {
          this.loadingModalCliente = false;
        });
      });
    },

    /**
     * Oculta o formulário de cliente.
     * Quando o formulário é oculto, a propriedade `showFormCliente` é definida como `false`,
     * e o método `editAtendimento` é chamado com a propriedade `currentChamado`.
     */
    async hideFormCliente(valorClienteNovo) {
      this.showFormCliente = false;
      this.currentCliente = null;
      this.loadingModalCliente = true;

      await this.$refs.chamadoFormRef.getClientes();

      console.log("valor do cliente", valorClienteNovo);
      this.clienteAtual = valorClienteNovo;
    },

    /**
     * Envia o formulário de cliente.
     * Se a propriedade `clientesFormRef` estiver disponível e tiver um método `submitForm`,
     * esse método é chamado. Caso contrário, um erro é registrado.
     */
    submitCliente() {
      const clienteForm = this.$refs.clientesFormRef;
      if (clienteForm && typeof clienteForm.submitForm === "function") {
        clienteForm.submitForm().then((response) => {
          this.$refs.chamadoFormRef.getClientes();
          this.showFormCliente = false;
        });
      } else {
        console.error("submitForm não está disponível no chamadoForm");
      }
    },

    /**
     * Recupera a lista de clientes da API e os armazena na propriedade `clientes.values`.
     * Se a solicitação da API for bem-sucedida, os dados da resposta são registrados no console.
     * Se houver um erro, o erro é registrado no console.
     */
    async getClientes() {
      try {
        const response = await api.get(`/api/clientes`);
        if (response.data.success) {
          console.log(
            "%c✔ Requisição feita com sucesso! Os dados que irão preencher o select serão:",
            "color: #148f32"
          );
          console.table(response.data.data);
          console.log("Dados que voltaram da requisição:", response.data.data);
        }
        this.clientes.values = response.data.data;
      } catch (e) {
        console.log(
          "%c Erro: ",
          "color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);",
          e
        );
      }
    },

    /**
     * Recupera a lista de setores da API e os armazena na propriedade `setores.values`.
     * Se a solicitação da API for bem-sucedida, os dados da resposta são registrados no console.
     * Se houver um erro, o erro é registrado no console.
     */
    async getSetores() {
      try {
        const response = await api.get(`/api/setores`);
        if (response.data.success) {
          console.log(
            "%c✔ Requisição feita com sucesso! Os dados que irão preencher o select serão:",
            "color: #148f32"
          );
          console.table(response.data.data);
          console.log("Dados que voltaram da requisição:", response.data.data);
        }
        this.setores.values = response.data.data;
      } catch (e) {
        console.log(
          "%c Erro: ",
          "color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);",
          e
        );
      }
    },

    /**
     * Recupera a lista de solicitações de serviço (atendimentos) da API com base nos filtros de pesquisa atuais,
     * e os armazena na propriedade `atendimentos.values`.
     * Se a solicitação da API for bem-sucedida, os dados da resposta são registrados no console.
     * Se houver um erro, o erro é registrado no console.
     * A propriedade `loading` é definida como `false` quando a solicitação é concluída.
     */
    async getAtendimentos() {
      this.loading = true;
      this.pesquisaRealizada = true;
      try {
        const response = await api.get(`/api/chamados`, {
          params: {
            keyword: this.searchQuery,
            setor_id: this.searchFilterSetor,
            urgencia_id: this.searchFilterUrgencia,
            cliente_id: this.searchFilterCliente,
            status_id: this.searchFilterSituacao,
          },
        });

        if (response.data.success) {
          console.log(
            "%c✔ Requisição feita com sucesso! Os dados que irão preencher o kanbam serão:",
            "color: #148f32"
          );
          console.table(response.data);
          console.log("Dados que voltaram da requisição:", response.data.data);
        }

        this.atendimentos.values = response.data;
        this.atendimentos.values.map(
          (atendimento) =>
            (atendimento.dateCriacao = new Date(atendimento.created_at))
        );
        this.loading = false;
      } catch (error) {
        if (error.response) {
          console.log(
            `Erro: ${error.response.status} - ${error.response.data.message}`
          );
        }
      }
    },

    /**
     * Oculta o formulário de solicitação de serviço (chamado).
     * A propriedade `currentChamado` é definida como `null`, a propriedade `showFormChamado` é definida como `false`,
     * e o método `getAtendimentos` é chamado para atualizar a lista de solicitações de serviço.
     */
    hideForm() {
      this.currentChamado = null;
      this.showFormChamado = false;
      this.getAtendimentos();

      this.loadingModalChamado = true;
    },

    /**
     * Limpa os filtros de pesquisa.
     * As propriedades `searchQuery`, `searchFilterSetor`, `searchFilterCliente`, `searchFilterSituacao`
     * e `searchFilterUrgencia` são definidas como strings vazias.
     */
    clearFilter() {
      this.searchQuery = "";
      this.searchFilterSetor = "";
      this.searchFilterCliente = "";
      this.searchFilterSituacao = "";
      this.searchFilterUrgencia = "";

      this.getAtendimentos();
    },

    /**
     * Envia o formulário de solicitação de serviço (chamado).
     * Se a propriedade `chamadoFormRef` estiver disponível e tiver um método `submitForm`,
     * esse método é chamado. Caso contrário, um erro é registrado.
     */
    submitChamado() {
      const chamadoForm = this.$refs.chamadoFormRef;
      this.loadingModalChamado = true;

      if (chamadoForm && typeof chamadoForm.submitForm === "function") {
        chamadoForm.submitForm().then((response) => {
          this.loadingModalChamado = false;
          if (response) {
            this.showFormChamado = false;
            this.getAtendimentos();
          }
        });
      } else {
        console.error("submitForm não está disponível no chamadoForm");
      }
    },

    /**
     * Edita uma solicitação de serviço (chamado).
     * A propriedade `currentChamado` é definida como o objeto `chamado` fornecido,
     * e a propriedade `showFormChamado` é definida como `true`.
     * @param {Object} chamado - O objeto de solicitação de serviço (chamado) a ser editado.
     */
    editAtendimento(chamado) {
      this.currentChamado = chamado;
      this.showFormChamado = true;

      this.$nextTick(() => {
        this.$refs.chamadoFormRef.fetchChamadoData().then((_) => {
          $(".dropdown-toggle").dropdown();
          this.loadingModalChamado = false;
        });
      });
    },

    /**
     * Alterna a visibilidade de uma lightbox e define as imagens a serem exibidas.
     * @param {Object} dados - Um objeto contendo os dados a serem exibidos na lightbox.
     */
    toggleLightBox(dados) {
      console.log(dados);

      this.lightboxImages = [dados.data.data];
      this.lightboxVisible = true;
    },
  },
  mounted() {
    $(".select2").select2();
    this.getClientes();
    this.getSetores();
    this.getAtendimentos();
  },
};
</script>

<style>
.custom-height-kamban {
  max-height: calc(100vh - 245px);
}
</style>
