<script setup>
import Loading from "@/components/generals/LoadingDefault.vue";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { api as api } from "@/axios.js";
import AtendimentoCardList from "../Home/AtendimentoCardList.vue";
import NenhumAtendimentoEncontado2x from "../generals/NenhumAtendimentoEncontado2x.vue";

const props = defineProps({
  contact: Object,
});

const emit = defineEmits(["edit"]);
function editAtendimento(chamado) {
  emit("edit", chamado);
}

const loading = ref(true);

// FILTERS
const searchFilterSetor = ref("");
const searchFilterSituacao = ref("");

// ATRIBUTOS
const atendimentos = reactive([]);
const setores = ref([]); // Setores carregados dinamicamente

// BUSCA OS CHAMADOS DESSE CONTATO
const getAtendimentos = () => {
  loading.value = true;
  const phone = props.contact.phone;
  if (!phone) return;
  api
    .get(`/api/chamados/phone/${phone}`)
    .then((response) => {
      atendimentos.values = response.data;
      atendimentos.values.forEach(
        (atendimento) =>
          (atendimento.dateCriacao = new Date(atendimento.created_at))
      );
      loading.value = false;
    })
    .catch((error) => {
      if (error.response) {
        console.log(
          `Erro: ${error.response.status} - ${error.response.data.message}`
        );
      }
    });
};

// BUSCA OS SETORES
const getSetores = () => {
  api
    .get(`/api/setores`)
    .then((response) => {
      if (response.data.success) {
        setores.value = response.data.data; // Armazena os setores carregados
      }
    })
    .catch((error) => {
      console.log("Erro ao carregar os setores:", error);
    });
};

const computedAtendimentos = computed(() => {
  return atendimentos.values
    .filter((atendimento) => {
      return (
        (searchFilterSetor.value
          ? atendimento.setores.includes(searchFilterSetor.value)
          : true) &&
        (searchFilterSituacao.value
          ? atendimento.status_id == searchFilterSituacao.value
          : true)
      );
    })
    .sort((a, b) => {
      return b.dateCriacao - a.dateCriacao;
    });
});

onMounted(() => {
  getAtendimentos();
  getSetores(); // Carrega os setores ao montar o componente
});

watch(
  () => props.contact,
  () => {
    atendimentos.values = [];
    getAtendimentos();
  }
);
</script>

<template>
  <div
    id="chat_container"
    class="w-100 p-1 panel"
    style="overflow: auto; width: auto; height: 100%"
    v-if="!loading"
  >
    <div class="px-3 py-2">
      <div class="mt-2">
        <select
          class="select2 form-control"
          id="situacaoAtendimentos"
          v-model="searchFilterSituacao"
        >
          <option value="">Todas Situações de Atendimentos</option>
          <option value="1">Atendimentos Abertos</option>
          <option value="3">Atendimentos Em Andamento</option>
          <option value="2">Atendimentos Fechados</option>
        </select>
      </div>
      <div class="mt-2">
        <select
          class="select2 form-control"
          id="setorAtendimentos"
          v-model="searchFilterSetor"
        >
          <option value="">Todos os Setores</option>
          <option v-for="setor in setores" :key="setor.ID" :value="setor.ID">
            {{ setor.NOME }}
          </option>
        </select>
      </div>
    </div>
    <div class="px-3 py-2">
      <hr class="m-0" />
    </div>
    <div v-if="computedAtendimentos.length > 0">
      <ul
        id="js-msgr-listfilter"
        class="list-unstyled m-0 js-list-filter px-3 py-2"
      >
        <AtendimentoCardList
          v-for="atendimento in computedAtendimentos"
          :key="atendimento.id"
          :atendimento="atendimento"
          @click="editAtendimento(atendimento.id)"
        />
      </ul>
    </div>
    <NenhumAtendimentoEncontado2x v-else />
  </div>
  <Loading v-else />
</template>
