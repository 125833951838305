<template>

  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-content-wrapper">
          <main id="js-page-content" role="main" class="page-content">
            <div>
              <h1>Configurações</h1>
            </div>
          </main>
      </div>
    </div>
  </div>
  </template>
  
  <script setup>
  
  </script>