<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-content-wrapper">
        <main id="js-page-content" role="main" class="page-content">
          <div class="d-flex flex-grow-1 p-0">
            <div class="d-flex flex-column flex-grow-1 overflow-hidden bg-white">
              <div class="row h-100">
                <div class="col-12">
                  <div class="panel h-100">
                    <div class="panel-hdr">
                      <h2>
                        <i class="subheader-icon fal fa-users"></i> <span
                          class="fw-300">Relatório de Atendimentos - Estatísticas Gerais</span>
                      </h2>
                    </div>
                    <div class="panel-container show">
                      <div class="panel-content p-0 dataTables_wrapper row m-2">

                        <div class="col-12 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">1 - Atendimentos:</h2>
                              <div class="">
                                <div class="row">
                                  <div class="col">
                                    <h5>Quantidade Total: {{ dados.atendimentos.total }}</h5>
                                  </div>
                                  <div class="col">
                                    <h5>Abertos: {{ dados.atendimentos.aberto }}</h5>
                                  </div>
                                  <div class="col">
                                    <h5>Em Andamento: {{ dados.atendimentos.em_andamento }}</h5>
                                  </div>
                                  <div class="col">
                                    <h5>Fechados: {{ dados.atendimentos.fechado }}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-4 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">2 - Clientes:</h2>
                              <div class="row">
                                <div class="col">
                                  <h5>
                                    Quantidade Total: {{ dados.clientes.total }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-4 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">3 - Setores:</h2>
                              <div class="row">
                                <div class="col">
                                  <h5>
                                    Quantidade Total: {{ dados.setores.total }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-4 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">4 - Colaboradores:</h2>
                              <div class="row">
                                <div class="col">
                                  <h5>
                                    Quantidade Total: {{ dados.colaboradores.total }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">5 - Duração Média por Chamado:</h2>
                              <div class="row">
                                <div class="col">
                                  <h5>
                                    Dias: {{ dados.chamados.media_chamado }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">6 - Média de Chamados Abertos por Dia:</h2>
                              <div class="row">
                                <div class="col">
                                  <h5>
                                    Quantidade: {{ dados.chamados.abertos_por_dia }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">7 - Média de Chamados Fechador por Dia:</h2>
                              <div class="row">
                                <div class="col">
                                  <h5>
                                    Quantidade: {{ dados.chamados.fechados_por_dia }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">8 - Média de Chamados por Colaborador:</h2>
                              <div class="row">
                                <div class="col">
                                  <h5>
                                    Quantidade: {{ dados.chamados.chamados_por_colaborador }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">1 - Atendimentos em Aberto por Colaborador:</h2>
                              <div class="">
                                <Bar :data="dados.graficoColaborador.aberto" :options="options"/>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">2 - Atendimentos em andamento por Colaborador:</h2>
                              <div class="">
                                <Bar :data="dados.graficoColaborador.em_andamento" :options="options"/>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">3 - Atendimentos Fechador por Colaborador:</h2>
                              <div class="">
                                <Bar :data="dados.graficoColaborador.fechado" :options="options"/>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">4 - Atendimentos em Geral por Colaborador:</h2>
                              <div class="">
                                <Bar :data="dados.graficoColaborador.total" :options="options"/>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">5 - Atendimentos em Aberto por Setor:</h2>
                              <div class="">
                                <Bar :data="dados.graficoSetor.aberto" :options="options"/>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">6 - Atendimentos em andamento por Setor:</h2>
                              <div class="">
                                <Bar :data="dados.graficoSetor.em_andamento" :options="options"/>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">7 - Atendimentos Fechador por Setor:</h2>
                              <div class="">
                                <Bar :data="dados.graficoSetor.fechado" :options="options"/>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 p-1 mb-1">
                          <div class="card h-100">
                            <div class="card-body">
                              <h2 class="card-title">8 - Atendimentos em Geral por Setor:</h2>
                              <div class="">
                                <Bar :data="dados.graficoSetor.total" :options="options"/>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart, CategoryScale, BarElement, BarController, LinearScale
} from 'chart.js'
import {Bar} from 'vue-chartjs'
import Loading from "@/components/generals/LoadingDefault.vue";
import {api} from "@/axios";
import {DataTable} from "datatables.net-vue3";
import $ from "jquery";

Chart.register(CategoryScale, BarElement, BarController, LinearScale);

export default {
  name: 'App',
  components: {
    DataTable,
    Loading,
    Bar
  },
  data()
  {
    return {
      dados: {
        atendimentos: {
          total: 0,
          aberto: 0,
          em_andamento: 0,
          fechado: 0
        },
        clientes: {
          total: 0,
        },
        setores: {
          total: 0,
        },
        colaboradores: {
          total: 0,
        },
        chamados: {
          media_chamado: 0,
          abertos_por_dia: 0,
          fechados_por_dia: 0,
          chamados_por_colaborador: 0,
        },
        graficoColaborador: this.createGraficoColaboradorData(),
        graficoSetor: this.createGraficoSetorData(),
      },
      loading: false,
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
    }
  },
  methods: {
    /**
     * Cria a estrutura de dados para o gráfico de colaboradores.
     * O gráfico terá quatro conjuntos de dados: 'aberto', 'em_andamento', 'fechado' e 'total'.
     * Cada conjunto de dados terá um único ponto de dados com a cor de fundo '#7f1517'.
     * Essa estrutura de dados é usada para preencher o gráfico de colaboradores no componente.
     *
     * @returns {Object} Um objeto contendo os dados do gráfico de colaboradores.
     */
    createGraficoColaboradorData()
    {
      return {
        aberto: this.createGraficoData(),
        em_andamento: this.createGraficoData(),
        fechado: this.createGraficoData(),
        total: this.createGraficoData(),
      };
    },

    /**
     * Cria a estrutura de dados para o gráfico de setores.
     * O gráfico terá quatro conjuntos de dados: 'aberto', 'em_andamento', 'fechado' e 'total'.
     * Cada conjunto de dados terá um único ponto de dados com a cor de fundo '#7f1517'.
     * Essa estrutura de dados é usada para preencher o gráfico de setores no componente.
     *
     * @returns {Object} Um objeto contendo os dados do gráfico de setores.
     */
    createGraficoSetorData()
    {
      return {
        aberto: this.createGraficoData(),
        em_andamento: this.createGraficoData(),
        fechado: this.createGraficoData(),
        total: this.createGraficoData(),
      };
    },


    /**
     * Cria a estrutura de dados para o gráfico.
     * O gráfico terá quatro conjuntos de dados: 'aberto', 'em_andamento', 'fechado' e 'total'.
     * Cada conjunto de dados terá um único ponto de dados com a cor de fundo '#7f1517'.
     * Essa estrutura de dados é usada para preencher o gráfico no componente.
     *
     * @param {string[]} backgroundColors - Um array de cores de fundo para os dados do gráfico.
     */
    createGraficoData()
    {
      return {
        labels: [],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '',
            data: []
          }
        ]
      };
    },


    /**
     * Busca e processa os dados para o relatório de estatísticas gerais.
     *
     * Este método é responsável por fazer uma requisição à API para recuperar os dados necessários para o relatório de estatísticas gerais,
     * e depois processar esses dados para preencher as várias propriedades de dados no componente.
     *
     * O método faz uma requisição GET para o endpoint `/api/relatorio/estatisticasgerais`, e então processa os dados da resposta
     * chamando vários métodos auxiliares que atualizam as propriedades de dados do componente com as informações relevantes.
     *
     * Se ocorrer um erro durante a requisição à API, o método registra o erro no console.
     */
    async getDados()
    {
      try
      {
        const response = await api.get(`/api/relatorio/estatisticasgerais`);

        this.processAtendimentos(response.data.data.atendimentos.data[0]);
        this.processClientes(response.data.data.countCliente.data[0]);
        this.processSetores(response.data.data.countSetor.data[0]);
        this.processColaboradores(response.data.data.countColaborador.data[0]);
        this.processChamados(response.data.data.mediasChamados.data);
        this.processGraficoColaborador(response.data.data.graficoColaboradores.data);
        this.processGraficoSetor(response.data.data.graficoSetores.data);

        console.log(this.dados.graficoColaborador);
      } catch (error)
      {
        console.error('Erro ao buscar os atendimentos:', error);
      }
    },


    /**
     * Processa os dados de atendimentos e atualiza as propriedades correspondentes nos dados do componente.
     *
     * Este método é responsável por processar os dados de atendimentos recebidos da resposta da API e atualizar as propriedades relevantes
     * no objeto `dados.atendimentos` do componente.
     *
     * @param {Object} atendimentos - Um objeto contendo os dados de atendimentos.
     * @param {number} atendimentos.TOTAL_CHAMADOS - O número total de chamados.
     * @param {number} atendimentos.TOTAL_ABERTOS - O número de chamados abertos.
     * @param {number} atendimentos.total_andamento - O número de chamados em andamento.
     * @param {number} atendimentos.TOTAL_FECHADOS - O número de chamados fechados.
     */
    processAtendimentos(atendimentos)
    {
      this.dados.atendimentos.total = atendimentos.TOTAL_CHAMADOS;
      this.dados.atendimentos.aberto = atendimentos.TOTAL_ABERTOS;
      this.dados.atendimentos.em_andamento = atendimentos.total_andamento;
      this.dados.atendimentos.fechado = atendimentos.TOTAL_FECHADOS;
    },


    /**
     * Processes the client data and updates the corresponding properties in the component's data.
     *
     * This method is responsible for processing the client data received from the API response and updating the relevant properties
     * in the `dados.clientes` object of the component.
     *
     * @param {Object} cliente - An object containing the client data.
     * @param {number} cliente.TOTAL_CLIENTES - The total number of clients.
     */
    processClientes(cliente)
    {
      this.dados.clientes.total = cliente.TOTAL_CLIENTES;
    },


    /**
     * Processa os dados dos setores e atualiza a propriedade correspondente nos dados do componente.
     *
     * Este método é responsável por processar os dados dos setores recebidos da resposta da API e atualizar a propriedade relevante
     * no objeto `dados.setores` do componente.
     *
     * @param {Object} setores - Um objeto contendo os dados dos setores.
     * @param {number} setores.TOTAL_SETORES - O número total de setores.
     */
    processSetores(setores)
    {
      this.dados.setores.total = setores.TOTAL_SETORES;
    },


    /**
     * Processa os dados dos colaboradores e atualiza a propriedade correspondente nos dados do componente.
     *
     * Este método é responsável por processar os dados dos colaboradores recebidos da resposta da API e atualizar a propriedade relevante
     * no objeto `dados.colaboradores` do componente.
     *
     * @param {Object} colaboradores - Um objeto contendo os dados dos colaboradores.
     * @param {number} colaboradores.TOTAL_USUARIOS - O número total de colaboradores.
     */
    processColaboradores(colaboradores)
    {
      this.dados.colaboradores.total = colaboradores.TOTAL_USUARIOS;
    },


    /**
     * Processa os dados dos chamados e atualiza as propriedades correspondentes nos dados do componente.
     *
     * Este método é responsável por processar os dados dos chamados recebidos da resposta da API e atualizar as propriedades relevantes
     * no objeto `dados.chamados` do componente.
     *
     * @param {Object} chamados - Um objeto contendo os dados dos chamados.
     * @param {number} chamados.duracao_media_dias - A duração média dos chamados em dias.
     * @param {number} chamados.media_chamados_abertos_por_dia - O número médio de chamados abertos por dia.
     * @param {number} chamados.media_chamados_fechados_por_dia - O número médio de chamados fechados por dia.
     * @param {number} chamados.media_chamados_por_colaborador - O número médio de chamados por colaborador.
     */
    processChamados(chamados)
    {
      this.dados.chamados.media_chamado = chamados.duracao_media_dias;
      this.dados.chamados.abertos_por_dia = chamados.media_chamados_abertos_por_dia;
      this.dados.chamados.fechados_por_dia = chamados.media_chamados_fechados_por_dia;
      this.dados.chamados.chamados_por_colaborador = chamados.media_chamados_por_colaborador;
    },


    /**
     * Processa os dados para os gráficos de colaboradores e atualiza as propriedades correspondentes nos dados do componente.
     *
     * Este método é responsável por processar os dados dos gráficos de colaboradores recebidos da resposta da API e atualizar as propriedades relevantes
     * no objeto `dados.graficoColaborador` do componente.
     *
     * @param {Object} graficoColaborador - Um objeto contendo os dados dos gráficos de colaboradores.
     * @param {Object[]} graficoColaborador.chamados_em_aberto - Os dados para os casos abertos dos colaboradores.
     * @param {string} graficoColaborador.chamados_em_aberto[].nome - O nome do colaborador.
     * @param {number} graficoColaborador.chamados_em_aberto[].ABERTOS - O número de casos abertos para o colaborador.
     * @param {Object[]} graficoColaborador.chamados_em_andamento - Os dados para os casos em andamento dos colaboradores.
     * @param {string} graficoColaborador.chamados_em_andamento[].nome - O nome do colaborador.
     * @param {number} graficoColaborador.chamados_em_andamento[].EM_ANDAMENTO - O número de casos em andamento para o colaborador.
     * @param {Object[]} graficoColaborador.chamados_fechados - Os dados para os casos fechados dos colaboradores.
     * @param {string} graficoColaborador.chamados_fechados[].nome - O nome do colaborador.
     * @param {number} graficoColaborador.chamados_fechados[].FECHADOS - O número de casos fechados para o colaborador.
     * @param {Object[]} graficoColaborador.chamados_totais - Os dados para os casos totais dos colaboradores.
     * @param {string} graficoColaborador.chamados_totais[].nome - O nome do colaborador.
     * @param {number} graficoColaborador.chamados_totais[].TOTAIS - O número total de casos para o colaborador.
     */
    processGraficoColaborador(graficoColaborador)
    {
      this.dados.graficoColaborador.aberto = {
        labels: graficoColaborador.chamados_em_aberto.map(item => item.nome),
        datasets: [
          {
            label: 'Chamados Abertos',
            backgroundColor: '#7f1517',
            data: graficoColaborador.chamados_em_aberto.map(item => item.ABERTOS)
          }
        ]
      };

      this.dados.graficoColaborador.em_andamento = {
        labels: graficoColaborador.chamados_em_andamento.map(item => item.nome),
        datasets: [
          {
            label: 'Chamados em Andamento',
            backgroundColor: '#7f1517',
            data: graficoColaborador.chamados_em_andamento.map(item => item.EM_ANDAMENTO)
          }
        ]
      };

      this.dados.graficoColaborador.fechado = {
        labels: graficoColaborador.chamados_fechados.map(item => item.nome),
        datasets: [
          {
            label: 'Chamados Fechados',
            backgroundColor: '#7f1517',
            data: graficoColaborador.chamados_fechados.map(item => item.FECHADOS)
          }
        ]
      };

      this.dados.graficoColaborador.total = {
        labels: graficoColaborador.chamados_totais.map(item => item.nome),
        datasets: [
          {
            label: 'Chamados Totais',
            backgroundColor: '#7f1517',
            data: graficoColaborador.chamados_totais.map(item => item.TOTAIS)
          }
        ]
      };
    },


    /**
     * Processa os dados para os gráficos de setores e atualiza as propriedades correspondentes nos dados do componente.
     *
     * Este método é responsável por processar os dados dos gráficos de setores recebidos da resposta da API e atualizar as propriedades relevantes
     * no objeto `dados.graficoColaborador` do componente.
     *
     * @param {Object} graficoSetor - Um objeto contendo os dados dos gráficos de setores.
     * @param {Object[]} graficoSetor.chamados_em_aberto - Os dados para os casos abertos dos setores.
     * @param {string} graficoSetor.chamados_em_aberto[].nome - O nome do setor.
     * @param {number} graficoSetor.chamados_em_aberto[].ABERTOS - O número de casos abertos para o setor.
     * @param {Object[]} graficoSetor.chamados_em_andamento - Os dados para os casos em andamento dos setores.
     * @param {string} graficoSetor.chamados_em_andamento[].nome - O nome do setor.
     * @param {number} graficoSetor.chamados_em_andamento[].EM_ANDAMENTO - O número de casos em andamento para o setor.
     * @param {Object[]} graficoSetor.chamados_fechados - Os dados para os casos fechados dos setores.
     * @param {string} graficoSetor.chamados_fechados[].nome - O nome do setor.
     * @param {number} graficoSetor.chamados_fechados[].FECHADOS - O número de casos fechados para o setor.
     * @param {Object[]} graficoSetor.chamados_totais - Os dados para os casos totais dos setores.
     * @param {string} graficoSetor.chamados_totais[].nome - O nome do setor.
     * @param {number} graficoSetor.chamados_totais[].TOTAIS - O número total de casos para o setor.
     */
    processGraficoSetor(graficoSetor)
    {
      this.dados.graficoSetor.aberto = {
        labels: graficoSetor.chamados_em_aberto.map(item => item.nome),
        datasets: [
          {
            label: 'Chamados Abertos',
            backgroundColor: '#7f1517',
            data: graficoSetor.chamados_em_aberto.map(item => item.ABERTOS)
          }
        ]
      };

      this.dados.graficoSetor.em_andamento = {
        labels: graficoSetor.chamados_em_andamento.map(item => item.nome),
        datasets: [
          {
            label: 'Chamados em Andamento',
            backgroundColor: '#7f1517',
            data: graficoSetor.chamados_em_andamento.map(item => item.EM_ANDAMENTO)
          }
        ]
      };

      this.dados.graficoSetor.fechado = {
        labels: graficoSetor.chamados_fechados.map(item => item.nome),
        datasets: [
          {
            label: 'Chamados Fechados',
            backgroundColor: '#7f1517',
            data: graficoSetor.chamados_fechados.map(item => item.FECHADOS)
          }
        ]
      };

      this.dados.graficoSetor.total = {
        labels: graficoSetor.chamados_totais.map(item => item.nome),
        datasets: [
          {
            label: 'Chamados Totais',
            backgroundColor: '#7f1517',
            data: graficoSetor.chamados_totais.map(item => item.TOTAIS)
          }
        ]
      };
    },
  },
  mounted()
  {
    $('.select2').select2();
    this.getDados();
  }
}

</script>
<style scoped>
:deep(.dataTables_wrapper) {
  max-height: calc(100vh - 261px) !important;
  overflow: auto;
}

:deep(.fullsize-table) {
  max-height: calc(100vh - 261px);
}
</style>