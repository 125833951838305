<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <button class="btn btn-default" type="button" @click="launchFilePicker">
        <span class="fal fa-paperclip" aria-hidden="true"></span>
      </button>
    </div>
    <input type="text" style="cursor: default" class="form-control"  @click="launchFilePicker" id="show-files" :placeholder="multiple ? 'Escolha um ou mais arquivos...' : 'Escolha um arquivo...'" readonly :value="fileNames"/>
    <input type="file" name="file" :multiple="multiple" ref="fileInput" class="form-control d-none" @change="updateFileNames"/>
  </div>
</template>

<script>
export default {
  name: 'CustomFileInput',
  data()
  {
    return {
      fileNames: '',
    }
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    launchFilePicker()
    {
      this.$refs.fileInput.click();
    },


    updateFileNames(event)
    {
      const selectedFiles = event?.target.files;
      if (selectedFiles?.length > 0)
      {
        const names = Array.from(selectedFiles).map(file => file.name).join(', ');
        this.fileNames = names;
      }
      else
      {
        this.fileNames = '';
      }
    },
  },
}
</script>
<style scoped>
textarea:focus, input:focus, select:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}
</style>
