import 'vue-select/dist/vue-select.css';
// import '@/assets/_theme/js/vendors.bundle.js';
// import '@/assets/_theme/js/app.bundle.js';

// push settings with "false" save to local
// initApp.pushSettings("layout-composed-mobile", false);


// FORMATA A DATA QUE É CRIADA PELO LARAVEL (ISO 8601)
// function formatLaravelDate(laravelDate) {
//   // Cria um objeto Date a partir da string de data do Laravel
//   const date = new Date(laravelDate);

//   // Obtém o dia, mês e ano da data
//   const day = String(date.getDate()).padStart(2, '0');
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são indexados a partir de 0
//   const year = date.getFullYear();

//   // Obtém as horas e minutos
//   const hours = String(date.getHours()).padStart(2, '0');
//   const minutes = String(date.getMinutes()).padStart(2, '0');

//   // Formata a data e a hora no formato desejado
//   const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

//   return formattedDate;
// }
export function calcularDiferencaDias(dataInicial, dataFinal) {
  // Converta as datas para timestamps (milissegundos)
  const timeInicial = dataInicial.getTime();
  const timeFinal = dataFinal.getTime();
  
  // Calcule a diferença em milissegundos
  const diferencaMillis = timeFinal - timeInicial;
  
  // Converta a diferença de milissegundos em dias
  const diferencaDias = diferencaMillis / (1000 * 60 * 60 * 24);
  
  return Math.floor(diferencaDias);
}

export function farmatDataAtualParaMensagens() {
  const data = new Date();
  const day = String(data.getDate()).padStart(2, '0');
  const month = String(data.getMonth() + 1).padStart(2, '0'); // Os meses são indexados a partir de 0
  const year = data.getFullYear();
  const hours = String(data.getHours()).padStart(2, '0');
  const minutes = String(data.getMinutes()).padStart(2, '0');
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
  return formattedDate;
}