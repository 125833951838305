<template>
  <form @submit.prevent="submitForm" id="chamadoForm">
    <div class="row">

      <div class="col-12">
        <div class="row">

          <div class="col-4">
            <div class="form-group">
              <label class="form-label" for="SelectSetorResponsavel">Nome*:</label>
              <input required type="text" class="form-control" name="inputNome" id="inputNome" v-model="form.nome" />
              <span class="text-danger" v-if="errors.nome">{{ errors.nome[0] }}</span>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label" for="inputCpf">Descrição:</label>
              <input required type="text" class="form-control" name="inputCpf" id="inputCpf" v-model="form.descricao" />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label" for="inputSenha">Dias para Execução*:</label>
              <input required type="number" class="form-control" name="inputSenha" id="inputSenha"
                v-model="form.diasExecucao" />
              <span class="text-danger" v-if="errors.dias_para_execucao">{{ errors.dias_para_execucao[0] }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-2">
            <div class="form-group">
              <label class="form-label" for="inputCelular">Texto Mensagem*:</label>
              <textarea required :rows="10" type="text" class="form-control" name="inputCelular" id="inputCelular"
                v-model="form.textoMensagem" />
              <span class="text-danger" v-if="errors.text_msg">{{ errors.text_msg[0] }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { api } from "@/axios";
import $ from "jquery";
import swal from 'sweetalert2';

export default {
  name: 'SetorForm',
  components: {},
  mounted() {
    $(".select2-cadClientes").select2({
      dropdownParent: $(".modal-body")
    })

    this.getSetor(this.modelValue)
  },
  data() {
    return {
      form: {
        nome: '',
        descricao: '',
        textoMensagem: '',
        opcMenu: '',
        diasExecucao: '',
      },
      optionsSetores: [],
      errors: [],
    }
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
  },
  methods: {
    editChamado(chamado) {
      this.$emit("toggle", chamado)
    },


    /**
     * Busca os detalhes de um setor da API e preenche os campos do formulário.
     *
     * @param {string|number|null|undefined} idSetor - O ID do setor a ser buscado. Se for nulo, indefinido ou uma string vazia, a função retornará sem fazer uma requisição.
     * @returns {Promise<boolean>} - Retorna true se a requisição foi bem-sucedida, false caso contrário.
     */
    async getSetor(idSetor) {
      if ([null, undefined, ""].includes(idSetor)) return;

      try {
        const response = await api.get(`/api/setores/${idSetor}`)

        if (!response.data.success) {
          swal.fire({
            title: 'Ops!',
            text: response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          });
          return;
        }

        const dados = response.data.data;
        console.log("%c✔ Requisição feita com sucesso! Os dados que irão preencher os campos do modal serão:", "color: #148f32");
        console.table(response.data.data);
        console.log('Dados que voltaram da requisição:', response.data.data);

        this.form.nome = dados.nome;
        this.form.descricao = dados.descricao;
        this.form.textoMensagem = dados.text_msg;
        this.form.diasExecucao = dados.dias_para_execucao;

        return true
      } catch (e) {
        console.log('%c Erro: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e);
      }
    },


    /**
     * Envia os dados do formulário para a API para criar ou atualizar um setor.
     *
     * Se `modelValue` for nulo, indefinido ou uma string vazia, um novo setor será criado usando o método `postForm` da API.
     * Caso contrário, o setor existente com o ID especificado em `modelValue` será atualizado usando o método `put` da API.
     *
     * Se a requisição à API for bem-sucedida, uma mensagem de sucesso será exibida usando `swal.fire`. Se a requisição falhar, uma mensagem de erro será exibida.
     *
     * @returns {Promise<boolean>} - Retorna `true` se o envio do formulário foi bem-sucedido, `false` caso contrário.
     */
    async submitForm() {
      const objetoRequisicao = {
        nome: this.form.nome,
        descricao: this.form.descricao,
        text_msg: this.form.textoMensagem,
        dias_para_execucao: this.form.diasExecucao,
      }

      try {
        let response = "";

        if ([null, undefined, ''].includes(this.modelValue)) {
          response = await api.postForm(`/api/setores`, objetoRequisicao);
        }
        else {
          response = await api.put(`/api/setores/${this.modelValue}`, objetoRequisicao);
        }

        if (response.data.success) {
          swal.fire({
            title: 'Sucesso!',
            text: response?.data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          return true;
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.data.status;
          const errors = error.response.data.errors;

          if (status === 'validation_error') {
            // Exibe erros de validação
            this.handleErrors(errors);
          }

        } else {
          // Outro tipo de erro (ex: rede, timeout)
          console.log('Erro inesperado', error);
        }

        console.log('%c Erro: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', error);
        return false;
      }
    },

    handleErrors(errors) {
      this.errors = errors
    },
  }
}
</script>

<style scoped></style>