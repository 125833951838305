<template>
  <HeaderMain v-if="!$route.path.includes('login')" />
  <AsideNav v-if="!$route.path.includes('login')"/>
  <RouterView @login-success="loginSuccess"/>
</template>

<script>
import {RouterView} from 'vue-router';
import HeaderMain from "@/components/HeaderMain.vue";
import AsideNav from "@/components/AsideNav.vue";

export default {
  components: {
    HeaderMain,
    AsideNav,
    RouterView
  },
  data() {
    return {
      showNavBar: false,
    };
  },
  mounted() {
    this.loginSuccess();
    if (this.$route.path !== '/login') {
      this.showNavBar = true;
    }
  },
  methods: {
    loginSuccess() {
      const accessToken = window.localStorage.getItem('access_token');
      if (accessToken && accessToken.trim() !== '') {
        this.showNavBar = true;
      } else {
        this.showNavBar = false;
        localStorage.clear();
      }
    }
  },
  watch: {
    $route(to) {
      if (to.path !== '/login') {
        this.showNavBar = true;
      } else {
        this.showNavBar = false;
        localStorage.clear();
      }
    }
  }
}
</script>

<style>
body {
  overflow: hidden;
}

.swal2-center{
  z-index: 10000 !important;
}
</style>
