import axios from 'axios';
import Cookies from 'js-cookie';
import router from './router';
import store from './store';
import swal from "sweetalert2";


const api = axios.create({
  baseURL: 'https://api.agrosmartzap.com.br',
  withCredentials: true
});

// const api = axios.create({
//   baseURL: 'https://localhost:8989',
//   withCredentials: true,
// });

// INTERCEPTOR NA REQUISÃO, QUE VERIFICA SE HÁ TOKEN E ADICIONA NO CABECALHO O MAIS ATUAL
api.interceptors.request.use(
  async (config) => {
  const token = localStorage.getItem('access_token')
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  if (config.method !== 'get') {
    try {
      await api.get('/sanctum/csrf-cookie');

    } catch (error) {
      console.log("Erro ao adicionar CSRF Token:", error)
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

// INTERCEPTOR NA RESPOSTA, VERIFICA SE O TOKEN EXPIROU, A PARTIR DA RESPOSTA DO SERVIDOR E REDIRECIONA PARA TELA DE LOGIN
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.log(error.response.data.message);
      console.log('Usuário não autenticado, redirecionando para página de login!');

      // remover tokens e cookies
      localStorage.removeItem('access_token');
      localStorage.removeItem('logged_user');
      Cookies.remove('XSRF-TOKEN')

      // // Atualiza o state no store para deslogado
      store.commit('LOGOUT')

      // // Redirect para a rota "login" dentro do sistema
      router.push({ name: 'login' })
    } else if (error.response && error.response.status === 500) {
      const status = error.response.data.status;
      const message = error.response.data.message;
      if (status === 'database_error') {
        // Exibe erros de banco de dados
        swal.fire({
          title: 'Ops!',
          text: message,
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }else {
        swal.fire({
          title: 'Ops!',
          text: "Erro inesperado, contate o administrador!",
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    }
    return Promise.reject(error)
  }
)

export {api};