<template>
  <!-- MENSAGEM ENVIADA/RECEBIDA -->
  <div class="chat-segment" v-bind:class="[message.fromMe ? 'chat-segment-sent' : 'chat-segment-get']">
    <div class="chat-message" v-bind:class="[message.deletada_via_api ? 'deleted' : '']">

      <div v-if="['image', 'video', 'document', 'audio'].includes(message.type_msg)" class="chat-message-text">
        <div class="media-message" v-if="message.type_msg === 'image'">
          <!-- <a v-if="message.url_media" :href="message.url_media" download="teste" target="_blank"> -->
          <a v-if="message.url_media" href="javascript:void(0)" @click="openLightbox(message.url_media)">
            <img :src="message.url_media" class="img" />
          </a>
        </div>
        <div v-else-if="message.type_msg === 'video'">
          <video v-if="message.url_media" controls >
            <source :src="message.url_media" type="video/mp4">
          </video>
        </div>
        <div class="media-message" v-else-if="message.type_msg === 'document'">
          <a :href="message.url_media" target="_blank">
            <div class="document-file p-2 d-flex">
              <div class="file-icon">
              </div>
              <div class="p-1 text-truncate text-truncate-lg">
                <span>{{ message.titleMedia }}</span>
              </div>
            </div>
          </a>
        </div>
        <div v-else-if="message.type_msg === 'audio'">
          <audio v-if="message.url_media" controls>
            <source :src="message.url_media" type="audio/mp3">
          </audio>
        </div>


        <p v-if="message.caption" v-html="message.caption">
        </p>

      </div>
      <!-- <div v-else-if="message.type_msg === 'listMessage'">

      </div>
      <div v-else-if="message.type_msg === 'listResponseMessage'">

      </div> -->
      <div v-else class="chat-message-text text-message">
        <p style="white-space: pre-line;" v-html="message.text_msg">
        </p>
      </div>

      
      <!-- DROPDOWN ACTIONS MSG -->
      <div class="dropdown position-absolute pos-right pos-top">
        <a class="dropdown-toggle brn-sm" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="javascript:void(0)" @click="abrirAtendimento" v-if="message?.text_msg">Abrir Atendimento</a>
          <a class="dropdown-item" href="javascript:void(0)" @click="copyToClipboard" v-if="message?.text_msg || message?.caption">Copiar Texto</a>
          <a class="dropdown-item" href="javascript:void(0)" @click="excluirMensagem" v-if="!message.deletada_via_api">Excluir</a>
          <a class="dropdown-item" :href="message.url_media" download target="_blank" v-if="message.url_media && message.type_msg !=='video'">Download</a>
          <a class="dropdown-item" v-if="message.type_msg =='document' && showSideModal" @click="adicionaOcorrencia(message.url_media, message.titleMedia, message.id)">Adicionar às Ocorrencias</a>
          <!-- <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a> -->
        </div>
      </div>

    </div>
    <div class="fw-300 text-muted mt-1 fs-xs" :class="{'text-right': message.fromMe }">
      {{ message.created_at_format }}
      <div v-if="message?.ocorrencias?.length > 0">
        <div v-for="(ocorrencia, index) in message.ocorrencias" :key="index">
          <div v-if="!exibidos.has(ocorrencia.chamado_id)">
            {{ adicionaAoSet(ocorrencia.chamado_id) }}
            - Documento anexado no ticket {{ ocorrencia.chamado_id }}
          </div>
        </div>
      </div>
      <span v-if="message.awaiting && message.fromMe">
        <svg viewBox="0 0 16 18" width="16" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="0 0 16 18">
          <title>msg-time</title>
          <path fill="currentColor" d="M9.75,7.713H8.244V5.359c0-0.276-0.224-0.5-0.5-0.5H7.65c-0.276,0-0.5,0.224-0.5,0.5v2.947 c0,0.276,0.224,0.5,0.5,0.5h0.094c0.001,0,0.002-0.001,0.003-0.001S7.749,8.807,7.75,8.807h2c0.276,0,0.5-0.224,0.5-0.5V8.213 C10.25,7.937,10.026,7.713,9.75,7.713z M9.75,2.45h-3.5c-1.82,0-3.3,1.48-3.3,3.3v3.5c0,1.82,1.48,3.3,3.3,3.3h3.5 c1.82,0,3.3-1.48,3.3-3.3v-3.5C13.05,3.93,11.57,2.45,9.75,2.45z M11.75,9.25c0,1.105-0.895,2-2,2h-3.5c-1.104,0-2-0.895-2-2v-3.5 c0-1.104,0.896-2,2-2h3.5c1.105,0,2,0.896,2,2V9.25z">
          </path>
        </svg>
      </span>
      <span v-else-if="message.fromMe">
        <svg viewBox="0 0 12 13" height="12" width="16" preserveAspectRatio="xMidYMid meet" class="" fill="none">
          <title>msg-check</title>
          <path d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z" fill="currentcolor">
          </path>
        </svg>
      </span>
    </div>

    <vue-easy-lightbox
      :visible="visible"
      :imgs="lightboxImages"
      @hide="visible = false"
    />

  </div>
</template>


<script setup>
import { onMounted, ref, watch } from 'vue';
import $ from 'jquery';
import VueEasyLightbox from 'vue-easy-lightbox';
import { api as api } from "@/axios.js";
import ChamadoForm from "@/components/Home/Forms/ChamadoForm.vue";

defineOptions({
  name: 'mensagem-component'
})

const props = defineProps({
  mensagem: {
    type: Object,
    required: true
  },
  showSideModal: {
    type: Boolean,
    default: false,
    required: false
  },
})

const message = ref(props.mensagem);
let showSideModal = ref(props.showSideModal);
let exibidos = new Set();

watch(() => props.showSideModal, (newVal) => {
  showSideModal.value = newVal;
});

const visible = ref(false);
const lightboxImages = ref([]);

watch(() => props.mensagem, () => {
  scrollToBottom();
})

onMounted(() => {
  $(`#message-${message.value.id} .dropdown-toggle`).dropdown();
})

function scrollToBottom() {
  $('#msgr_history').scrollTop($('#chat_container').innerHeight())  
}

async function copyToClipboard() {
  let content = '';

  if (message.value.text_msg !== null && message.value.text_msg !== '') {
    content = message.value.text_msg
  } else if (message.value.caption !== null && message.value.caption !== '') {
    content = message.value.caption
  }

  try {
    await navigator.clipboard.writeText(content)
  } catch (err) {
    console.error('Falha ao copiar conteúdo: ', err)
  }
}

function openLightbox(image) {
  lightboxImages.value = [image];
  visible.value = true;
}

const emit = defineEmits(['abrirAtendimento', 'setArquivoMensagem']);
function abrirAtendimento() {
  console.log('abrirAtendimento')
  emit('abrirAtendimento', message.value)
}

function excluirMensagem() {
  api.delete(`/api/mensagem/${message.value.id}`).then((response) => {
    if (response.data.success) {
      message.value.deletada_via_api = true;
      console.log('%c Mensagem excluída com sucesso: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', response.data.message);
    } else {
      console.log('%c Erro ao excluir mensagem: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', response.data.message);
    }
  }).catch((e) => {
    console.log('%c Erro ao excluir mensagem: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e);
  })
}


/**
 * Recupera um arquivo do servidor de forma assíncrona, cria um novo objeto File com os dados recuperados e emite um evento para definir o arquivo como o anexo da mensagem.
 *
 * @param {string} arquivo - O caminho do arquivo a ser recuperado.
 * @param {string} nome - O nome do arquivo.
 * @param {number} id - O ID da mensagem.
 * @returns {Promise<void>} - Uma Promise que é resolvida quando o arquivo é definido como o anexo da mensagem.
 */
async function adicionaOcorrencia(arquivo, nome, id) {
  try {
    arquivo = arquivo.split('/');
    let session_user = JSON.parse(localStorage.getItem('logged_user'));

    const response = await api.get(`api/storage/empresa_${session_user.empresa_id}/${arquivo.at(-1)}`, {
      responseType: 'blob'
    });

    const file = new File([response.data], arquivo, { type: response.headers['content-type'] || 'application/octet-stream' });

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    emit('setArquivoMensagem', dataTransfer, nome, id);
  } catch (error) {
    console.error('Erro ao criar e adicionar o arquivo ao input:', error);
  }
}

/**
 * Adiciona um chamado_id ao conjunto exibidos e retorna uma string vazia.
 *
 * @param {number} chamado_id - O ID do chamado a ser adicionado ao conjunto exibidos.
 * @returns {string} Uma string vazia.
 */
function adicionaAoSet(chamado_id) {
  exibidos.add(chamado_id);
  return ''
}


</script>

<style scoped>
.chat-message {
  padding: .3rem .3rem;
}
.chat-message img, 
.chat-message video {
  width: 260px;
  height: auto;
  border-radius: 0.625rem;
  background: url('@/assets/img/backgrounde_no_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.chat-message audio {
  height: 32px;
  margin-top: 4px;
  background-color: #F1F3F4;
  border-radius: 0.625rem;
}
.chat-message .dropdown {
  margin-right: 8px;
  font-size: 20px;
  font-weight: 600;
}
.chat-message .dropdown .dropdown-toggle:after {
  color: #5e5858de;
}
.mod-skin-dark:not(.mod-skin-light) .chat-message .dropdown .dropdown-toggle:after {
  color:#F1F3F4;
}
.chat-message .dropdown-menu .dropdown-item {
  padding: .2rem .75rem;
  text-decoration: none!important;
  font-weight: 400;
}

.chat-message .chat-message-text.text-message {
  margin-right: 10px;
}
.chat-message .chat-message-text > .media-message {
  margin-right: 20px;
}

.chat-message .chat-message-text a {
  text-decoration: none!important;
}
.chat-message .chat-message-text > p {
  padding: 0.2rem .6rem;
  margin: 0;
}
.chat-segment-sent .chat-message.deleted,
.chat-segment-get .chat-message.deleted {
  background: #8d171987;
  color: #FFFFFF;
}
.chat-segment-get .chat-message .chat-message-text .document-file {
  color: rgba(0, 0, 0, 0.8);
  background-color: #dfdfdf;
}
.chat-segment-sent .chat-message .chat-message-text .document-file {
  color: #FFF;
  background-color: #f5f6f661;
}
.chat-message .chat-message-text .document-file {
  border-radius: 0.625rem;
  font-size: 0.95rem;
  font-weight:300;
  align-items: center;
  gap: 4px;
}

.file-icon {
  width: 22.5px;
  height: 26px;
  background: url('@/assets/img/file-bg-icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>