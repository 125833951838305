import { createRouter, createWebHistory } from "vue-router"
import Login from "./components/Login/Login.vue"
import Chat from "./components/chat/Chat.vue"
import ClientesPage from "./components/Clients/ClientsPage.vue"
import SetoresPage from "./components/Setors/SetoresPage.vue"
import ColaboradoresPage from "./components/Collaborators/ColaboradoresPage.vue"
import AtendimentoPorCliente from "./components/relatorios/AtendimentoPorCliente/AtendimentoPorCliente.vue"
import AtendimentoPorSetor from "./components/relatorios/AtendimentoPorSetor/AtendimentoPorSetor.vue"
import AtendimentoPorColaborador from "./components/relatorios/AtendimentoPorColaborador/AtendimentoPorColaborador.vue"
import EstatisticasGerais from "./components/relatorios/EstatisticasGerais.vue"
import ConfiguracoesPage from "./components/Configuracoes/ConfiguracoesPage.vue"
import HomePage from "./components/Home/HomePage.vue"
// import {api as api} from "@/axios.js";
// import Cookies from "js-cookie";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    title: "Login Page",
    meta: {
      public: true // rotas publicas
    }
  },
  {
    path: "/",
    name: "home",
    title: "Home - Atendimento",
    component: HomePage,
    meta: {
      public: false
    }
  },
  {
    path: "/atendimento",
    name: "atendimento",
    title: "Atendimento Adriana Leal",
    component: Chat,
    meta: {
      public: false
    }
  },
  {
    path: "/clientes",
    name: "clientes",
    title: "Clientes  - Atendimento",
    component: ClientesPage,
    meta: {
      public: false
    }
  },
  {
    path: "/setores",
    name: "setores",
    title: "Setores  - Atendimento",
    component: SetoresPage,
    meta: {
      public: false
    }
  },
  {
    path: "/colaboradores",
    name: "colaboradores",
    title: "Colaboradores  - Atendimento",
    component: ColaboradoresPage,
    meta: {
      public: false
    }
  },
  {
    path: "/configuracoes",
    name: "configuracoes",
    title: "Configurações - Atendimento",
    component: ConfiguracoesPage,
    meta: {
      public: false
    }
  },
  // RELATÓRIOS
  {
    path: "/relatorios/atendimento-por-cliente",
    name: "atendimento-por-cliente",
    title: "Relatórios - Atendimento por Cliente",
    component: AtendimentoPorCliente,
    meta: {
      public: false
    }
  },
  {
    path: "/relatorios/atendimento-por-colaborador",
    name: "atendimento-por-colaborador",
    title: "Relatórios - Atendimento por Colaborador",
    component: AtendimentoPorColaborador,
    meta: {
      public: false
    }
  },
  {
    path: "/relatorios/atendimento-por-setor",
    name: "atendimento-por-setor",
    title: "Relatórios - Atendimento por Setor",
    component: AtendimentoPorSetor,
    meta: {
      public: false
    }
  },
  {
    path: "/relatorios/estatisticas-gerais",
    name: "estatisticas-gerais",
    title: "Relatórios - Estatísticas Gerais",
    component: EstatisticasGerais,
    meta: {
      public: false
    }
  },
  // END RELATÓRIOS

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// ROUTER COM VALIDAÇÃO DO TOKEN NO BACKEND
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("access_token");
  const logged_user = localStorage.getItem("logged_user");
  if(to.meta.public){
    next();
  } else {
    if (isAuthenticated && logged_user) {
      // CRIA UM Date COM A DATA DO TOKEN
      const expiresIn = new Date(JSON.parse(logged_user).expires_in);
      // VERIFICA SE A DATA DE VALIDAÇÃO DO TOKEN E MAIOR QUE A DATA ATUAL
      const isExpired = expiresIn < new Date();
      // REDIRECIONA PARA O LOGIN CASO O TOKEN TENHA EXPIRADO
      if (isExpired) {
        next({ name: "login" })
      }
      // CONTINUA A ROTA CASO O TOKEN NÃO TENHA EXPIRADO
      next();
    } else {
      next({ name: "login" })
    }
  } 
})


export default router;