<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  title: { type: String, default: null },
  show: { type: Boolean, default: false },
  loading: {type: Boolean, default: true},
});

const show = ref(props.show);
const showClass = ref("")

watch(() => props.show, (value) => {
  show.value = value;
  setTimeout(() => {
    showClass.value = value ? "show" : "";
  }, 100)
});

const emits = defineEmits(["close", "save"]);

const close = () => {
  showClass.value = "";
  setTimeout(() => {
    emits("close");
  }, 100);
};

</script>

<template>
    <!-- Modal Right -->
    <div v-if="show" class="modal fade d-block pr-3" style="pointer-events: none;" tabindex="-1" role="dialog" aria-modal="true" :class="showClass">
      <div class="modal-dialog modal-dialog-right modal-sm d-flex">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title h4" v-if="title">{{ title }}</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body" style="overflow-y: scroll;">
            <div class="loading-css" v-if="loading">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            <!-- Form do modal  -->
            <slot />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="$emit('save')">
              <i class="fal fa-save"></i> Salvar
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('close')">
              <i class="fal fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active,
.v-enter-active .modal-wrapper-inner,
.v-leave-active .modal-wrapper-inner {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-from .modal-wrapper-inner,
.v-leave-to .modal-wrapper-inner {
  transform: translateY(-50px);
}

.loading-css {
  backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900;
}
</style>