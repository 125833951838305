<template>
  <form @submit.prevent="submitForm" id="colaboradorForm">
    <div class="row">

      <div class="col-12">
        <div class="row">

          <div class="col-6">
            <div class="form-group">
              <label class="form-label"
                     for="SelectSetorResponsavel">Nome*:</label>
              <input required type="text" class="form-control" name="inputNome"
                     id="inputNome"
                     v-model="form.nome"/>
              <span class="text-danger" v-if="errors.nome">{{ errors.nome[0] }}</span>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label class="form-label" for="inputCpf">CPF:</label>
              <input required type="text" class="form-control" name="inputCpf"
                     id="inputCpf"
                     v-model="form.cpf" v-mask="'###.###.###-##'"
                     placeholder="000.000.000-00"/>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6 mt-2">
            <div class="form-group">
              <label class="form-label" for="inputCelular">Celular:</label>
              <input required type="text" class="form-control" name="inputCelular" id="inputCelular"
                     v-model="form.celular" v-mask="'+## (##) #####-####'" placeholder="+## (##) #####-####"/>
            </div>
          </div>

          <div class="col-6 mt-2">
            <div class="form-group">
              <label class="form-label" for="inputEmail">E-mail*:</label>
              <div class="input-group">
                <input required type="email" class="form-control" name="inputEmail" id="inputEmail" v-model="form.email"/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button"
                          @click="alterarSenhaMensagem">
                    {{ titleButtom }}
                  </button>
                </div>
              </div>
              <span class="text-danger" v-if="errors.email">{{ errors.email[0] }}</span>
            </div>
          </div>

          <div class="col-6 mt-2">
            <div class="form-group">
              <label class="form-label" for="SelectSituacao">Situação*:</label>
              <select required name="SelectSituacao"
                      class="select2-cadClientes form-control"
                      v-model="form.situacao"
                      v-select="form.situacao">
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </select>
              <span class="text-danger" v-if="errors.situacao">{{ errors.situacao[0] }}</span>
            </div>
          </div>

          <div class="col-6 mt-2">
            <div class="form-group">
              <label class="form-label" for="SelectSetor">Setor*:</label>
              <select required multiple name="SelectSetor"
                      class="select2-cadClientes form-control"
                      v-model="form.setor">
                <option v-for="setor in optionsSetores" :value="setor.ID"
                        :key="setor.ID">
                  {{ setor.NOME }}
                </option>
              </select>
              <span class="text-danger" v-if="errors.setor">{{ errors.setor[0] }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</template>

<script>
/**
 * Importa o cliente HTTP Axios, a biblioteca jQuery e a biblioteca SweetAlert2 para uso no componente.
 * A biblioteca Axios é usada para fazer requisições HTTP para a API, a biblioteca jQuery é usada para interagir com o DOM,
 * e a biblioteca SweetAlert2 é usada para exibir mensagens de erro.
 */
import { api } from '@/axios'
import $ from 'jquery'
import swal from 'sweetalert2'

export default {
  name: 'ColaboradorForm',
  components: {},
  mounted () {
    $('.select2-cadClientes').each(function () {
      $(this).select2({
        dropdownParent: $(this).parent(),
      })
    })
    
    this.getSetores()
  },
  /**
   * Define as propriedades de dados para o componente ColaboradorForm.
   * O objeto `form` contém os campos para um colaborador, incluindo nome, CPF, telefone, email, senha, status e setores.
   * O array `optionsSetores` armazena os setores disponíveis que podem ser selecionados para o colaborador.
   */
  data () {
    return {
      form: {
        nome: '',
        cpf: '',
        celular: '',
        email: '',
        senha: '',
        situacao: '',
        setor: [],
      },
      optionsSetores: [],
      errors: [],
    }
  },
  computed: {
    titleButtom () {
      return this.form.senha === '' ? 'Criar Senha' : 'Alterar Senha'
    },
  },
  props: {
    modelValue: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    /**
     * Recupera os detalhes de um colaborador da API e preenche os campos do formulário.
     * Este método faz uma requisição GET para o endpoint `/api/users/{idColaborador}` para recuperar os detalhes do colaborador
     * com o ID especificado. Se a requisição for bem-sucedida, o método preenche os campos do formulário com os dados recuperados.
     * Se a requisição falhar, o método exibe uma mensagem de erro usando SweetAlert2.
     * @param {string|number|null|undefined} idColaborador - O ID do colaborador a ser recuperado.
     * @returns {Promise<boolean>}
     */
    async getColaborador (idColaborador) {

      if ([null, undefined, ''].includes(idColaborador)) return

      try {
        const response = await api.get(`/api/users/${idColaborador}`)

        if (!response.data.success) {
          swal.fire({
            title: 'Ops!',
            text: response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
          return
        }

        const dados = response.data.data
        const setores = dados.setor.map((valor) => valor.setor_id)

        this.form.nome = dados.nome
        this.form.cpf = dados.cpf
        this.form.email = dados.email
        this.form.celular = dados.phone
        this.form.senha = dados.senha
        this.form.situacao = dados.situacao
        this.form.setor = setores

        $('select[name=SelectSetor]').val(this.form.setor).trigger('change')

        console.log('Eu não acredito que eh isso ', this.form.setor)

        return true
      } catch (e) {
        console.log('%c Erro: ',
            'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e)
      }
    },

    /**
     * Recupera a lista de setores da API e armazena-os no array `optionsSetores`.
     * Este método faz uma requisição GET para o endpoint `/api/setores` para buscar a lista de setores. Se a requisição for bem-sucedida,
     * os dados da resposta são registrados no console e armazenados no array `optionsSetores`. Se a requisição falhar, uma mensagem
     * de erro é registrada no console.
     * @returns {Promise<void>} Uma Promise que é resolvida quando os dados dos setores forem buscados e armazenados.
     */
    getSetores () {
      try {
        api.get(`/api/setores`).then(response => {
          if (response.data.success) {
            console.log(
                '%c✔ Requisição feita com sucesso! Os dados que irão preencher o select serão:',
                'color: #148f32')
            console.table(response.data.data)
            console.log('Dados que voltaram da requisição:',
                response.data.data)
          }

          this.optionsSetores = response.data.data
        })
      } catch (e) {
        console.log('%c Erro: ',
            'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e)
      }
    },

    /**
     * Emite um evento para alternar a exibição de um chamado (caso).
     * @param {Object} chamado - O objeto do chamado (caso) a ser alternado.
     * @returns {void}
     */
    editChamado (chamado) {
      this.$emit('toggle', chamado)
    },

    /**
     * Formata um número de telefone removendo todos os caracteres não numéricos.
     * @param {string} phone - O número de telefone a ser formatado.
     * @returns {string} O número de telefone formatado com apenas caracteres numéricos.
     */
    formatMask (phone) {
      return phone?.replace(/[+\-\s().]/g, '') || ''
    },

    /**
     * Envia os dados do formulário para o servidor.
     * Este método é responsável por lidar com a lógica de submissão do formulário. Ele constrói um objeto com os dados do formulário, incluindo os campos de telefone e CPF formatados, e envia para o servidor usando o método HTTP apropriado (POST ou PUT) com base em se `modelValue` é nulo ou não.
     * Se o servidor responder com um status de sucesso, uma mensagem de sucesso é exibida usando SweetAlert. Se o servidor responder com um erro, uma mensagem de erro é exibida usando SweetAlert.
     * @returns {Promise<void>} Uma Promise que é resolvida quando a submissão do formulário estiver completa.
     */
    async submitForm () {
      const objetoRequisicao = {
        nome: this.form.nome,
        email: this.form.email,
        password: this.form.senha,
        phone: this.form.celular !== ''
            ? this.formatMask(this.form.celular)
            : null,
        setor: $('select[name=SelectSetor]').val(),
        situacao: parseInt(this.form.situacao),
        cpf: this.form.cpf !== '' ? this.formatMask(this.form.cpf) : null,
        admin: 0,
      }

      try {

        let response = ''

        if (this.modelValue == null) {
          response = await api.post(`/api/users`, objetoRequisicao)
        } else {
          response = await api.put(`/api/users/${this.modelValue}`,
              objetoRequisicao)
        }

        if (response.data.success) {
          swal.fire({
            title: 'Sucesso!',
            text: response?.data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          });
        }
        
        return true;

      } catch (error)
      {
        if (error.response) {
          const status = error.response.data.status;
          const errors = error.response.data.errors;

          if (status === 'validation_error') {
            // Exibe erros de validação
            this.handleErrors(errors);
          }

        } else {
          // Outro tipo de erro (ex: rede, timeout)
          console.log('Erro inesperado', error);
        }

        return false;
        //console.log('%c Erro: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e);
      }
    },
    handleErrors(errors) {
      this.errors = errors
    },

    /**
     * Exibe um diálogo Swal para permitir que o usuário insira uma nova senha para um colaborador.
     * Se o `modelValue` não for nulo, atualiza a senha do colaborador existente.
     * Caso contrário, define a nova senha para um novo colaborador.
     */
    alterarSenhaMensagem () {
      const textSwal = this.modelValue == null ? ' ' : ' nova '
      const title = this.modelValue == null
          ? 'Nova senha '
          : 'Alterar senha do usuário '

      swal.fire({
        title: `${title}`,
        html: `<small>Informe a${textSwal}senha do colaborador:</small><br> <input type="text" id="novaSenha" class="swal2-input">`,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Voltar',
        showCancelButton: true,
        focusConfirm: false,
      }).then(async (result) => {
        if (result.value) {
          let valor = $('#novaSenha').val()
          await this.setNovaSenhaColaborador(valor)
        }
      })
    },

    /**
     * Atualiza a senha de um colaborador.
     * Se `modelValue` for nulo, define a nova senha para um novo colaborador.
     * Caso contrário, atualiza a senha de um colaborador existente.
     * @param {string} valor - A nova senha a ser definida.
     * @returns {Promise<void>} - Uma Promise que é resolvida quando a atualização da senha estiver completa.
     */
    async setNovaSenhaColaborador (valor) {
      if ([null, undefined, ''].includes(this.modelValue)) {
        this.form.senha = valor
        return
      }

      try {
        const response = await api.put(`/api/users/senha/${this.modelValue}`,
            { senha: valor })

        if (response.data.success) {
          swal.fire({
            title: 'Sucesso!',
            text: response?.data.message,
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        } else {
          swal.fire({
            title: 'Ops!',
            text: response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        }
      } catch (e) {
        swal.fire({
          title: 'Ops!',
          text: e.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
        console.log('%c Erro: ',
            'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e)
      }
    },
  },
}
</script>

<style scoped>

</style>