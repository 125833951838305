import { createApp } from 'vue'
import '@/assets/css/main.css';
import '@/assets/js/main.js';


import App from './App.vue'
import store from './store'; // Vuex Store
import router from './router.js'; // Vue Router
import select2Plugin from './assets/plugins/select2-plugin';
import $ from 'jquery';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueTheMask from 'vue-the-mask'
import VueApexCharts from "vue3-apexcharts";

import 'datatables.net-rowreorder-dt/css/rowReorder.dataTables.css';


console.log('Initializing app...');
const app = createApp(App)

app.use(store)
app.use(router)
app.use(select2Plugin)
app.use(CKEditor )
app.use(VueTheMask)
app.use(VueApexCharts)


app.directive('select', {
    beforeMount: function (el) {
        $(el).select2().on("select2:select", (e) => {
            //  - a value with property path "$event.target.value"
            el.dispatchEvent(new Event('change', { target: e.target }));
        });
    },
    updated: function(el) {
        $(el).trigger("change");
    }
});

app.mount('#app')
console.log('App initialized.');