<template>
    <aside class="page-sidebar">
        <div class="page-logo">
            <a href="#" class="page-logo-link press-scale-down d-flex align-items-center position-relative"
                data-toggle="modal" data-target="#modal-shortcut">
                <img src="@/assets/img/logo-horizontal.svg" alt="Logo Adriana Leal" aria-roledescription="logo" class="img-fluid">
                <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
            </a>
        </div>
        <!-- BEGIN PRIMARY NAVIGATION -->
        <nav id="js-primary-nav" class="primary-nav js-list-filter" role="navigation">
            <!-- <div class="info-card">
                <img src="@/assets/_theme/img/demo/avatars/avatar-admin.png" class="profile-image rounded-circle"
                    alt="Nome Usuário">
                <div class="info-card-text">
                    <a href="#" class="d-flex align-items-center text-white">
                        <span class="text-truncate text-truncate-sm d-inline-block">
                            Nome Usuário
                        </span>
                    </a>
                    <span class="d-inline-block text-truncate text-truncate-sm">Setor</span>
                </div>
            </div> -->
            <ul id="js-nav-menu" class="nav-menu js-nav-built">
                <li>
                    <router-link to="/" title="Home" data-filter-tags="home"
                        class="waves-effect waves-themed">
                        <i class="fal fa-home"></i>
                        <span class="nav-link-text" data-i18n="nav.application_intel">Home</span>
                        <!-- <b class="collapse-sign"><em class="fal fa-angle-down"></em></b> -->
                    </router-link>
                </li>
                <li>
                    <router-link to="/atendimento" title="Atendimento" data-filter-tags="atendimento"
                        class="waves-effect waves-themed">
                        <i class="fal fa-comment"></i>
                        <span class="nav-link-text">Atendimento</span>
                        <!-- <b class="collapse-sign"><em class="fal fa-angle-down"></em></b> -->
                    </router-link>
                </li>
                <li>
                    <router-link to="/clientes" title="Clientes" data-filter-tags="cliente clientes" class="waves-effect waves-themed">
                        <i class="fal fa-users"></i>
                        <span class="nav-link-text">Clientes</span>
                    </router-link>
                </li>
                <li id="setores">
                    <router-link to="/setores" title="Setores" data-filter-tags="setor setores" class="waves-effect waves-themed">
                        <i class="fal fa-sitemap"></i>
                        <span class="nav-link-text">Setores</span>
                    </router-link>
                </li>
                <li id="colaboradores" v-if="admin">
                    <router-link to="/colaboradores" title="Colaboradores" data-filter-tags="colaborador colaboradores" class="waves-effect waves-themed">
                        <i class="fal fa-users-crown"></i>
                        <span class="nav-link-text">Colaboradores</span>
                    </router-link>
                </li>
                <li>
                    <a href="javascript:void(0)" title="Relatórios" data-filter-tags="relatórios" class="waves-effect waves-themed">
                        <i class="fal fa-file-chart-line"></i>
                        <span class="nav-link-text" data-i18n="nav.relatorios">Relatórios</span>
                        <b class="collapse-sign"><em class="fal fa-angle-down"></em></b>
                    </a>
                    <ul>
                        <li>
                            <router-link to="/relatorios/atendimento-por-cliente" title="Atendimento por Cliente" data-filter-tags="relatório relatórios Atendimento por Cliente"
                                class="waves-effect waves-themed">
                                <span class="nav-link-text" data-i18n="nav.relatorios_atendimento_por_cliente">Atendimento por Cliente</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/relatorios/atendimento-por-colaborador" title="Atendimento por Colaborador" data-filter-tags="relatório relatórios Atendimento por Colaborador"
                                class=" waves-effect waves-themed">
                                <span class="nav-link-text" data-i18n="nav.relatorios_atendimento_por_colaborador">Atendimento por Colaborador</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/relatorios/atendimento-por-setor" title="Atendimento por Setor" data-filter-tags="relatório relatórios Atendimento por Setor"
                                class=" waves-effect waves-themed">
                                <span class="nav-link-text" data-i18n="nav.relatorios_atendimento_por_setor">Atendimento por Setor</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/relatorios/estatisticas-gerais" title="Estatísticas Gerais" data-filter-tags="relatório relatórios Estatísticas Gerais" class=" waves-effect waves-themed">
                                <span class="nav-link-text" data-i18n="nav.relatorios_estatisticas_gerais">Estatísticas Gerais</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <!-- <li>
                    <router-link to="/configuracoes" title="Configurações" data-filter-tags="configuração configurações" class="waves-effect waves-themed">
                        <i class="fal fa-cogs"></i>
                        <span class="nav-link-text">Configurações</span>
                    </router-link>
                </li> -->
            </ul>
            <!-- <div class="filter-message js-filter-message bg-success-600"></div> -->
        </nav>
        <!-- END PRIMARY NAVIGATION -->
        <!-- NAV FOOTER -->
        <div class="nav-footer shadow-top">
            <a href="#" onclick="return false;" data-action="toggle" data-class="nav-function-minify"
                class="hidden-md-down">
                <i class="ni ni-chevron-right"></i>
                <i class="ni ni-chevron-right"></i>
            </a>
            <ul class="list-table m-auto nav-footer-buttons">
                <li>
                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title=""
                        data-original-title="Chat logs">
                        <i class="fal fa-comments"></i>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title=""
                        data-original-title="Support Chat">
                        <i class="fal fa-life-ring"></i>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title=""
                        data-original-title="Make a call">
                        <i class="fal fa-phone"></i>
                    </a>
                </li>
            </ul>
        </div> <!-- END NAV FOOTER -->
    </aside>
</template>


<script>
export default {
    name: "asidebar-component",
    data () {
        return {
            admin: false
        }
    },
    mounted(){
        this.displays_collaborators_menu()
    },
    methods: {
        displays_collaborators_menu(){
            const loggedUser = JSON.parse(localStorage.getItem('logged_user'));
            if(loggedUser){
                this.admin = loggedUser.access_type !== null ? true : false;
                // create_collaborators_menu(loggedUser.access_type);
            }
        }
    }
}

// function create_collaborators_menu (access) {
//     if(access !== null){
//         let li = document.createElement('li');
//         let routerLink = document.createElement('a');
//         routerLink.setAttribute('title', 'Colaboradores');
//         routerLink.setAttribute('data-filter-tags', 'colaborador colaboradores');
//         routerLink.classList.add('waves-effect', 'waves-themed');
//         routerLink.setAttribute('href', '/colaboradores');
//         let icon = document.createElement('i');
//         icon.classList.add('fal', 'fa-users-crown');
//         let span = document.createElement('span');
//         span.classList.add('nav-link-text');
//         span.textContent = 'Colaboradores';
//         let sector = document.getElementById('setores');

//         routerLink.appendChild(icon);
//         routerLink.appendChild(span);

//         li.appendChild(routerLink);

//         sector.parentNode.insertBefore(li, sector.nextSibling);
//     }
// }
</script>