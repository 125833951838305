<!--

 * O componente ClientsPage é responsável por renderizar uma tabela de informações de clientes.
 * Ele obtém os dados dos clientes de uma API e os exibe em uma tabela com colunas para o nome do cliente, empresa, telefone, e-mail e tickets abertos/em progresso/fechados.
 * O componente também oferece funcionalidades para editar e excluir clientes, além de filtrar os dados por consulta de pesquisa e status do ticket.
 * 
 * 
 * Estamos chamando os "Atendimentos" de Chamados na parte do código, porém Adriana e seus funcionarios
 * chamam isso de Atendimentos, então na questão visual trataremos Chamados como Atendimentos 

 -->

<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-content-wrapper">
        <main id="js-page-content" role="main" class="page-content">
          <div class="d-flex flex-grow-1 p-0">
            <div
              class="flex-wrap flex-shrink-0 position-relative slide-on-mobile slide-on-mobile-left bg-body p-3 bg-white mr-2"
              id="js-slide-left"
            >
              <div
                class="row fullsize-table"
                style="overflow-y: auto; overflow-x: hidden"
              >
                <div class="col-12 bg-body stickTop">
                  <div class="col-12 p-0">
                    <button
                      class="btn btn-info w-100"
                      id="clientes-new"
                      @click="
                        showFormCliente = true;
                        loadingModalCliente = false;
                      "
                    >
                      <i class="fal fa-1x fa-users"></i> Novo Cliente
                    </button>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <form
                    @submit.prevent="handleSubmit"
                    id="filterForm"
                    class="row"
                  >
                    <div class="col-12 mt-2">
                      <label for="filter-search" class="form-label"
                        >Pesquisar por Palavra-Chave</label
                      >
                      <input
                        type="search"
                        autocomplete="off"
                        class="form-control"
                        data-table-api="search"
                        id="filter-search"
                        v-model="searchQuery"
                      />
                    </div>
                    <div class="col-12 mt-2">
                      <label class="form-label" for="situacaoChamados">
                        Situação de Chamados
                      </label>
                      <select
                        class="select2 form-control w-100"
                        id="situacaoChamados"
                        v-model="situacao"
                        v-select="situacao"
                      >
                        <option value="">Todos</option>
                        <option value="1">Aberto</option>
                        <option value="2">Em Andamento</option>
                        <option value="3">Fechados</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div class="col-12 stickBottom bg-body mt-2">
                  <div class="row mt-2">
                    <div class="col-8">
                      <button
                        class="btn btn-success mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        type="submit"
                        form="filterForm"
                        id="btn-filter"
                      >
                        <i class="fal fa-search mr-1 mt-1"></i>
                        Pesquisar
                      </button>
                    </div>
                    <div class="col-4">
                      <button
                        class="btn btn-light mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        data-toggle="tooltip"
                        title=""
                        data-placement="top"
                        type="button"
                        id="btn-clear-filter"
                        data-original-title="Limpar Filtro"
                        @click="clearFilter"
                      >
                        <i class="fal fa-times mt-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="slide-backdrop"
                data-action="toggle"
                data-class="slide-on-mobile-left-show"
                data-target="#js-slide-left"
              ></div>
            </div>
            <div
              class="d-flex flex-column flex-grow-1 overflow-hidden bg-white"
            >
              <div class="row h-100">
                <div class="col-12">
                  <div class="panel h-100">
                    <div class="panel-hdr">
                      <h2>
                        <i class="subheader-icon fal fa-users"></i>
                        <!-- Estamos chamando os "Atendimentos" de Chamados na parte do código, porém Adriana e seus funcionarios
                         chamam isso de Atendimentos, então na questão visual trataremos Chamados como Atendimentos -->
                        <span class="fw-300">Clientes e Atendimentos</span>
                      </h2>
                    </div>
                    <div class="panel-container show h-100">
                      <div class="panel-content p-0 h-100">
                        <DataTable
                          v-if="!loading"
                          class="table table-bordered table-hover table-striped w-100 mb-0"
                          :options="options"
                          :columns="columns"
                          :data="data"
                        >
                          <thead>
                            <tr>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                *
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Nome
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Haras/Fazenda
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Celular
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                E-mail
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Abertos
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Andamento
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Fechados
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody style="text-align: center"></tbody>
                        </DataTable>
                        <Loading v-else />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>

  <ModalCenteredLarge
    :id="idCliente"
    :show="showFormCliente"
    :title="currentCliente ? 'Editar Cliente' : 'Novo Cliente'"
    @close="hideForm()"
    :loading="loadingModalCliente"
    @save="submitCliente"
  >
    <ClientesForm
      ref="clientesFormRef"
      :modelValue="currentCliente"
      @toggle="toggleChamado"
    />
  </ModalCenteredLarge>

  <ModalCenteredLarge
    :id="idChamado"
    :show="showFormChamado"
    :title="currentChamado ? 'Editar Atendimento' : 'Novo Atendimento'"
    @close="hideFormChamado()"
    @save="submitChamado()"
  >
    <ChamadoForm
      ref="chamadoFormRef"
      :modelValue="currentChamado"
      :loading="loadingModalChamado"
      :submitChamado="submitChamado"
    />
  </ModalCenteredLarge>
</template>

<script>
/**
 * Importa os componentes e bibliotecas necessários para o componente ClientsPage.
 * - `defineComponent` de 'vue' é usado para definir o componente Vue.
 * - `DataTable` e `DataTablesCore` são importados das bibliotecas 'datatables.net-vue3' e 'datatables.net', respectivamente, para fornecer um componente de tabela de dados.
 * - 'datatables.net-scroller-bs4' é importado para fornecer funcionalidades adicionais para a tabela de dados.
 * - `api` é importado de '@/axios' para lidar com requisições de API.
 * - `AsideNav`, `HeaderMain` e `BreadcrumbMain` são importados como componentes filhos para o componente ClientsPage.
 */
import { defineComponent } from "vue";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import "datatables.net-scroller-bs4";
import { api } from "@/axios";
import Loading from "@/components/generals/LoadingDefault.vue";
import $ from "jquery";
import ModalCenteredLarge from "@/components/modals/ModalCenteredLarge.vue";
import ClientesForm from "./forms/ClientesForm.vue";
import ChamadoForm from "@/components/Home/Forms/ChamadoForm.vue";
import swal from "sweetalert2";

DataTable.use(DataTablesCore);

/**
 * Define o componente ClientsPage, responsável por renderizar uma tabela de dados de clientes.
 *
 * O componente utiliza o componente DataTable da biblioteca 'datatables.net-vue3' para exibir os dados dos clientes.
 * Os dados são buscados da API utilizando o módulo `api` de '@/axios'.
 *
 * O componente possui as seguintes funcionalidades:
 * - Exibe uma tabela com colunas para nome do cliente, empresa, telefone, e-mail e chamados abertos/em andamento/fechados.
 * - Fornece botões para editar e excluir clientes.
 * - Permite filtrar os dados dos clientes por consulta de pesquisa e status.
 * - Gerencia a lógica para buscar e atualizar os dados dos clientes.
 */
export default defineComponent({
  name: "ClientsPage",
  components: {
    ChamadoForm,
    ModalCenteredLarge,
    ClientesForm,
    DataTable,
    Loading,
  },
  /**
   * Define as propriedades de dados para o componente ClientsPage.
   * - `idCliente`: O ID do modal do cliente.
   * - `idChamado`: O ID do modal do chamado (caso).
   * - `showFormChamado`: Uma flag para controlar a visibilidade do formulário do chamado.
   * - `currentChamado`: O ID do chamado atual exibido no formulário.
   * - `clientesFormRef`: Uma referência ao componente ClientesForm.
   * - `chamadoFormRef`: Uma referência ao componente ChamadoForm.
   * - `currentCliente`: O cliente atual exibido ou editado.
   * - `showFormCliente`: Uma flag para controlar a visibilidade do formulário do cliente.
   * - `data`: Os dados para o componente DataTable.
   * - `options`: As opções para o componente DataTable, incluindo configurações de idioma.
   * - `columns`: As colunas a serem exibidas no DataTable, incluindo funções de renderização personalizadas.
   * - `searchQuery`: A consulta de pesquisa atual para filtragem dos dados.
   * - `situacao`: O filtro de status atual para os dados.
   * - `loading`: Uma flag para indicar se os dados estão sendo carregados no momento.
   */
  data() {
    return {
      loadingModalCliente: true,
      loadingModalChamado: true,
      idCliente: "modal-cliente-chamado",
      idChamado: "modal-chamado",
      showFormChamado: false,
      currentChamado: "",
      clientesFormRef: "",
      chamadoFormRef: "",
      currentCliente: "",
      showFormCliente: false,
      data: null,
      options: {
        paging: false,
        pageLength: -1,
        dom: "t",
        language: {
          emptyTable: "Nenhum Resultado Encontrado",
          zeroRecords: "Nenhum Resultado Encontrado",
          decimal: ",",
          info: "Mostrando _START_ a _END_ de _TOTAL_ resultados",
          infoEmpty: "Nenhum Resultado Encontrado",
          thousands: ".",
          loadingRecords: "Carregando...",
          processing: "",
          search: "",
          searchPlaceholder: "Pesquisar",
          aria: {
            orderable: "Ordenar por essa coluna",
            orderableReverse: "Ordem inversa desta coluna",
          },
        },
      },
      columns: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + 1;
          },
        },
        {
          data: "NOME",
          render: function (data) {
            return data ? data.toUpperCase() : "";
          },
        },
        {
          data: "EMPRESA",
          render: function (data) {
            return data ? data.toUpperCase() : "";
          },
        },
        {
          data: "TELEFONE",
          render: function (data) {
            const cleaned = ("" + data).replace(/\D/g, "");

            // Ajuste a regex para capturar tanto números com 8 quanto 9 dígitos
            const match = cleaned.match(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/);

            if (match) {
              return `<span class="text-nowrap">+${match[1]} (${match[2]}) ${match[3]}-${match[4]}</span>`;
            }

            return data;
          },
        },
        {
          data: "EMAIL",
        },
        {
          data: "CHAMADOS_ABERTOS",
        },
        {
          data: "CHAMADOS_EM_ANDAMENTO",
        },
        {
          data: "CHAMADOS_FECHADOS",
        },
        {
          data: "ID",
          render: function (data, type, row) {
            return `<div style="white-space: nowrap" ">
                        <button class="btn btn-sm btn-light btn-icon rounded-circle waves-effect waves-themed mr-2"
                                      data-toggle="tooltip" data-original-title="Editar Cliente"
                                      onclick="window.dispatchEvent(new CustomEvent('edit-cliente', { detail: ${row.ID} }))">
                                <i class="fal fa-pencil"></i>
                        </button>
                        <button onclick="window.dispatchEvent(new CustomEvent('delete-cliente', { detail: ${row.ID} }))" class="btn btn-sm btn-outline-danger btn-icon rounded-circle waves-effect waves-themed"
                                    data-toggle="tooltip" data-original-title="Excluir Cliente">
                                <i class="fal fa-times"></i>
                        </button>
                    </div>`;
          },
        },
      ],
      searchQuery: "",
      situacao: "",
      loading: true,
    };
  },
  /**
   * Conecta-se ao ciclo de vida do componente para configurar ouvintes de eventos para edição e exclusão de clientes.
   * - Inicializa a biblioteca select2 para elementos dropdown.
   * - Chama o método `handleSubmit` quando o componente é montado.
   * - Adiciona ouvintes de eventos para os eventos 'edit-cliente' e 'delete-cliente', que chamam os métodos `handleEdit` e `handleDelete`, respectivamente.
   * - Remove os ouvintes de eventos quando o componente é desmontado.
   */
  mounted() {
    $(".select2").select2();

    this.handleSubmit();
    window.addEventListener("edit-cliente", this.handleEdit);
    window.addEventListener("delete-cliente", this.handleDelete);
  },
  beforeUnmount() {
    window.removeEventListener("edit-cliente", this.handleEdit);
    window.removeEventListener("delte-cliente", this.handleDelete);
  },
  methods: {
    /**
     * Formata uma string de número de telefone adicionando parênteses ao redor do código de área e um hífen entre o prefixo e o número da linha.
     * @param {string} number - O número de telefone a ser formatado.
     * @returns {string} O número de telefone formatado.
     */
    formatPhoneNumber(number) {
      const cleaned = ("" + number).replace(/\D/g, "");

      const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);

      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }

      return number;
    },

    /**
     * Envia o formulário do chamado (caso).
     * - Recupera a referência do formulário do chamado a partir do componente.
     * - Se a referência do formulário estiver disponível e tiver um método `submitForm`, chama esse método para enviar o formulário.
     * - Se a referência do formulário não estiver disponível ou o método `submitForm` não estiver definido, registra uma mensagem de erro no console.
     */
    submitChamado() {
      const chamadosForm = this.$refs.chamadoFormRef;
      if (chamadosForm && typeof chamadosForm.submitForm === "function") {
        chamadosForm.submitForm();
      } else {
        console.error("submitForm não está disponível no chamadoForm");
      }
    },

    /**
     * Limpa os filtros de pesquisa.
     * As propriedades `searchQuery`, `searchFilterSetor`, `searchFilterCliente`, `searchFilterSituacao`
     * e `searchFilterUrgencia` são definidas como strings vazias.
     */
    clearFilter() {
      this.searchQuery = "";
      this.situacao = "";

      this.handleSubmit();
    },

    /**
     * Alterna a exibição do formulário do chamado (caso) e define a propriedade `currentChamado` para o ID do chamado fornecido.
     * @param {number} idChamado - O ID do chamado a ser exibido no formulário.
     */
    toggleChamado(idChamado) {
      this.currentChamado = idChamado;
      this.showFormChamado = true;

      this.$nextTick(() => {
        this.$refs.chamadoFormRef.fetchChamadoData().then(() => {
          this.loadingModalChamado = false;
        });
      });
    },

    /**
     * Oculta o formulário e redefine a propriedade currentCliente.
     */
    hideForm() {
      this.currentCliente = null;
      this.showFormCliente = false;
      this.loadingModalCliente = true;

      this.handleSubmit();
    },

    /**
     * Oculta o formulário do chamado.
     */
    hideFormChamado() {
      this.showFormChamado = false;
      this.currentChamado = null;
      this.loadingModalChamado = true;
    },

    /**
     * Envia o formulário do cliente.
     * - Recupera a referência ao formulário do cliente a partir do componente.
     * - Se a referência do formulário estiver disponível e tiver um método `submitForm`, chama esse método para enviar o formulário.
     * - Se a referência do formulário não estiver disponível ou o método `submitForm` não estiver definido, registra uma mensagem de erro no console.
     */
    submitCliente() {
      const clienteForm = this.$refs.clientesFormRef;
      this.loadingModalCliente = true;

      if (clienteForm && typeof clienteForm.submitForm === "function") {
        clienteForm.submitForm().then((response) => {
          this.loadingModalCliente = false;
          if (response) {
            this.hideForm();
          }
        });
      } else {
        console.error("submitForm não está disponível no chamadoForm");
      }
    },

    /**
     * Gerencia o envio do formulário de pesquisa de clientes.
     * - Recupera os dados dos clientes da API com base na consulta de pesquisa e no filtro de status.
     * - Atualiza a propriedade `data` com os dados dos clientes recuperados.
     * - Registra os dados atualizados no console.
     * - Trata quaisquer erros que ocorram durante a requisição à API.
     */
    async handleSubmit() {
      this.loading = true;
      try {
        const keyword = this.searchQuery || "";
        const status_id = this.situacao || "";

        const response = await api.get("/api/clientes", {
          params: {
            keyword: keyword,
            status_id: status_id,
          },
        });

        if (response.data.success) {
          console.log(
            "%c✔ Requisição feita com sucesso! Os dados que irão preencher a tabela serão:",
            "color: #148f32"
          );
          console.table(response.data.data);
          console.log("Dados que voltaram da requisição:", this.data);
        }

        this.data = response.data.data;

        this.loading = false;
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
      }
    },

    /**
     * Trata a edição de um cliente.
     * - Define a propriedade `currentCliente` para o ID do cliente a partir da propriedade `detail` do evento.
     * - Define a propriedade `showFormCliente` como `true` para exibir o formulário do cliente.
     * @param {Object} clientId - O ID do cliente a ser editado.
     * @param {number} clientId.detail - O ID do cliente a ser editado.
     */
    handleEdit(clientId) {
      this.currentCliente = clientId.detail;
      this.showFormCliente = true;

      this.$nextTick(() => {
        this.$refs.clientesFormRef.getCliente(this.currentCliente).then((_) => {
          this.loadingModalCliente = false;
        });
      });
    },

    /**
     * Trata a exclusão de um cliente.
     * - Exibe uma caixa de confirmação usando o SweetAlert2.
     * - Se o usuário confirmar, envia uma requisição DELETE para a API para excluir o cliente.
     * - Se a exclusão for bem-sucedida, atualiza a lista de clientes chamando o método `handleSubmit`.
     * - Se ocorrer um erro, exibe uma mensagem de erro usando o SweetAlert2.
     * @param {Object} clientId - O ID do cliente a ser excluído.
     * @param {number} clientId.detail - O ID do cliente a ser excluído.
     */
    handleDelete(clientId) {
      clientId = clientId.detail;
      swal
        .fire({
          title: "Atenção!",
          text: "Deseja realmente excluir este cliente?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: true,
        })
        .then(async (response) => {
          if (!response.value) return;

          const retorno = await api.delete(`/api/clientes/${clientId}`, {});

          if (retorno.data.success) {
            swal.fire({
              title: "Sucesso!",
              text: retorno.data.message,
              icon: "success",
              confirmButtonText: "Ok",
            });
            return;
          }

          this.handleSubmit();
        });
    },
  },
});
</script>

<style scoped>
:deep(.dataTables_wrapper) {
  max-height: calc(100vh - 246px) !important;
  overflow: auto;
}

:deep(.fullsize-table) {
  max-height: calc(100vh - 205px);
}
</style>
