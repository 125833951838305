// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDn6E5qKLXCuHYFJ0zFYQqx36mFfxmAXco",
  authDomain: "chat-atendimento-dev.firebaseapp.com",
  projectId: "chat-atendimento-dev",
  storageBucket: "chat-atendimento-dev.appspot.com",
  messagingSenderId: "74875067080",
  appId: "1:74875067080:web:31fa12178082e0f714e3fd"
}

// Initialize Firebase
initializeApp(firebaseConfig)

const db = getFirestore()
export default db