<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-content-wrapper">
        <main id="js-page-content" role="main" class="page-content">
          <div class="d-flex flex-grow-1 p-0">
            <div
              class="flex-wrap flex-shrink-0 position-relative slide-on-mobile slide-on-mobile-left bg-body p-3 bg-white mr-2"
              id="js-slide-left"
            >
              <div
                class="row fullsize-table"
                style="overflow-x: hidden; overflow-y: auto"
              >
                <div class="col-12 bg-body stickTop">
                  <div class="col-12 p-0">
                    <button
                      class="btn btn-info w-100"
                      id="colaboradores-new"
                      @click="
                        showFormColaborador = true;
                        loadingColaborador = false;
                      "
                    >
                      <i class="fal fa-1x fa-users-crown mr-1"></i>
                      Novo Colaborador
                    </button>
                  </div>
                </div>

                <div class="col-12 mt-3">
                  <form
                    @submit.prevent="handleSubmit"
                    id="filterForm"
                    class="row"
                  >
                    <div class="col-12 mt-2">
                      <label for="filter-search" class="form-label"
                        >Pesquisar por Palavra-Chave</label
                      >
                      <input
                        type="search"
                        autocomplete="off"
                        class="form-control"
                        data-table-api="search"
                        id="filter-search"
                        v-model="searchQuery"
                      />
                    </div>
                    <div class="col-12 mt-2">
                      <label class="form-label" for="situacaoChamados">
                        Setor
                      </label>
                      <select
                        class="select2 form-control w-100"
                        id="setorChamados"
                        v-model="Colaborador"
                        v-select="Colaborador"
                      >
                        <option value="">Todos</option>
                        <option
                          v-for="Colaborador in ColaboradorData"
                          :value="Colaborador.id"
                          :key="Colaborador.id"
                        >
                          {{ Colaborador.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 mt-2">
                      <label class="form-label" for="situacaoChamados">
                        Situação de Chamados
                      </label>
                      <select
                        class="select2 form-control w-100"
                        id="situacaoChamados"
                        v-model="situacao"
                        v-select="situacao"
                      >
                        <option value="" selected>Todos</option>
                        <option value="1">Aberto</option>
                        <option value="2">Em Andamento</option>
                        <option value="3">Fechados</option>
                      </select>
                    </div>
                  </form>
                </div>

                <div class="col-12 stickBottom bg-body mt-2">
                  <div class="row mt-2">
                    <div class="col-8">
                      <button
                        class="btn btn-success mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        type="submit"
                        form="filterForm"
                        id="btn-filter"
                      >
                        <i class="fal fa-search mr-1 mt-1"></i>
                        Pesquisar
                      </button>
                    </div>
                    <div class="col-4">
                      <button
                        class="btn btn-light mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                        data-toggle="tooltip"
                        title=""
                        data-placement="top"
                        type="button"
                        id="btn-clear-filter"
                        data-original-title="Limpar Filtro"
                        @click="clearFilter"
                      >
                        <i class="fal fa-times mt-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="slide-backdrop"
                data-action="toggle"
                data-class="slide-on-mobile-left-show"
                data-target="#js-slide-left"
              ></div>
            </div>
            <div
              class="d-flex flex-column flex-grow-1 overflow-hidden bg-white"
            >
              <div class="row h-100">
                <div class="col-12">
                  <div class="panel h-100">
                    <div class="panel-hdr">
                      <h2>
                        <i class="subheader-icon fal fa-users-crown"></i>
                        <span class="fw-300">Colaboradores e Atendimentos</span>
                      </h2>
                    </div>
                    <div class="panel-container show h-100">
                      <div class="panel-content p-0 h-100">
                        <DataTable
                          v-if="!loading"
                          class="table table-bordered table-hover table-striped w-100 mb-0"
                          :options="options"
                          :columns="columns"
                          :data="data"
                        >
                          <thead>
                            <tr>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                *
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Colaborador
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Setor
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Celular
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                E-mail
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Abertos
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Andamento
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Fechados
                              </th>
                              <th
                                style="white-space: nowrap"
                                class="text-center cursor-pointer"
                              >
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody style="text-align: center"></tbody>
                        </DataTable>
                        <Loading v-else />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>

  <ModalCenteredLarge
    :show="showFormColaborador"
    :title="currentColaborador ? 'Editar Colaborador' : 'Novo Colaborador'"
    :loading="loadingColaborador"
    @close="hideForm()"
    @save="submitColaborador"
  >
    <ColaboradorForm
      ref="colaboradorFormRef"
      :modelValue="currentColaborador"
      :submitChamado="submitColaborador"
    />
  </ModalCenteredLarge>
</template>

<script>
/**
 * Importa os componentes e bibliotecas necessários para o componente ColaboradoresPage.
 * - `defineComponent` do 'vue' é utilizado para definir o componente Vue.
 * - `DataTable` e `DataTablesCore` são importados das bibliotecas 'datatables.net-vue3' e 'datatables.net' respectivamente, que são usadas para criar uma tabela de dados.
 * - `'datatables.net-scroller-bs4'` é importado para funcionalidades adicionais.
 * - `{api}` é importado de '@/axios' para lidar com requisições de API.
 * - `AsideNav`, `HeaderMain` e `BreadcrumbMain` são importados como componentes filhos.
 */
import { defineComponent } from "vue";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import "datatables.net-scroller-bs4";
import { api } from "@/axios";
import Loading from "@/components/generals/LoadingDefault.vue";
import $ from "jquery";
import ColaboradorForm from "@/components/Collaborators/forms/ColaboradorForm.vue";
import ModalCenteredLarge from "@/components/modals/ModalCenteredLarge.vue";
import swal from "sweetalert2";

DataTable.use(DataTablesCore);

/**
 * Define o componente ColaboradoresPage, responsável por renderizar uma tabela de dados de Colaboradores.
 *
 * O componente utiliza o componente DataTable da biblioteca 'datatables.net-vue3' para exibir os dados dos Colaboradores.
 * Os dados são buscados da API utilizando o módulo `api` de '@/axios'.
 *
 * O componente possui as seguintes funcionalidades:
 * - Exibe uma tabela com colunas para nome, colaboradores, e chamados abertos/em andamento/fechados.
 * - Fornece botões para editar.
 * - Permite filtrar os dados dos Colaboradores por consulta de pesquisa e status.
 * - Gerencia a lógica para buscar e atualizar os dados dos Colaboradores.
 */
export default defineComponent({
  name: "ColaboradoresPage",
  components: {
    ModalCenteredLarge,
    ColaboradorForm,
    DataTable,
    Loading,
  },
  /**
   * Define as propriedades de dados e opções para o componente ColaboradoresPage.
   *
   * - `data`: Armazena os dados para a tabela de Colaboradores.
   * - `options`: Configura as opções para o componente DataTable, incluindo configurações de idioma.
   * - `columns`: Define as colunas para a tabela de Colaboradores, incluindo funções de renderização para cada coluna.
   * - `searchQuery`: Armazena a consulta de pesquisa para filtrar os Colaboradores.
   * - `situacao`: Armazena o filtro de status para os Colaboradores.
   */
  data() {
    return {
      loadingColaborador: true,
      currentColaborador: "",
      showFormColaborador: false,
      colaboradorFormRef: "",
      data: [],
      options: {
        paging: false,
        pageLength: -1,
        dom: "t",
        language: {
          emptyTable: "Nenhum Resultado Encontrado",
          zeroRecords: "Nenhum Resultado Encontrado",
          decimal: ",",
          info: "Mostrando _START_ a _END_ de _TOTAL_ resultados",
          infoEmpty: "Nenhum Resultado Encontrado",
          thousands: ".",
          loadingRecords: "Carregando...",
          processing: "",
          search: "",
          searchPlaceholder: "Pesquisar",
          aria: {
            orderable: "Ordenar por essa coluna",
            orderableReverse: "Ordem inversa desta coluna",
          },
        },
      },
      columns: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + 1;
          },
        },
        {
          data: "NOME",
          render: function (data) {
            return data ? data.toUpperCase() : "";
          },
        },
        {
          data: "SETOR",
          render: function (data) {
            return `<span style="white-space: nowrap;">${data}</span>`;
          },
        },
        {
          data: "TELEFONE",
          render: function (data) {
            const cleaned = ("" + data).replace(/\D/g, "");

            // Ajuste a regex para capturar tanto números com 8 quanto 9 dígitos
            const match = cleaned.match(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/);

            if (match) {
              return `<span class="text-nowrap">+${match[1]} (${match[2]}) ${match[3]}-${match[4]}</span>`;
            }

            return data;
          },
        },
        {
          data: "EMAIL",
        },
        {
          data: "CHAMADOS_ABERTOS",
        },
        {
          data: "CHAMADOS_EM_ANDAMENTO",
        },
        {
          data: "CHAMADOS_FECHADOS",
        },
        {
          data: "ID",
          render: function (data, type, row) {
            return `<div style="white-space: nowrap">
                        <button class="btn btn-sm btn-light btn-icon rounded-circle waves-effect waves-themed mr-2"
                                      data-toggle="tooltip" data-original-title="Editar Colaborador"
                                      onclick="window.dispatchEvent(new CustomEvent('edit-colaborador', { detail: ${row.ID} }))">
                                <i class="fal fa-pencil"></i>
                        </button>
                        <button onclick="window.dispatchEvent(new CustomEvent('delete-colaborador', { detail: ${row.ID} }))" class="btn btn-sm btn-outline-danger btn-icon rounded-circle waves-effect waves-themed"
                                    data-toggle="tooltip" data-original-title="Excluir Colaborador">
                                <i class="fal fa-times"></i>
                        </button>
                    </div>`;
          },
        },
      ],
      searchQuery: "",
      Colaborador: "",
      situacao: "",
      ColaboradorData: [],
      loading: true,
    };
  },
  /**
   * Busca os dados dos Colaboradores e atualiza a propriedade de dados do componente quando o componente é montado.
   */
  created() {
    this.getSetorsSelect();
  },
  /**
   * Busca os dados da tabela após inicializar.
   */
  mounted() {
    this.handleSubmit();

    $(".select2").select2();

    window.addEventListener("edit-colaborador", this.handleEdit);
    window.addEventListener("delete-colaborador", this.handleDelete);
  },
  beforeUnmount() {
    window.removeEventListener("edit-colaborador", this.handleEdit);
    window.removeEventListener("delete-colaborador", this.handleDelete);
  },
  methods: {
    /**
     * Submete o formulário do colaborador.
     *
     * Este método verifica se a referência `colaboradorFormRef` existe e possui uma função `submitForm`. Se sim, chama a função `submitForm` para submeter o formulário. Se a função `submitForm` não estiver disponível, registra uma mensagem de erro no console.
     */
    submitColaborador() {
      const clienteForm = this.$refs.colaboradorFormRef;
      this.loadingColaborador = true;

      if (clienteForm && typeof clienteForm.submitForm === "function") {
        clienteForm.submitForm().then((response) => {
          this.loadingColaborador = false;
          if (response) {
            this.hideForm();
          }
        });
      } else {
        console.error("submitForm não está disponível no chamadoForm");
      }
    },

    /**
     * Oculta o formulário do colaborador e atualiza os dados dos colaboradores.
     *
     * Este método é chamado quando o usuário deseja ocultar o formulário do colaborador. Ele define a propriedade `currentColaborador` como `null` e a propriedade `showFormColaborador` como `false` para ocultar o formulário. Em seguida, chama o método `handleSubmit` para atualizar os dados dos colaboradores.
     */
    hideForm() {
      this.currentColaborador = null;
      this.showFormColaborador = false;
      this.loadingColaborador = true;
      this.$refs.colaboradorFormRef.form.setor.pop();

      this.handleSubmit();
    },

    /**
     * Busca e atualiza os dados dos Colaboradores.
     *
     * Este método é responsável por fazer uma requisição GET para o endpoint `/api/users` com parâmetros de consulta opcionais para `keyword`, `id` e `status_id`. Os dados da resposta são então armazenados na propriedade `data` do componente.
     *
     * Se a requisição for bem-sucedida, o método registra os dados da resposta no console. Se ocorrer um erro, o método registra o erro no console.
     *
     * @async
     */
    async handleSubmit() {
      this.loading = true;
      try {
        const keyword = this.searchQuery || "";
        const colaborador = this.Colaborador || "";
        const status_id = this.situacao || "";

        const response = await api.get("/api/users", {
          params: {
            keyword: keyword,
            id: colaborador,
            status_id: status_id,
          },
        });

        this.data = response.data.data;

        this.loading = false;
      } catch (error) {
        console.error("Erro ao buscar Colaboradores:", error);
      }
    },

    /**
     * Lida com a ação de edição de um colaborador.
     *
     * Este método é chamado quando o usuário deseja editar um colaborador. Ele registra o ID do colaborador no console e define as propriedades `currentColaborador` e `showFormColaborador` para exibir o formulário de edição do colaborador.
     *
     * @param {object} colaboradorId - Um objeto contendo o ID do colaborador a ser editado.
     * @param {number} colaboradorId.detail - O ID do colaborador a ser editado.
     */
    handleEdit(colaboradorId) {
      console.log(`Editar Colaborador ID: ${colaboradorId.detail}`);

      this.currentColaborador = colaboradorId.detail;
      this.showFormColaborador = true;

      this.$nextTick(() => {
        this.$refs.colaboradorFormRef
          .getColaborador(this.currentColaborador)
          .then((_) => {
            this.loadingColaborador = false;
          });
      });
    },

    /**
     * Busca a lista de setores da API e preenche o array `ColaboradorData` com os dados dos setores.
     *
     * Este método é responsável por fazer uma requisição GET para o endpoint `/api/setores`, recuperar os dados dos setores e armazená-los no array `ColaboradorData`. Espera-se que os dados dos setores possuam as propriedades `ID` e `NOME`, que são usadas para criar as opções do dropdown de seleção de setores.
     *
     * Se a requisição à API for bem-sucedida, o método registra os dados da resposta no console. Se ocorrer um erro, o método registra o erro no console.
     */
    async getSetorsSelect() {
      try {
        await api.get("/api/setores").then((response) => {
          if (response.data.success) {
            console.log(
              "%c✔ Requisição feita com sucesso! Os dados que irão preencher o select serão:",
              "color: #148f32"
            );
            console.table(response.data.success);
            console.log("Dados que voltaram da requisição:", this.data);
          }

          response.data.data.forEach((element) => {
            this.ColaboradorData.push({
              id: element.ID,
              name: element.NOME,
            });
          });
        });
      } catch (e) {
        console.log(
          "%c Erro: ",
          "color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);",
          e
        );
      }
    },

    /**
     * Limpa o filtro de pesquisa e redefine as propriedades Colaborador e situação, em seguida, chama o método handleSubmit.
     * Este método é usado para redefinir o estado do filtro e iniciar uma nova pesquisa.
     */
    clearFilter() {
      this.searchQuery = "";
      this.Colaborador = "";
      this.situacao = "";

      this.handleSubmit();
    },

    /**
     * Exclui um colaborador do sistema.
     *
     * @param {number} colaboradorId - O ID do colaborador a ser excluído.
     * @returns {Promise<void>} - Uma Promise que é resolvida quando o colaborador for excluído com sucesso.
     */
    handleDelete(colaboradorId) {
      colaboradorId = colaboradorId.detail;
      swal
        .fire({
          title: "Atenção!",
          text: "Deseja realmente excluir este colaborador?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: true,
        })
        .then(async (response) => {
          if (!response.value) return;

          const retorno = await api.delete(`/api/users/${colaboradorId}`, {});

          if (retorno.data.success) {
            swal.fire({
              title: "Sucesso!",
              text: retorno.data.message,
              icon: "success",
              confirmButtonText: "Ok",
            });
          }

          this.handleSubmit();
        });
    },
  },
});
</script>

<style scoped>
:deep(.dataTables_wrapper) {
  max-height: calc(100vh - 246px) !important;
  overflow: auto;
}

:deep(.fullsize-table) {
  max-height: calc(100vh - 205px);
}
</style>
