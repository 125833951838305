<script setup>
import { computed } from "vue";
import { onMounted } from "vue";
import { reactive } from "vue";
import { calcularDiferencaDias } from "@/assets/js/main";
import $ from "jquery";
import { Popover } from "bootstrap";

const props = defineProps({
  atendimento: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["edit"]);
function editAtendimento(chamado) {
  emit("edit", chamado);
}

const dateNow = computed(() => {
  return new Date();
});

const atendimento = reactive(props.atendimento);

const statusObj = computed(() => ({
  "badge-success": atendimento.status_id == "2",
  "badge-warning": atendimento.status_id == "3",
  "badge-danger": atendimento.status_id == "1",
}));

const urgenciaClass = computed(() => ({
  "card-urgencia-baixa": atendimento.urgencia == 1,
  "card-urgencia-media": atendimento.urgencia == 2,
  "card-urgencia-alta": atendimento.urgencia == 3,
}));

const diasDiferenca = computed(() => {
  return calcularDiferencaDias(atendimento.dateCriacao, dateNow.value);
});

onMounted(() => {
  new Popover($(`#${atendimento.id} > a`)[0]);
});
</script>

<style scoped>
.text-title-atendimento > h5 {
  font-size: 1.2rem;
  font-weight: 700;
}

hr {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  border-bottom: 1.5px solid #eee;
}

.card-urgencia-baixa {
  border-left: 4px solid #1dc9b7 !important;
}
.card-urgencia-media {
  border-left: 4px solid #ffc241 !important;
}
.card-urgencia-alta {
  border-left: 4px solid var(--danger) !important;
}
li > a {
  background-color: transparent;
}
</style>

<template>
  <li
    class="card rounded border-faded mb-3"
    :class="urgenciaClass"
    :id="atendimento.id"
  >
    <a
      class="d-table w-100 px-2 py-2 text-dark hover-white border-0 cursor-pointer"
      data-filter-tags="{{ atendimento.assunto }}"
      @click="editAtendimento(atendimento.id)"
      data-toggle="popover"
      data-trigger="hover"
      data-placement="top"
      title=""
      :data-content="atendimento.descricao"
    >
      <div class="w-100 align-middle pl-2 pr-2">
        <div class="text-title-atendimento">
          <div class="fs-xl fw-500">
            {{ atendimento.id }} - {{ atendimento.assunto }}
            <p>
              <small>{{ atendimento.cliente }}</small>
            </p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-4 text-center">
            <div>Aberto em</div>
            <small class="badge border border-light ml-auto">
              <!-- {{ atendimento.created_at_format }} -->
              {{ atendimento.dateCriacao?.toLocaleDateString() }}
            </small>
          </div>
          <div class="col-md-4 text-center">
            <div>Dias Aberto</div>
            <small class="badge border border-light ml-auto">
              <!-- {{ atendimento.created_at_format }} -->
              {{ diasDiferenca }}
            </small>
          </div>
          <div class="col-md-4 text-center">
            <div>Data Final</div>
            <small class="badge ml-auto d-inline-block" :class="statusObj">
              <!-- {{ atendimento.created_at_format }} -->
              {{ atendimento.data_final_prevista_format }}
            </small>
          </div>
        </div>
      </div>
    </a>
  </li>
</template>
