<template>
  <li
    v-for="contact in contacts"
    :key="contact.phone"
    @click="selectContato(contact)"
    :class="{ active: contact.active }"
    :id="contact.phone"
  >
    <div
      class="d-flex w-100 px-3 py-2 text-dark hover-white cursor-pointer show-child-on-hover align-items-center"
    >
      <div
        class="profile-image-lg rounded-circle"
        :style="
          imageProfileContact(
            contact?.profileThumbnail
              ? contact?.profileThumbnail
              : contact?.contato?.foto
          )
        "
      ></div>
      <div class="px-2">
        <div class="text-truncate text-truncate-sm">
          {{
            contact?.contato?.nome
              ? contact?.contato?.nome
              : contact?.name ?? "Cliente não identificado"
          }}
          <small class="d-block text-muted text-truncate text-truncate-md">
            {{ formatPhoneNumber(contact.phone) }}
          </small>
          <small
            v-if="contact.contato?.chamados_abertos > 0"
            class="d-block text-truncate text-truncate-md"
            style="
              background-color: #ffbc2179;
              border-radius: 5px;
              padding: 2px 4px;
              display: inline-block;
              font-size: 11px;
              border: #ffb605 2px solid;
              line-height: 1;
            "
          >
            <b>Atendimentos: {{ contact.contato?.chamados_abertos }}</b>
          </small>
        </div>
      </div>
      <div class="d-flex align-items-end ml-auto">
        <span
          v-if="contact.unread > 0"
          class="badge border-light rounded-pill bg-danger-500 ml-1"
          >{{ contact.unread }}</span
        >
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "chat-contatos-component",
  computed: {},
  props: {
    contacts: {
      type: Array,
      // required: true
    },
  },

  methods: {
    /**
     * Formata uma string de número de telefone adicionando parênteses ao redor do código de área e um hífen entre o prefixo e o número da linha.
     * @param {string} number - O número de telefone a ser formatado.
     * @returns {string} O número de telefone formatado.
     */
    formatPhoneNumber(number) {
      const cleaned = ("" + number).replace(/\D/g, "");

      const match = cleaned.match(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/);

      if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
      }

      return number;
    },
    selectContato(contato) {
      this.$emit("contactSelected", contato);
    },
    imageProfileContact(imgUrl) {
      if (!imgUrl) {
        imgUrl = require("@/assets/img/user-default.svg");
      }
      return {
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
      };
    },
  },
  mounted() {},
};
</script>
