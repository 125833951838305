<template>
  <form @submit.prevent="submitForm" id="chamadoForm">
    <div class="row">
      <div :class="emAtendimento ? 'col-12' : 'col-md-5'">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label" for="SelectSetorResponsavel"
              >Setor Responsável*:</label
              >
              <select
                  required
                  name="SetorResponsavel"
                  id="SetorResponsavel"
                  class="select2-cadAtendimento form-control"
                  v-model="form.setorResponsavel"
                  v-select="form.setorResponsavel"
              >
                <option
                    v-for="setor in optionsSetores.values"
                    :value="setor.ID"
                    :key="setor.ID"
                >
                  {{ setor.NOME }}
                </option>
              </select>
              <span class="text-danger" v-if="errors.setorResponsavel">{{
                  errors.setorResponsavel[0]
                }}</span>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label" for="SelectSetorAuxliar"
              >Setores Auxiliares:</label
              >
              <select
                  required
                  name="SetorAuxliar"
                  id="SetorAuxliar"
                  multiple
                  class="select2-cadAtendimento form-control"
                  v-model="form.setoresAuxiliares"
                  v-select="form.setoresAuxiliares"
              >
                <option
                    v-for="setor in optionsSetores.values"
                    :value="setor.ID"
                    :key="setor.ID"
                >
                  {{ setor.NOME }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row mt-2" :class="emAtendimento ? 'd-none' : ''">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label" for="SelectSetorAuxliar"
              >Cliente*:</label
              >
              <div class="row align-items-center">
                <div class="col">
                  <select
                      required
                      name="Cliente"
                      id="Cliente"
                      class="select2-cadAtendimento form-control"
                      v-model="form.phone"
                      v-select="form.phone"
                  >
                    <option
                        v-for="cliente in optionsClientes.values"
                        :value="`${cliente.TELEFONE}-${cliente.ID}`"
                        :key="`${cliente.TELEFONE}-${cliente.ID}`"
                    >
                      {{ cliente.NOME }} - {{ cliente.TELEFONE }}
                    </option>
                  </select>
                  <span class="text-danger" v-if="errors.cliente_id">{{
                      errors.cliente_id[0]
                    }}</span>
                </div>
                <div
                    class="col-auto pl-0 align-items-end text-right text-sm-center"
                >
                  <button
                      @click="editCliente"
                      class="ml-2 btn btn-primary"
                      type="button"
                  >
                    <i class="fa fal fa-pencil"></i>
                  </button>
                  <button
                      @click="$emit('toggle')"
                      class="ml-2 btn btn-primary"
                      type="button"
                      id="atendimento-new"
                  >
                    <i class="fa fal fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label class="form-label" for="SelectUrgencia">Urgência*:</label>
              <select
                  required
                  name="SelectUrgencia"
                  class="select2-cadAtendimento form-control"
                  v-model="form.urgencia"
                  v-select="form.urgencia"
              >
                <option
                    v-for="urgencia in optionsUrgencias"
                    :value="urgencia.value"
                    :key="urgencia.value"
                    :selected="urgencia.value === 1"
                >
                  {{ urgencia.name }}
                </option>
              </select>
              <span class="text-danger" v-if="errors.urgencia">{{
                  errors.urgencia[0]
                }}</span>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label class="form-label" for="InputDataFinal"
              >Data Final*:</label
              >
              <input
                  required
                  type="date"
                  class="form-control"
                  name="InputDataFinal"
                  id="InputDataFinal"
                  v-model="form.data_final_prevista"
              />
              <span class="text-danger" v-if="errors.data_final_prevista">{{
                  errors.data_final_prevista[0]
                }}</span>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label" for="TextAreaDetalhesSolicitacao"
              >Detalhes da Solicitação:</label
              >
              <textarea
                  name="TextAreaDetalhesSolicitacao"
                  required
                  class="form-control"
                  id="TextAreaDetalhesSolicitacao"
                  v-model="form.detalhesSolicitacao"
                  rows="10"
              />
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label" for="SelectSituacao">Situação*:</label>

              <div class="row align-items-start">
                <div class="col">
                  <select
                      required
                      name="SelectSituacao"
                      class="select2-cadAtendimento form-control"
                      v-model="form.situacao"
                      v-select="form.situacao"
                  >
                    <option
                        v-for="status in optionsSituacoes"
                        :value="status.value"
                        :key="status.value"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                  <span class="text-danger" v-if="errors.status_id">{{
                      errors.status_id[0]
                    }}</span>
                </div>
                <div
                    class="col-auto pl-0 align-items-end text-right text-sm-center"
                    v-if="form.phone != '' && !emAtendimento"
                >
                  <button
                      class="btn btn-success"
                      type="button"
                      @click="abreConversa"
                      id="AbreConversa"
                  >
                    <i class="fal fa-comment"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="emAtendimento">
        <hr/>
      </div>
      <div :class="emAtendimento ? 'col-12' : 'col-md-7'">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label" for="TextAreaOcorrencias"
              >Informe aqui ocorrências do atendimento:</label
              >
              <div class="row">
                <div class="col-12">
                  <div id="app">
                    <textarea
                        class="form-control"
                        id="TextAreaOcorrencias"
                        v-model="form.newOcorrencias"
                        rows="1"
                    />
                  </div>
                </div>

                <div class="col-12 mt-2">
                  <label class="form-label" for="inputAnexos">Anexos:</label>

                  <CustomFileInput
                      ref="files"
                      @click="idMensagemOcorrencia = false"
                  />
                  <!--                  <div class="input-group">-->
                  <!--                    <div class="input-group-prepend">-->
                  <!--                      <button class="btn btn-default" type="button" @click="launchFilePicker">-->
                  <!--                        <span class="fal fa-paperclip" aria-hidden="true"></span>-->
                  <!--                      </button>-->
                  <!--                    </div>-->
                  <!--                    <input type="text" class="form-control" placeholder="Escolha um ou mais arquivos..." readonly :value="fileNames"/>-->
                  <!--                    <input type="file" name="file" multiple ref="files" class="form-control d-none" @change="updateFileNames"/>-->
                  <!--                  </div>-->
                </div>

                <div class="col-12 mt-2">
                  <button
                      class="btn btn-primary float-right"
                      type="button"
                      @click="setOcorrencia"
                  >
                    <i class="fal fa-save"></i> Registrar Ocorrência
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-5 p-0 overflow-hidden">
          <p>Histórico de Ocorrências</p>
          <DataTable
              class="table table-bordered table-hover table-striped w-100 mb-0"
              ref="dataTable"
              :options="options"
              :columns="columns"
              :data="form.ocorrencias"
          >
            <thead>
            <tr>
              <th style="white-space: nowrap">*</th>
              <th style="white-space: nowrap">Data/Hora</th>
              <th style="white-space: nowrap">Ocorrência</th>
              <th style="white-space: nowrap">Responsável</th>
              <th style="white-space: nowrap">Anexo</th>
            </tr>
            </thead>
            <tbody></tbody>
          </DataTable>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
/**
 * Importa várias dependências e bibliotecas usadas no componente ChamadoForm, incluindo:
 * - `api` do módulo `@/axios` para fazer requisições API
 * - `$` da biblioteca `jquery` para manipulação do DOM
 * - `DataTable` e `DataTablesCore` da biblioteca `datatables.net-vue3` para renderização de tabelas de dados
 * - `ClassicEditor` da biblioteca `@ckeditor/ckeditor5-build-classic` para o componente CKEditor
 */
import {api} from "@/axios";
import $ from "jquery";
import {DataTable} from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import swal from "sweetalert2";
import {format} from "date-fns";
import {ptBR} from "date-fns/locale";
import CustomFileInput from "@/components/generals/CustomFIleInput.vue";

DataTable.use(DataTablesCore);

export default {
  components: {CustomFileInput, DataTable},
  /**
   * Define as propriedades de dados para o componente ChamadoForm.
   *
   * @property {Object} optionsSetores - Um objeto contendo os valores para o dropdown de setores.
   * @property {Object} optionsClientes - Um objeto contendo os valores para o dropdown de clientes.
   * @property {Array} optionsSituacoes - Um array de objetos representando as opções para o dropdown de situação.
   * @property {Array} optionsUrgencias - Um array de objetos representando as opções para o dropdown de urgência.
   * @property {Object} form - Um objeto contendo os dados do formulário, incluindo campos como id, phone, setorResponsavel, setoresAuxiliares, urgencia, data_final_prevista, detalhesSolicitacao, ocorrencias, newOcorrencias e situacao.
   * @property {Object} options - Um objeto contendo as opções para o componente DataTable, incluindo DOM, paginação, scrollCollapse, scrollY e configurações de idioma.
   * @property {Array} columns - Um array de objetos definindo as colunas para o componente DataTable, incluindo funções de dados e renderização.
   */
  data()
  {
    let logged_user = JSON.parse(localStorage.getItem("logged_user"));
    return {
      idMensagemOcorrencia: false,
      dataTable: "",
      optionsSetores: {values: []},
      optionsClientes: {values: []},
      optionsSituacoes: [
        {value: "1", name: "Aberto"},
        {value: "3", name: "Em Andamento"},
        {value: "2", name: "Fechado"},
      ],
      optionsUrgencias: [
        {value: 1, name: "Baixa"},
        {value: 2, name: "Média"},
        {value: 3, name: "Alta"},
      ],
      form: {
        id: "",
        phone: "",
        setorResponsavel: "",
        setoresAuxiliares: [],
        urgencia: "1",
        data_final_prevista: "",
        detalhesSolicitacao: "",
        ocorrencias: [],
        newOcorrencias: "",
        situacao: "1",
      },
      fileNames: "",
      options: {
        paging: false,
        pageLength: -1,
        dom: "t",
        scrollY: "195",
        scrollX: true,
        language: {
          emptyTable: "Nenhum Resultado Encontrado",
          zeroRecords: "Nenhum Resultado Encontrado",
          decimal: ",",
          info: "Mostrando _START_ a _END_ de _TOTAL_ resultados",
          infoEmpty: "Nenhum Resultado Encontrado",
          thousands: ".",
          loadingRecords: "Carregando...",
          processing: "",
          search: "",
          searchPlaceholder: "Pesquisar",
          aria: {
            orderable: "Ordenar por essa coluna",
            orderableReverse: "Ordem inversa desta coluna",
          },
        },
      },
      columns: [
        {
          data: null,
          render: function (data, type, row, meta)
          {
            return meta.row + 1;
          },
        },
        {
          data: "created_at",
          render: function (data)
          {
            const dateObj = new Date(data);
            const formattedDate = format(dateObj, "dd/MM/yyyy", {
              locale: ptBR,
            });
            const formattedTime = format(dateObj, "HH:mm:ss", {locale: ptBR});
            return data ? `${formattedDate} <p>${formattedTime}</p>` : "";
          },
        },
        {
          data: "ocorrencia",
        },
        {
          data: null,
          render: function (data)
          {
            return data.user?.name || logged_user.nome;
          },
        },
        {
          data: "arquivos",
          render: function (data, type, row)
          {
            if (row.arquivos?.length > 0)
            {
              return `
                  ${row.arquivos
              .map(
                  (arquivo) =>
                      `<div style="white-space: nowrap">
                    <button class="btn btn-white btn-visualizar-arquivo" type="button" onclick="window.dispatchEvent(new CustomEvent('visualizar-arquivo', { detail: {id_empresa: '${row.user.empresa_id}', path: '${arquivo.nome_arquivo}' } }))">
                          <i class="fal fa-paperclip"></i>'
                    </button>
                    <button class="btn btn-white download-arquivo" type="button" onclick="window.dispatchEvent(new CustomEvent('download-arquivo', { detail: {id_empresa: '${row.user.empresa_id}', path: '${arquivo.nome_arquivo}' } }))">
                          <i class="fal fa-download"></i>'
                    </button></div>`
              )
              .join("")}
                `;
            }

            return "";
          },
        },
      ],
      errors: [],
    };
  },
  /**
   * Props para o componente ChamadoForm.
   *
   * @property {Object} modelValue - O valor do modelo para o formulário.
   * @property {string} phone - O número de telefone para o formulário.
   * @property {string} newCliente - O novo cliente para o formulário.
   */
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    phone: {
      type: String,
      default: "",
    },
    newCliente: {
      type: String,
      default: "",
    },
    emAtendimento: {
      type: Boolean,
      default: false,
    },
    messageSelected: {
      type: Object,
      default: () => ({}),
    },
  },
  /**
   * Observa as props `phone` e `newCliente` e atualiza o campo `form.phone` de acordo.
   *
   * - Quando a prop `phone` muda, atualiza o campo `form.phone` com o novo valor ou uma string vazia se o novo valor for falsy.
   * - Quando a prop `newCliente` muda, primeiro chama o método `getClientes()`, e depois atualiza o campo `form.phone` com o novo valor ou uma string vazia se o novo valor for falsy.
   * - O watcher para `phone` está configurado com `immediate: true`, o que significa que será acionado imediatamente após a criação do componente.
   * - O watcher para `newCliente` está configurado com `immediate: false`, o que significa que não será acionado imediatamente após a criação do componente.
   */
  watch: {
    'form.setorResponsavel': {
      handler(newVal) {
        const setor = this.optionsSetores.values.find(valor => valor.ID == newVal);

        if (setor) {
          const dataAtual = new Date();

          const diasParaExecutar = typeof setor.DIAS_PARA_EXECUTAR === 'number' ? setor.DIAS_PARA_EXECUTAR : 0;
          dataAtual.setDate(dataAtual.getDate() + diasParaExecutar);

          this.form.data_final_prevista = dataAtual.toISOString().split('T')[0];
        } else {
          this.form.data_final_prevista = '';
        }
      },
      immediate: true,
    },

    phone: {
      handler(newVal)
      {
        this.form.phone = newVal || "";
      },
      immediate: true,
    },
    newCliente: {
      async handler(newVal)
      {
        await this.getClientes();
        console.log("O valor veio e ficou assim:", newVal);
        this.form.phone = newVal || "";
      },
      immediate: false,
    },
  },
  methods: {
    handleAddFile(dataTransfer, nomeArquivo, id) {
      this.idMensagemOcorrencia = id;
      const input = this.$refs.files;

      const dataTransferItems = dataTransfer.items;
      const fileList = new DataTransfer();

      for (let i = 0; i < dataTransferItems.length; i++) {
        const originalFile = dataTransferItems[i].getAsFile();

        const renamedFile = new File([originalFile], nomeArquivo, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        fileList.items.add(renamedFile);
      }

      input.$refs.fileInput.files = fileList.files;

      $("input[name='file']").trigger("change");

      input.fileNames = nomeArquivo;
    },

    /**
     * Formata uma string de data para uma string de data localizada no formato 'dd/MM/yyyy'.
     *
     * @param {string} dateString - A string de data a ser formatada.
     * @returns {string} A string de data formatada.
     */
    formatDate(dateString)
    {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      const formattedDate = `${day}/${month}/${year}`;
      const formattedTime = `${hours}:${minutes}:${seconds}`;

      return `${formattedDate} ${formattedTime}`;
    },

    /**
     * Obtém os dados de um chamado específico da API e atualiza os campos do formulário de acordo.
     *
     * - Se `modelValue` for nulo, o método retorna sem fazer nada.
     * - Envia uma requisição GET para o endpoint `/api/chamados/{modelValue}` para buscar os dados do chamado.
     * - Mapeia os dados da resposta para atualizar os campos do formulário, incluindo `id`, `phone`, `setorResponsavel`, `setoresAuxiliares`, `urgencia`, `data_final_prevista`, `detalhesSolicitacao`, `ocorrencias` e `situacao`.
     * - Formata o campo `created_at` do array `ocorrencias` usando o método `formatDate`.
     * - Registra a requisição bem-sucedida e os dados atualizados do formulário no console.
     *
     * @returns {Promise<boolean>} - Uma Promise que é resolvida com `true` se a busca for bem-sucedida, ou `false` caso contrário.
     */
    async fetchChamadoData()
    {
      try
      {
        if (this.modelValue == null)
        {
          this.form.detalhesSolicitacao = this.messageSelected?.text_msg;
          return;
        }

        const response = await api.get(`/api/chamados/${this.modelValue}`);

        if (response.data)
        {
          console.log(
              "%c✔ Requisição feita com sucesso! Os dados que irão preencher os campos do modal serão:",
              "color: #148f32"
          );
          console.table(response.data);
          console.log("Dados que voltaram da requisição:", response.data);

          const data = response.data;
          const ocorrencias = data.ocorrencia.map((valores) => ({
            ...valores,
            created_at: this.formatDate(valores.created_at),
          }));

          this.form.id = data?.id || "";
          this.form.phone =
              `${data?.phone || ""}-${data?.cliente_id || ""}` || this.phone;
          this.form.setorResponsavel = data?.setor?.[0].id || "";
          this.form.setoresAuxiliares =
              data?.setor?.slice(1).map((valores) => valores.id) || [];
          this.form.urgencia = data?.urgencia || "";
          this.form.data_final_prevista = data?.data_final_prevista || "";
          this.form.detalhesSolicitacao = data?.descricao || "";
          this.form.ocorrencias = data.ocorrencia;
          this.form.situacao = data?.status_id || "";

          console.log("Form atualizado com sucesso:", this.form);
          return true;
        }
      } catch (e)
      {
        console.log(
            "%c Erro na requisição: ",
            "color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);",
            e
        );
      }
    },

    /**
     * Busca a lista de setores da API e atualiza a propriedade `optionsSetores.values` com os dados da resposta.
     *
     * Este método é usado para preencher as opções de seleção de setores no formulário.
     *
     * @returns {Promise<void>} - Uma Promise que é resolvida quando os dados dos setores foram buscados e armazenados.
     */
    async getSetores()
    {
      try
      {
        const response = await api.get(`/api/setores`);
        if (response.data.success)
        {
          console.log(
              "%c✔ Requisição feita com sucesso! Os dados que irão preencher o select serão:",
              "color: #148f32"
          );
          console.table(response.data.data);
          console.log("Dados que voltaram da requisição:", response.data.data);
          this.optionsSetores.values = response.data.data;
        }
      } catch (e)
      {
        console.log(
            "%c Erro ao buscar setores: ",
            "color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);",
            e
        );
      }
    },

    /**
     * Busca a lista de clientes da API e atualiza a propriedade `optionsClientes.values` com os dados da resposta.
     *
     * Este método é usado para preencher as opções de seleção de clientes no formulário.
     *
     * @returns {Promise<void>} - Uma Promise que é resolvida quando os dados dos clientes foram buscados e armazenados.
     */
    async getClientes()
    {
      try
      {
        const response = await api.get(`/api/clientes`);
        if (response.data.success)
        {
          console.log(
              "%c✔ Requisição feita com sucesso! Os dados que irão preencher o select serão:",
              "color: #148f32"
          );
          console.table(response.data.data);
          console.log("Dados que voltaram da requisição:", response.data.data);
          this.optionsClientes.values = response.data.data;
        }
      } catch (e)
      {
        console.log(
            "%c Erro ao buscar clientes: ",
            "color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);",
            e
        );
      }
    },

    /**
     * Envia os dados do formulário para a API para criar ou atualizar uma solicitação de serviço.
     *
     * Se `modelValue` for nulo, cria uma nova solicitação de serviço com os dados do formulário, incluindo quaisquer anexos.
     * Se `modelValue` não for nulo, atualiza a solicitação de serviço existente com os novos dados do formulário e anexos.
     *
     * O método constrói um objeto FormData com os dados do formulário e quaisquer anexos, e então envia uma requisição POST ou PUT para o endpoint da API.
     * Se a requisição for bem-sucedida, emite um evento 'saved' para notificar o componente pai.
     * Se houver um erro, exibe uma mensagem de erro usando o SweetAlert2.
     *
     * @returns {Promise<void>} - Uma Promise que é resolvida quando o formulário tiver sido enviado com sucesso.
     */
    async submitForm()
    {
      console.log("Valor setor: ", this.form.setoresAuxiliares);

      const [phone, clientId] = (this.form.phone || "").split("-");

      let formData = new FormData();

      formData.append("phone", phone || "");
      formData.append("cliente_id", clientId || "");
      formData.append("descricao", this.form.detalhesSolicitacao || "");
      formData.append("status_id", this.form.situacao);
      formData.append("urgencia", this.form.urgencia);
      formData.append("data_final_prevista", this.form.data_final_prevista);
      formData.append("setorResponsavel", this.form.setorResponsavel);
      formData.append(
          "setoresAuxiliares",
          JSON.stringify(this.form.setoresAuxiliares)
      );
      try
      {
        let response;
        if (this.modelValue == null)
        {
          this.form.ocorrencias.forEach((ocorrencia, index) =>
          {
            formData.append(
                `ocorrencias[${index}][ocorrencia]`,
                ocorrencia.ocorrencia
            );
            formData.append(
                `ocorrencias[${index}][user_id]`,
                ocorrencia.user_id
            );
            formData.append(
                `ocorrencias[${index}][created_at]`,
                ocorrencia.created_at
            );
            formData.append(
                `ocorrencias[${index}][mensagem_id]`,
                ocorrencia.mensagem_id
            );
            ocorrencia.arquivos.forEach((arquivo, fileIndex) =>
            {
              formData.append(
                  `ocorrencias[${index}][arquivos][${fileIndex}][nome_arquivo]`,
                  arquivo.nome_arquivo
              );
              formData.append(
                  `ocorrencias[${index}][arquivos][${fileIndex}][file]`,
                  arquivo.file
              );
            });
          });

          response = await api.post(`/api/chamados`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(
              "%c🛈 info: ",
              "color: #005eff;",
              "Resposta da requisição:",
              response.data
          );
          this.$emit("saved");
        }
        else
        {
          formData.append("_method", "PUT");

          response = await api.postForm(
              `/api/chamados/${this.modelValue}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
          );
          console.log(
              "%c🛈 info: ",
              "color: #005eff;",
              "Resposta da requisição:",
              response.data
          );
        }

        if (response.data.success)
        {
          swal.fire({
            title: "Sucesso!",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          });
          this.$emit("saved");
          console.log("Form do chamado submetido com sucesso!");
          return true;
        }
      } catch (error)
      {
        if (error.response)
        {
          const status = error.response.data.status;
          const errors = error.response.data.errors;

          if (status === "validation_error")
          {
            // Exibe erros de validação
            this.handleErrors(errors);
          }
        }
        else
        {
          // Outro tipo de erro (ex: rede, timeout)
          console.log("Erro inesperado", error);
        }

        console.log(
            "%c Erro ao submeter o formulário: ",
            "color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);",
            error
        );
        return false;
      }
    },
    handleErrors(errors)
    {
      this.errors = errors;
    },

    /**
     * Define a ocorrência para o caso atual.
     *
     * Se o `modelValue` for nulo, cria uma nova ocorrência com os arquivos fornecidos e a adiciona ao array `form.ocorrencias`.
     * Se o `modelValue` não for nulo, atualiza a ocorrência com os arquivos fornecidos fazendo uma requisição PUT para o endpoint `/api/chamados/ocorrencias/{modelValue}`.
     *
     * @returns {void}
     */
    async setOcorrencia()
    {
      if (this.form.newOcorrencias == "") return;

      let logged_user = JSON.parse(localStorage.getItem("logged_user"));

      if (this.modelValue == null)
      {
        let arquivos = [];

        if (this.$refs.files.$refs.fileInput.files.length > 0)
        {
          console.log(this.$refs.files.$refs.fileInput.files.length)
          arquivos = Array.from(this.$refs.files.$refs.fileInput.files).map(
              (file, index) =>
              {
                console.log(index, file.name, file)
                return {
                  index: index,
                  nome_arquivo: file?.name,
                  file: file,
                };
              }
          );
        }

        this.form.ocorrencias.unshift({
          ocorrencia: this.form.newOcorrencias,
          user_id: logged_user.id,
          user: {id: logged_user.id, empresa_id: logged_user.empresa_id},
          created_at: new Date().toISOString(),
          arquivos: arquivos,
          mensagem_id: this.idMensagemOcorrencia ?? null
        });

        this.form.newOcorrencias = "";
        this.$refs.files.$refs.fileInput.value = "";
        this.$refs.files.updateFileNames();
        this.idMensagemOcorrencia = false;

        setTimeout(() =>
        {
          $(".dropdown-toggle").trigger("click").dropdown("hide");
        }, 500);

        return;
      }

      let arquivosOcorrencias = new FormData();

      const files = this.$refs.files.$refs.fileInput.files;

      for (let i = 0; i < files.length; i++)
      {
        arquivosOcorrencias.append("file[]", files[i]);
      }

      arquivosOcorrencias.append(`ocorrencia`, this.form.newOcorrencias);

      if (this.idMensagemOcorrencia)
        arquivosOcorrencias.append(`id`, this.idMensagemOcorrencia);

      arquivosOcorrencias.append(`_method`, "PUT");

      try
      {
        const response = await api.postForm(
            `/api/chamados/ocorrencias/${this.modelValue}`,
            arquivosOcorrencias
        );

        if (response.data.success)
        {
          console.log(
              "%c✔ Requisição feita com sucesso! Os dados que irão preencher os campos da tabela serão:",
              "color: #148f32"
          );
          console.table(response.data.data);
          console.log("Dados que voltaram da requisição:", response.data.data);

          const data = [response.data.data];
          const ocorrencia = data.map((valores) => ({
            ...valores,
            created_at: this.formatDate(valores.created_at),
          }));

          console.log("Ocorrencia adicionada com sucesso:", ocorrencia[0]);

          if (this.idMensagemOcorrencia)
          {
            this.$emit("salvoArquivo", ocorrencia[0]);
          }

          this.form.ocorrencias.unshift(response.data.data);
          this.form.newOcorrencias = "";
          this.$refs.files.$refs.fileInput.value = "";
          this.$refs.files.updateFileNames();
          this.idMensagemOcorrencia = false;

          setTimeout(() =>
          {
            $(".dropdown-toggle").trigger("click").dropdown("hide");
          }, 500);
        }
      } catch (e)
      {
        console.log(
            "%c Erro na requisição: ",
            "color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);",
            e
        );
      }
    },

    /**
     * Cria uma representação de URL de dados do arquivo fornecido.
     *
     * @param {File} file - O arquivo a ser convertido para uma URL de dados.
     * @returns {Promise<string>} Uma promessa que é resolvida com a representação de URL de dados do arquivo.
     */
    criaSrcImagem(file)
    {
      return new Promise((resolve, reject) =>
      {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },

    /**
     * Recupera o link para um arquivo associado a uma ocorrência em um caso e o exibe em uma lightbox.
     *
     * @param {Object} dados - Um objeto contendo detalhes sobre o arquivo a ser exibido.
     * @param {number} dados.id - O ID do arquivo.
     * @param {number} dados.id_empresa - O ID da empresa associada ao arquivo.
     * @param {string} dados.path - O caminho para o arquivo.
     */
    async getLinkArquivo(dados)
    {
      $(".btn-visualizar-arquivo").off('visualizar-arquivo')

      if ([null, undefined, ""].includes(this.modelValue))
      {
        const {id, id_empresa, path} = event.detail;

        const ocorrencia = this.form.ocorrencias.find((o) =>
            o.arquivos.find((a) => a.id === id)
        );

        if (ocorrencia)
        {
          const arquivo = ocorrencia.arquivos.find((a) => a.id === id);
          if (!arquivo) return;

          try
          {
            const valor = await this.criaSrcImagem(arquivo.file);
            this.$emit("showLightBox", {data: {data: valor}});
          } catch (error)
          {
            console.error("Erro ao ler o arquivo:", error);
          }
        }
        return;
      }

      try
      {
        const response = await api.get(
            `/api/chamados/ocorrencias/arquivo/${dados.detail.id_empresa}/${dados.detail.path}/${this.modelValue}`
        );

        window.open(response.data.data);
        // this.$emit("showLightBox", response);
      } catch (e)
      {
        console.log(
            "%c Erro ao buscar clientes: ",
            "color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);",
            e
        );
      }
    },

    async downloadArquivo(dados) {
      try {
        let arquivo = event.detail.path;
        let session_user = JSON.parse(localStorage.getItem('logged_user'));

        const response = await api.get(`api/arquivos/empresa_${session_user.empresa_id}/${arquivo}/${this.modelValue}`, {
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(response.data);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        const fileName = response.headers['content-disposition']
            ? response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
            : arquivo ;

        a.download = fileName;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

      } catch (error) {
        console.error('Erro ao criar e adicionar o arquivo ao input:', error);
      }
    }
,

    /**
     * Abre uma nova janela ou aba do navegador com a URL da página 'atendimento', passando o valor de 'phone' do formulário como um parâmetro de consulta.
     */
    abreConversa()
    {
      const phone = this.form.phone.split("-")[0];
      window.open(
          window.location.href + "atendimento/?phone=" + phone,
          "_blank"
      );
    },

    editCliente()
    {
      const clientId = this.form.phone;
      this.$emit("editCliente", clientId);
    },
  },

  /**
   * Realiza tarefas de configuração quando o componente é montado, incluindo:
   * - Busca os dados para o chamado e configura a funcionalidade do dropdown
   * - Obtém a lista de setores e clientes
   * - Configura a funcionalidade do select2 para o dropdown "cadAtendimento"
   * - Alterna a visibilidade do elemento "AbreConversa" com base no valor de `modelValue`
   * - Adiciona um ouvinte de evento para o evento "visualizar-arquivo" para tratar a visualização de arquivos
   */
  mounted()
  {
    this.getSetores();
    this.getClientes();

    if (this.messageSelected != "")
      this.form.detalhesSolicitacao = this.messageSelected?.text_msg;

    $(".select2-cadAtendimento").each(function ()
    {
      $(this).select2({
        dropdownParent: $(this).parent(),
      });
    });

    if (this.modelValue == null)
    {
      $("#AbreConversa").fadeOut();
    }
    else
    {
      $("#AbreConversa").fadeIn();
    }

    window.addEventListener("visualizar-arquivo", this.getLinkArquivo);
    window.addEventListener("download-arquivo", this.downloadArquivo);
  },
  beforeUnmount()
  {
    window.removeEventListener('visualizar-arquivo', this.getLinkArquivo);
    window.removeEventListener('download-arquivo', this.downloadArquivo);
  },
};
</script>
<style>
.ck-editor__editable {
  min-height: 100px;
}

hr {
  border-bottom: 1.5px solid #eee;
}
</style>
