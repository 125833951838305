<template>

  <!-- inbox msgs -->
  <div class="d-flex flex-column flex-grow-1 bg-white">
    <!-- inbox header -->
    <div class="d-flex align-items-center p-0 border-faded border-top-0 border-left-0 border-right-0 flex-shrink-0">
      <div class="d-flex align-items-center justify-content-between w-100 pl-3 px-lg-4 py-2 position-relative">
        <!-- BEGIN INFO CONTACT -->
        <div class="d-flex flex-row align-items-center mt-1 mb-1 cursor-pointer" @click="editContato">
          <div class="mr-2 d-inline-block">
            <span class="rounded-circle profile-image d-block" :style="imageProfileContact(contact?.profileThumbnail ? contact?.profileThumbnail : contact?.contato?.foto)"></span>
          </div>
          <div class="info-card-text">
            <div class="fs-lg text-truncate text-truncate-lg contact-name">
              {{ contact?.contato?.nome ? contact?.contato?.nome : (contact?.name ?? "Cliente não identificado") }}
          </div>
            <span class="text-truncate text-truncate-md opacity-80">
              {{ formatPhoneNumber(contact.phone) }}
            </span>
          </div>
        </div>
        <!-- END INFO CONTACT -->

        <div class="d-flex flex-row align-items-center">
          <!-- BEGIN SEARCH -->
          <div class="input-group input-group-sm bg-white shadow-inset-2">
            <input type="text" class="form-control bg-transparent pr-0"
              placeholder="Buscar na conversa..." v-model="searchMessagesQuery" @input="searchMessages" @keydown.enter="nextMessage">
            <div class="input-group-append">
              <button class="btn btn-outline-default waves-effect waves-themed" type="button" @click="previousMessage"
                title="Mensagem Anterior" data-toggle="tooltip" data-original-title="Mensagem Anterior"
                data-placement="bottom">
                <i class="fal fa-angle-left"></i>
              </button>
              <button class="btn btn-outline-default waves-effect waves-themed" type="button" @click="nextMessage"
                title="Próxima Mensagem" data-toggle="tooltip" data-original-title="Próxima Mensagem"
                data-placement="bottom">
                <i class="fal fa-angle-right"></i>
              </button>

            </div>
          </div>
          <!-- END SEARCH -->

          


          <!-- BEGIN ACTIONS BUTTON -->
          <div class="btn-group ml-2" role="group">
            <button type="button" class="btn btn-sm btn-outline-default waves-effect waves-themed" @click="editContato" data-toggle="Editar contato" data-original-title="Editar contato" title="Editar contato" :disabled="showFormChamado || !(contact?.contato?.id || clienteId)">Editar
            </button>
            <!-- <button type="button" class="btn btn-sm btn-outline-default dropdown-toggle waves-effect waves-themed"
              data-toggle="dropdown" aria-expanded="false">Ações</button>
            <div class="dropdown-menu" style="">
              <a class="dropdown-item" href="javascript:void(0)"
              @click="editContato">
                Editar contato
              </a>
              <a class="dropdown-item" href="javascript:void(0)">Ação 02</a>
            </div> -->
          </div>
          <!-- END ACTIONS BUTTON -->

        </div>



      </div>
      <!-- button for mobile -->
      <a href="javascript:void(0);"
        class="px-3 py-2 d-flex d-lg-none align-items-center justify-content-center mr-2 btn waves-effect waves-themed"
        data-action="toggle" data-class="slide-on-mobile-left-show" data-target="#js-chat-contact">
        <i class="fal fa-ellipsis-v h1 mb-0 "></i>
      </a>
      <!-- end button for mobile -->
    </div>
    <!-- end inbox header -->

    <!-- inbox message -->
    <div class="flex-wrap align-items-center flex-grow-1 position-relative bg-gray-50">
      <div class="position-absolute pos-top pos-bottom w-100 overflow-hidden">
        <div class="d-flex h-100 flex-row">
          <!-- message list (the part that scrolls) -->
          <!-- BEGIN Atendimentos List -->

          <!-- BEGIN msgr -->
          <div class="msgr d-flex h-100 flex-column bg-white w-100">

              
            <Loading v-if="loading" />

            <!-- BEGIN custom-scroll -->
            <div class="custom-scroll flex-1 h-100" style="overflow-y:scroll;">
              <div id="chat_container" class="w-100 p-4" v-if="messages.length > 0">
                <!-- MENSAGENS -->
                <Mensagem 
                  v-for="mensagem in messages" 
                  :key="mensagem.id" 
                  :mensagem="mensagem"
                  @abrirAtendimento="abrirAtendimento"
                  @setArquivoMensagem="setArquivoMensagem"
                  :showSideModal="showSideModal"
                  :id="'message-' + mensagem.id" 
                />
                <!-- END MENSAGENS -->
              </div>

              <div v-else class="w-100 p-4">
                <div class="text-center">
                  <p class="text-muted">Nenhuma mensagem encontrada</p>
                </div>
              </div>
            </div>
            <!-- END custom-scroll  -->

            <!-- BEGIN CHAT INPUT -->
            <div class="panel-content bg-faded rounded-0">
              <form id="msgr_form" @submit.prevent="sendMessage">
                <textarea rows="2" class="form-control bg-transparent rounded-0" placeholder="Digite uma mensagem"
                id="msgr_input" style="resize: none;" @keypress="listenKeyCodes" v-model.trim="messageText"></textarea>
                
                <div class="d-flex align-items-center py-2 px-2 bg-transparent">
                  <a href="javascript:void(0);" class="btn btn-icon fs-xl mr-1 waves-effect waves-themed"
                    data-toggle="tooltip" data-original-title="Inserir Documento" data-placement="top"
                    @click="openExplorerToUpload('document')">
                    <i class="fal fa-file-upload color-fusion-300"></i>
                  </a>
                  <a href="javascript:void(0);" class="btn btn-icon fs-xl mr-1 waves-effect waves-themed"
                    data-toggle="tooltip" data-original-title="Inserir Foto" data-placement="top"
                    @click="openExplorerToUpload('image')">
                    <i class="fal fa-camera color-fusion-300"></i>
                  </a>
                  <a href="javascript:void(0);" class="btn btn-icon fs-xl mr-1 waves-effect waves-themed"
                    data-toggle="tooltip" data-original-title="Inserir Video" data-placement="top"
                    @click="openExplorerToUpload('video')">
                    <i class="fal fa-camera-movie color-fusion-300"></i>
                  </a>

                    <div class="anexo-file px-2 py-1 d-flex ml-2" v-if="file.length > 0">
                      <div class="icon-file">
                        <i class="fal fa-paperclip"></i>
                      </div>
                      <div class="p-1 text-truncate text-truncate-lg">
                        <span>{{ file[0].data.name }}</span>
                      </div>
                      <div class="">
                        <button class="close" type="button" @click="removeAnexoFile">
                            <i class="fal fa-times"></i>
                        </button>
                      </div>
                    </div>

                  <div class="custom-control custom-checkbox custom-control-inline ml-auto hidden-sm-down">
                    <input type="checkbox" class="custom-control-input" id="defaultInline1">
                    <!-- <label class="custom-control-label" for="defaultInline1">Pressione <strong>ENTER</strong> para enviar</label> -->
                  </div>
                  <button type="submit" class="btn btn-info btn-sm ml-auto ml-sm-0 waves-effect waves-themed">
                    Enviar
                  </button>
                </div>


                <!-- DEBUG DE ARQUIVOS NOS INPUTS -->
                <div class="d-none">
                  <div>
                    <p>DEBUG DE ARQUIVOS</p>
                  </div>
                  <div class="d-flex flex-row">
                    <div class="p-1">
                      <p>Arquivo de imagem:</p>
                      <input type="file" name="image" id="image" @change="setMediaFile"
                        accept="image/png, image/jpeg, image/gif">
                    </div>
                    <div class="p-1">
                      <p>Arquivo de video:</p>
                      <input type="file" name="video" id="video" @change="setMediaFile" accept="video/mp4">
                    </div>
                    <div class="p-1">
                      <p>Arquivo de documento:</p>
                      <input type="file" name="document" id="document" @change="setMediaFile"
                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                    </div>
                  </div>
                </div>
                <!-- END DEBUG -->

              </form>
            </div>
            <!-- END CHAT INPUT -->
          </div>
          <!-- END msgr -->

        </div>
      </div>
    </div>
    <!-- end inbox message -->
  </div>
  <!-- end inbox msgs -->


  <!-- box tickets -->
  <!-- <div class="d-flex flex-column bg-white border-faded border-left-1 border-right-0 border-bottom-0 border-top-0"
    style="width: 22rem;"> -->
  <div class="flex-wrap position-relative slide-on-mobile slide-on-mobile-right bg-white border-faded border-left-1 border-right-0 border-bottom-0 border-top-0"
    style="width: 22rem;">

    <div class="d-flex flex-column bg-white position-absolute pos-top pos-bottom w-100">
      <!-- inbox title -->
      <div class="">
        <div class="d-flex align-items-center p-0 border-faded border-top-0 border-left-0 border-right-0 flex-shrink-0">
          <div class="d-flex align-items-center justify-content-between w-100 pl-3 px-lg-4 py-2 position-relative">
            <div class="d-flex flex-row align-items-center mt-1 mb-1">
              <div class="mr-2 d-flex align-items-center" style="height: 3.125rem;">
                <h4 class="m-0">Atendimentos</h4>
              </div>
            </div>

            <div>
              <button type="button" class="btn btn-sm btn-info w-100 waves-effect waves-themed"
                @click="showForm('Chamado')" :disabled="showFormChamado || !(contact?.contato?.id || clienteId)">
                <i class="fal fa-1x fa-plus mr-1"></i>
                Novo Atendimento
              </button>
            </div>

          </div>
          <!-- button for mobile -->
          <a href="javascript:void(0);"
            class="px-3 py-2 d-flex d-lg-none align-items-center justify-content-center mr-2 btn waves-effect waves-themed"
            data-action="toggle" data-class="slide-on-mobile-right-show" data-target="#js-chat-tickets">
            <i class="fal fa-ellipsis-v h1 mb-0 "></i>
          </a>
          <!-- end button for mobile -->
        </div>
      </div>
      <!-- end inbox msgs -->

      <div class="custom-scroll flex-1 h-100">
        <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
          <!-- BEGIN CHAMADOS -->
          <ContactTickets :contact="contact" @edit="editAtendimento">
          </ContactTickets>
          <!-- END CHAMADOS -->

        </div>
      </div>


    </div>
    
  </div>
  <ModalSidePanel 
    :show="showFormChamado"
    :title="currentChamado ? 'Editar Atendimento' : `Novo Atendimento: ${contact?.contato?.nome ?? 'Cliente não identificado'}` " 
    @close="hideForm()"
    @save="submitChamado"
    :loading="loadingModalChamado"
  >
    <ChamadoForm
      ref="chamadoForm"
      :phone="contact?.phone + '-' + contact?.contato?.id"
      :modelValue="currentChamado"
      :submitChamado="submitChamado"
      :emAtendimento="true"
      :messageSelected="messageSelected"
      @saved="hideForm()"
      @salvoArquivo="salvoArquivo"
    />
  </ModalSidePanel>
  <!-- end box tickets -->

  <ModalCenteredLarge
    :show="showFormContato"
    :title="`Editar contato: ${contact?.contato?.nome ? contact?.contato?.nome : contact?.name}`"
    @close="hideForm()"
    @save="submitContato"
    :loading="loadingModalContato"
  >
    <ContactForm
      ref="contactForm"
      :modelValue="contact.contato"
      :clientId="contact.contato?.id || clienteId"
      :submitContato="submitContato"
      @updated="hideForm()"
    />
  </ModalCenteredLarge>

</template>


<script>
import Mensagem from './Mensagem.vue';
import ContactTickets from './ContactTickets.vue';
import Loading from '@/components/generals/LoadingDefault.vue';
import { api as api } from "@/axios.js";
import $ from 'jquery';
import ModalSidePanel from '../modals/ModalSidePanel.vue';
import ChamadoForm from '@/components/Home/Forms/ChamadoForm.vue';
import ModalCenteredLarge from '../modals/ModalCenteredLarge.vue';
import ContactForm from './forms/ClienteEditForm.vue';
import { doc, onSnapshot } from "firebase/firestore";
import db from '@/firebase/init.js';
import { farmatDataAtualParaMensagens } from '@/assets/js/main.js';

export default {
  name: "contact-chat-component",
  props: {
    contact: {
      type: Object,
      required: true
    },
  },
  components: {
    Mensagem,
    ContactTickets,
    Loading,
    ModalSidePanel,
    ChamadoForm,
    ModalCenteredLarge,
    ContactForm
  },
  computed: {
  },
  data() {
    return {
      messages: [],
      messageText: '',
      image: null,
      video: null,
      document: null,
      file: [],
      loading: true,
      searchMessagesQuery: '',
      currentMessageIndex: 0,
      searchMessagesResults: [],
      showFormChamado: false,
      currentChamado: null,
      showFormContato: false,
      processSnapshot: false,
      unsubscribe: null,
      messageSelected: null,
      loadingModalChamado: true,
      loadingModalContato: false,
      metaDataContato: null,
      clienteId: null,
      showSideModal: false,
    }
  },
  mounted() {
    this.getMessages();
    this.snapShotNewMsg();
    this.getClienteData();
    this.getMetaDataContato();
  },
  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  watch: {
    contact() {
      this.resetData();
      this.getMessages();
      this.getMetaDataContato();
      this.snapShotNewMsg();
      this.getClienteData();
    },
  },
  methods: {
    salvoArquivo(messages)
    {

      let mensagem = this.messages.find(msg => msg.id == messages.mensagem_id);
      console.log("enraksjfaklçdsfjk", messages, mensagem);

      if (mensagem)
      {
        if (!mensagem.ocorrencias)
        {
          mensagem.ocorrencias = [];
        }

        mensagem.ocorrencias.push({
          chamado_id: messages.chamado_id
        });
      }
    },
    /** 
     * Formata uma string de número de telefone adicionando parênteses ao redor do código de área e um hífen entre o prefixo e o número da linha.
     * @param {string} number - O número de telefone a ser formatado.
     * @returns {string} O número de telefone formatado.
     */
    formatPhoneNumber(number)
    {
      const cleaned = ('' + number).replace(/\D/g, '');

      const match = cleaned.match(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/);

      if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
      }

      return number;
    },


    imageProfileContact(imgUrl) {
      if (!imgUrl) {
        imgUrl = require("@/assets/img/user-default.svg");
      }
      return {
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
      };
    },


    getMessages() {
      this.messages = [];
      this.loading = true
      const phone = this.contact.phone;
      api.get(`/api/mensagem/all/${phone}`)
        .then(response => {
          this.messages = response.data;
          this.phone = phone;
          setTimeout(() => {
            if (this.messages.length > 0) {
              this.scrollToMessage(`message-${this.messages[this.messages.length - 1].id}`, false);
            }
            this.loading = false;
          }, 100);
        })
        .catch(error => {
          if (error.response) {
            // O servidor respondeu com um código de status fora do intervalo 2xx
            this.errorMessage = `Erro: ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            // A requisição foi feita, mas nenhuma resposta foi recebida
            this.errorMessage = 'Erro na conexão com o servidor.';
          } else {
            // Algo aconteceu na configuração da requisição que acionou um erro
            this.errorMessage = `Erro: ${error.message}`;
          }
        });
    },


    async sendMessage() {
      const message = this.messageText
      const phone = this.contact.phone;

      // Criando um id aleatório para buscar a menagem após o envio
      const random_id = Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000;
      // Setando os atributos necessárioa para serem utilizados no componente Mensagem
      let objMsg = {
        fromMe: true,
        created_at_format: farmatDataAtualParaMensagens(),
        id: random_id,
        awaiting: true
      };

      let formData = new FormData();
      formData.append('phone', phone)

      // Verificando se o arquivo foi selecionado
      if ((this.file.length > 0 && ['image', 'video', 'document'].includes(this.file[0].name)) || this.document) {
        this.file.forEach(element => {
          // Set o arquivo no tipo correspondente
          formData.append(element.name, element.data);
          // Set o nome do arquivo original para enviar na requisição
          formData.append('fileName', this.removeExtensionFile(element.data.name));
          // Set o arquivo no tipo correspondente do objeto temporario
          objMsg['type_msg'] = element.name;
          objMsg['url_media'] = URL.createObjectURL(element.data);
          objMsg[element.name] = element.data;
          objMsg['titleMedia'] = this.removeExtensionFile(element.data.name);
        });
        formData.append('caption', message);
        objMsg['caption'] = message;
      } else {
        formData.append('message', message);
        objMsg['text_msg'] = message;
      }

      // Adicionando a mensagem no array de mensagens
      await this.handleNewMsg(objMsg);
      this.scrollToMessage(`message-${random_id}`, false);

      // Enviando a mensagem
      if ((message || this.file) && phone) {
        api.postForm('/api/mensagem/send', formData)
          .then(response => {

            this.messageText = '';
            
            if (response.data?.success) {
              // Removendo o arquivo
              this.removeAnexoFile();
  
              // Buscando a mensagem que foi incluída no array de mensagens pelo Objeto temporário
              const index = this.messages.findIndex(msg => msg.id === random_id);
              if (index !== -1) {
                // Atualizando o status de aguardando para enviado
                this.messages[index].awaiting = false; 
                // Atualizando o ID da mensagem
                this.messages[index].messageId = JSON.parse(response.data.success).messageId;
              }
            }
          })
          .catch(error => {
            console.log(error);
          })
      } else {
        console.log('Mensagem e/ou Contato são inválidos!');
      }

    },


    setMediaFile(event) {
      this.file = [];
      const keyType = event.target.id;
      const type = event.target.files[0].type;
      const typesAvaliables = (keyType == 'image' || keyType == 'video') ? ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'] : (keyType == 'document') ? ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'] : [];

      if (!typesAvaliables.includes(type)) {
        return alert('Tipo de anexo inválido!');
      } else {
        let file = event.target.files[0];
        let keyType = event.target.id;
        let obj = {};
        obj.name = keyType;
        obj.data = file;
        this.file.push(obj);
      }
    },


    openExplorerToUpload(idType) {
      const el = document.getElementById(idType);
      el.click();
    },


    scrollToBottom() {
      $('.msgr > .custom-scroll').scrollTop($('#chat_container').innerHeight())
    },


    listenKeyCodes(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },


    // IMPLEMENTAÇÕES DE BUSCA DE MENSAGENS
    searchMessages() {
      this.searchMessagesResults = [];
      this.currentMessageIndex = 0;
      // REMOVENDO AS CLASSES DE MARCATEXTO
      $('.bg-marcatexto').removeClass('bg-marcatexto');

      if (!this.searchMessagesQuery) {
        this.scrollToBottom();
        return;
      }

      // PERCORRENDO POR TODAS AS MENSAGENS E VERIFICANDO SE A PALAVRA ESTÁ CONTIDA NA MENSAGEM. SE TIVER, ADICIONA O INDEX NA LISTA DE RESULTADOS
      this.messages.forEach((message) => {
        if (
          message.text_msg?.toLowerCase().includes(this.searchMessagesQuery.toLowerCase())
          ||
          message.caption?.toLowerCase().includes(this.searchMessagesQuery.toLowerCase())
        ) {
          this.searchMessagesResults.push(`message-${message.id}`);
        }
      });

      // SE POSSUI PELO MENOS 1 RESULTADO DE BUSCA, SCROLLA PARA O INDEX DO PRIMEIRO RESULTADO
      if (this.searchMessagesResults.length > 0) {
        this.scrollToMessage(this.searchMessagesResults[this.currentMessageIndex]);
      }

    },


    // SCROLLA PARA O INDEX DA MENSAGEM
    scrollToMessage(messageId, mark = true) {
      // REMOVENDO AS CLASSES DE MARCATEXTO
      $('.bg-marcatexto').removeClass('bg-marcatexto');
      // GET ELEMENTO PESQUISADO
      const messageElement = document.getElementById(messageId);
      // VERIFICA SE O ELEMENTO FOI ENCONTRADO
      if (messageElement) {
        // ADD CLASSE DE MARCATEXTO NA MENSAGEM EM EVIDENCIA CASO MARK FOR TRUE
        if (mark) {
          $(`#${messageId} > .chat-message`).addClass('bg-marcatexto')
        }
        // SCROLLA PARA O ELEMENTO
        messageElement.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    },


    // VAI PARA A PRÓXIMA MENSAGEM DOS RESULTADOS
    nextMessage() {
      if (this.searchMessagesResults.length > 0) {
        this.currentMessageIndex = (this.currentMessageIndex + 1) % this.searchMessagesResults.length;
        this.scrollToMessage(this.searchMessagesResults[this.currentMessageIndex]);
      }
    },
    // VAI PARA A MENSAGEM ANTERIOR DOS RESULTADOS


    previousMessage() {
      if (this.currentMessageIndex > 0) {
        this.currentMessageIndex = (this.currentMessageIndex - 1 + this.searchMessagesResults.length) % this.searchMessagesResults.length;
        this.scrollToMessage(this.searchMessagesResults[this.currentMessageIndex]);
      }
    },
    // FIM IMPLEMENTAÇÕES DE BUSCA DE MENSAGENS
    // BEGIN ATENDIMENTO


    showForm(resource) {
      this[`showForm${resource}`] = true;
      this[`loadingModal${resource}`] = false;
      this.showSideModal = true;
    },


    hideForm() {
      this.showFormChamado = false;
      this.currentChamado = null;
      this.showFormContato = false;
      this.loadingModalContato = true;
      this.loadingModalChamado = true;
      this.showFormCliente = false;
      this.showSideModal = false;
      this.messageSelected = null;
    },


    submitChamado() {
      const chamadoForm = this.$refs.chamadoForm;
      if (chamadoForm && typeof chamadoForm.submitForm === 'function') {
        chamadoForm.submitForm().then(response => {
          if(response) {
            this.hideForm()
          }
        })
      } else {
        console.error("submitForm não está disponível no chamadoForm");
      }
    },

    // END ATENDIMENTO
    // BEGIN CLIENTE
    /**
     * Edita uma solicitação de serviço (chamado).
     * A propriedade `currentChamado` é definida como o objeto `chamado` fornecido,
     * e a propriedade `showFormChamado` é definida como `true`.
     * @param {Object} chamado - O objeto de solicitação de serviço (chamado) a ser editado.
     */
     editAtendimento(chamado) {
      this.currentChamado = chamado;
      this.showFormChamado = true;

      this.$nextTick(() => {
        this.$refs.chamadoForm.fetchChamadoData().then(_ => {
          $('.dropdown-toggle').dropdown();
          this.loadingModalChamado = false;
          this.showSideModal = true;
        });
      });
    },


    editContato() {
      this.showFormContato = true;
      this.loadingModalContato = true;
      const clienteId = this.contact?.contato?.id || this.clienteId; 

      this.$nextTick(() =>
      {
        this.$refs.contactForm.getCliente(clienteId).then(() =>
        {
          this.loadingModalContato = false;
        })
      });
    },
    submitContato() {
      const contactForm = this.$refs.contactForm;

      if (contactForm && typeof contactForm.submitForm === 'function') {
        contactForm.submitForm().then(response => {
          if (response) {
            this.hideForm()
          }
        })
      } else {
        console.error("submitForm não está disponível no contactForm");
      }
    },
    // END CLIENTE
    // BEGIN FIREBASE REALTIME DATABASE

    async snapShotNewMsg() {
      
      // VERIFICA SE HÁ DOCUMENTO FIREBASE JÁ SENDO UTILIZADO E AGUARDANDO ALTERAÇÕES
      if(this.unsubscribe) {
        // REMOVE O OBSERVADOR
        this.unsubscribe();
      }
      
      // ARMAZENANDO O OBSERVADOR PARA USAR O unsubscribe() DO FIREBASE DEPOIS
      this.unsubscribe = onSnapshot(doc(db, "NOVA_MENSAGEM", `${this.contact.phone}_${this.contact.empresa_id}`), (doc) => {

        // IMPEDINDO O PROCESSAMNTO DA PRIMEIRA CHAMADA, QUE É QUANDO O COMPONENTE É CRIADO
        if (!this.processSnapshot) {
          this.processSnapshot = true
          return;
        }

        const data = doc.data()
        const messageExists = this.messages.some(message => message.messageId === data?.ID_MENSAGEM)
  
        if (!messageExists) {
          this.getMessageUnique(data?.ID_MENSAGEM)
        }
      }, (error) => {
        console.log(error)
      })
    },


    getMessageUnique(id) {
      api.get(`/api/mensagem/${id}`).then(async(response)=> {
        const idMsg = response.data.id;
        await this.handleNewMsg(response.data)
        this.scrollToMessage(`message-${idMsg}`, false)
      }).catch(error => {
        console.log(error)
      })
    },
    async handleNewMsg(message) {
      this.messages.push(message)
    },
    removeExtensionFile(filename) {
      return filename.substring(0, filename.lastIndexOf('.'));
    },


    removeAnexoFile() {
      let inputs = $('input[type="file"]');
      inputs.val('');
      this.file = [];
    },
    resetData() {
      this.messages = [];
      this.image = null;
      this.video = null;
      this.document = null;
      this.searchMessagesQuery = '';
      this.currentMessageIndex = 0;
      this.searchMessagesResults = [];
      this.currentChamado = null;
      this.showFormChamado = false;
      this.showFormContato = false;
      this.processSnapshot = false;
      this.removeAnexoFile(); 
      this.clienteId = null;
    },
    abrirAtendimento(value) {
      this.messageSelected = value
      this.loadingModalChamado = false;
      this.showForm('Chamado')
    },
    getMetaDataContato() {
      api.get(`/api/metadata/${this.contact.phone}`).then(response => {
        this.metaDataContato = response.data.data
        if (response.data.success) {
          this.$emit('setMetaDataContato', this.metaDataContato)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getClienteData(){
      api.get(`/api/clientes/phone/${this.contact.phone}`).then(response => {
        this.clienteId = response.data.id || null
      }).catch(error => {
        console.log(error)
      })
    },

    setArquivoMensagem(arquivo, nome, id)
    {
      this.$nextTick(_ =>
      {
        console.log(this.$refs.chamadoForm)
        this.$refs.chamadoForm.handleAddFile(arquivo, nome, id);
      })
    }

  },

}

</script>


<style scoped>
.anexo-file {
  color: rgba(0, 0, 0, 0.8);
  background-color: #dfdfdf;
  border-radius: 0.4rem;
  font-size: 0.95rem;
  font-weight:300;
  align-items: center;
  gap: 4px;
}
.anexo-file .icon-file {
  font-size: 1.1rem;
}
.anexo-file .close i {
  font-size: 1rem;
}

.info-card-text .contact-name{
  color: var(--theme-primary);
}
.info-card-text .contact-name:hover{
  color: var(--theme-primary-200);
}
</style>