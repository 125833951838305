<template>
  <header class="page-header" role="banner">
    <!-- we need this logo when user switches to nav-function-top -->
    <div class="page-logo">
      <router-link
        to="/"
        title="Home"
        class="page-logo-link press-scale-down d-flex align-items-center position-relative active"
        data-toggle="modal"
        data-target="#modal-shortcut"
      >
        <img
          :src="srcLogo"
          alt="Logo Adriana Leal"
          aria-roledescription="logo"
          class="img-fluid"
        />
      </router-link>
    </div>
    <!-- DOC: nav menu layout change shortcut -->
    <div class="hidden-md-down dropdown-icon-menu position-relative">
      <a
        href="javascript:void(0)"
        class="header-btn btn js-waves-off"
        data-action="toggle"
        data-class="nav-function-hidden"
        title="Hide Navigation"
      >
        <i class="ni ni-menu"></i>
      </a>
      <ul>
        <li>
          <a
            href="javascript:void(0)"
            class="btn js-waves-off"
            data-action="toggle"
            data-class="nav-function-minify"
            title="Minify Navigation"
          >
            <i class="ni ni-minify-nav"></i>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            class="btn js-waves-off"
            data-action="toggle"
            data-class="nav-function-fixed"
            title="Lock Navigation"
          >
            <i class="ni ni-lock-nav"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- DOC: mobile button appears during mobile width -->
    <div class="hidden-lg-up">
      <a
        href="javascript:void(0)"
        class="header-btn btn press-scale-down waves-effect waves-themed"
        data-action="toggle"
        data-class="mobile-nav-on"
      >
        <i class="ni ni-menu"></i>
      </a>
    </div>

    <div class="ml-auto d-flex align-items-center">
      <!-- activate app search icon (mobile) -->
      <div class="hidden-sm-up">
        <a
          href="javascript:void(0)"
          class="header-icon"
          data-action="toggle"
          data-class="mobile-search-on"
          data-focus="search-field"
          title="Search"
        >
          <i class="fal fa-search"></i>
        </a>
      </div>
      <!-- app settings -->

      <div class="theme-switcher">
        <input
          type="checkbox"
          id="theme-switcher"
          class="theme-switcher-input"
          @change="toggleTheme"
        />
        <label :class="['theme-switcher-label', theme]" for="theme-switcher">
          <i class="fal fa-sun"></i>
          <span class="theme-switcher-toggler" :class="theme"></span>
          <i class="fal fa-horse-head"></i>
          <i class="fal fa-moon"></i>
        </label>
        
      </div>

      <!-- logout -->
      <div :class="['navigation', theme]">
        <a class="logout-button" :class="theme" type="button" @click="logout">
          <i class="fal fa-sign-out-alt"></i>
          <div class="logout-text" :class="theme">SAIR</div>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      logo: "",
      srcLogo: "",
      theme: "default", // Tema inicial
    };
  },
  mounted() {
    this.initializeTheme();
    this.updateTogglerPosition(this.theme); // Atualiza a posição do toggler
    this.mode(this.theme);
  },
  methods: {
    mode(mode) {
      if (mode === "default") {
        this.logo = require("@/assets/img/logo-horizontal-branca.svg");
        this.srcLogo = this.logo;
      } else if (mode === "dark") {
        this.logo = require("@/assets/img/logo-horizontal-branca.svg");
        this.srcLogo = this.logo;
      } else if (mode === "light") {
        this.logo = require("@/assets/img/logo-horizontal.svg");
        this.srcLogo = this.logo;
      }
      this.updateTogglerPosition(mode); // Atualiza a posição do toggler ao mudar o tema
    },
    toggleTheme() {
      if (this.theme === "default") {
        this.theme = "dark";
      } else if (this.theme === "dark") {
        this.theme = "light";
      } else {
        this.theme = "default";
      }
      this.mode(this.theme);
      layouts.mode(this.theme);
    },
    updateTogglerPosition(theme) {
      const toggler = document.querySelector(".theme-switcher-toggler");
      toggler.classList.remove("light", "default", "dark");
      toggler.classList.add(theme);
    },
    logout() {
      this.$router.push("/login");
    },
    initializeTheme() {
      let themeClassList = document.getElementsByTagName("body")[0].classList;
      if (themeClassList.contains("mod-skin-dark")) {
        this.theme = "dark";
      } else if (themeClassList.contains("mod-skin-light")) {
        this.theme = "light";
      } else {
        this.theme = "default";
      }
      this.mode(this.theme);
    },
  },
};
</script>

<style scoped>
/* Estilo Geral */
.page-header .btn-logout {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  color: #ffffff;
}

.theme-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.theme-switcher-input {
  opacity: 0;
  position: absolute;
}

.theme-switcher-label {
  cursor: pointer;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 5px;
  height: 30px;
  border-radius: 20px;
  position: relative;
  transition: background-color 0.3s ease;
}

.theme-switcher-toggler {
  background-color: #fff;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  transition: left 0.4s ease, transform 0.4s ease;
}

.theme-switcher-label i {
  font-size: 18px;
}

.logout-button {
  cursor: pointer;
  width: 40px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: width 0.35s ease, background-color 0.3s ease;
  overflow: hidden;
  margin-bottom: 3px;
}

.logout-button i {
  font-size: 18px;
  transition: color 0.3s ease;
}

.logout-button .logout-text {
  font-size: 0.8em;
  letter-spacing: 3px;
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.35s ease, color 0.3s ease;
  margin-left: 10px;
}

.logout-button:hover {
  width: 100px;
}

.logout-button:hover .logout-text {
  opacity: 1;
}

/* Tema Light */
.theme-switcher-label.light {
  background-color: #822530;
}


.theme-switcher-label.light i {
  color: #ffffff;
}

.theme-switcher-toggler.light {
  left: 4px;
}

.theme-switcher-label.light {
  background-color: #822530;
  color: #ffffff;
}

/* Tema Default */
.theme-switcher-label.default {
  background-color: #ffffff;
}

.theme-switcher-label.default i {
  color: #822530;
}

.theme-switcher-toggler.default {
  background-color: #822530;
  left: 37px;
}

.theme-switcher-label.default {
  background-color: #ffffff;
  color: #822530;
}


/* Tema Dark */
.theme-switcher-label.dark {
  background-color: #ffffff;
}

.theme-switcher-label.dark i {
  color: #212225;
}

.theme-switcher-toggler.dark {
  background-color: #212225;
  left: 73px;
}

.theme-switcher-label.dark{
  background-color: #ffffff;
  color: #212225;
}

/* Hover Effects */
.page-header .btn-logout:hover {
  background-color: #ffffff;
  color: rgb(73, 73, 73) !important;
}

.mod-skin-light .page-header .btn-logout:hover {
  background-color: var(--theme-secondary);
  color: #ffffff !important;
}

.theme-switcher-label:hover > .theme-switcher-toggler {
  transform: rotate(360deg);
}

.logout-button:hover::after {
  opacity: 1;
}

/* Ajustes no Ícone do Botão de Sair */
.logout-button i {
  padding-left: 45px;
  display: flex;
  align-items: center;
  justify-content: center; 
  font-size: 18px;
  transition: color 0.3s ease;

}

.logout-button:hover i {
  padding-left: 0px;

}


/* Logout Button Themes  */
/* Light */
.logout-button.light i  {
  color: #fff
}

.logout-text.light { 
  color: #fff;
}

.logout-button.light {
  background-color: #822530;
}

/* Default */

.logout-button.default i  {
  color: #822530
}

.logout-text.default { 
  color: #822530;
}

.logout-button.default {
  background-color: #fff;
}


/* Dark */
.logout-button.dark {
background-color: #fff;
}

.logout-button.dark i  {
  color: #212225;
}

.logout-text.dark { 
  color: #212225;
}


</style>
