<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-content-wrapper">
        <main id="js-page-content" role="main" class="page-content">
          <div class="d-flex flex-grow-1 p-0">
            <div
                class="flex-wrap flex-shrink-0 position-relative slide-on-mobile slide-on-mobile-left bg-body p-3 bg-white mr-2"
                id="js-slide-left">
              <div class="row fullsize-table" style="overflow-y: auto; overflow-x: hidden">
                <!--                <div class="col-12 bg-body stickTop">-->
                <!--                  <div class="col-12 p-0">-->
                <!--                    <button class="btn btn-info w-100" id="clientes-new" @click="showFormCliente = true"><i-->
                <!--                        class="fal fa-1x fa-users"></i> Novo Cliente-->
                <!--                    </button>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="col-12 mt-3">
                  <form @submit.prevent="handleSubmit" id="filterForm" class="row">
                    <div class="col-12 mt-2">
                      <label class="form-label" for="clientesSelect">
                        Cliente
                      </label>
                      <select class="select2 form-control w-100" id="clientesSelect" v-model="form.cliente"
                              v-select="form.cliente">
                        <option value="0">Selecione o Cliente</option>
                        <option v-for="cliente in filter.cliente" :key="cliente.id" :value="cliente.ID">{{
                            cliente.NOME
                          }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
                <div class="col-12 stickBottom bg-body mt-2">
                  <div class="row mt-2">
                    <div class="col-8">
                      <button class="btn btn-success mr-1 mt-2 w-100 waves-effect waves-themed d-block" type="submit"
                              form="filterForm" id="btn-filter"><i class="fal fa-search mr-1 mt-1"
                                                                   @click="handleSubmit"></i>
                        Pesquisar
                      </button>
                    </div>
                    <div class="col-4">
                      <button class="btn btn-light mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                              data-toggle="tooltip" title="" data-placement="top" type="button" id="btn-clear-filter"
                              data-original-title="Limpar Filtro" @click="clearFilter"><i class="fal fa-times mt-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="slide-backdrop" data-action="toggle" data-class="slide-on-mobile-left-show"
                   data-target="#js-slide-left"></div>
            </div>
            <div class="d-flex flex-column flex-grow-1 overflow-hidden bg-white">
              <div class="row h-100">
                <div class="col-12">
                  <div class="panel h-100">
                    <div class="panel-hdr">
                      <h2>
                        <i class="subheader-icon fal fa-users"></i> <span class="fw-300">Relatório de Atendimentos - Cliente {{
                          stringCliente
                        }}</span>
                      </h2>
                    </div>
                    <div class="panel-container">
                      <div class="panel-content p-0 dataTables_wrapper row m-2" id="cardsDados">

                        <div class="col-6 p-1 mb-1">

                          <div class="card h-100">
                            <div class="card-body">
                              <h5 class="card-title">Atendimentos em Aberto</h5>
                              <div class="dt-card-body">
                                <DataTable v-if="!loading"
                                           class="table table-bordered table-hover table-striped w-100 mb-0"
                                           :options="optionsDatatable" :columns="columns"
                                           :data="DataTable.dataAtendimentosAberto">
                                  <thead>
                                  <tr>
                                    <th style="white-space: nowrap" class="text-center">*</th>
                                    <th style="white-space: nowrap" class="text-center">Cliente</th>
                                    <th style="white-space: nowrap" class="text-center">Setor</th>
                                    <th style="white-space: nowrap" class="text-center">Data Abertura</th>
                                    <th style="white-space: nowrap" class="text-center">Dias em Aberto</th>
                                  </tr>
                                  </thead>
                                  <tbody style="text-align:center">
                                  </tbody>
                                </DataTable>
                                <Loading v-else/>
                              </div>
                            </div>
                          </div>


                        </div>

                        <div class="col-6 p-1 mb-1">

                          <div class="card h-100">
                            <div class="card-body">
                              <h5 class="card-title">Atendimentos em Andamento</h5>
                              <div class="dt-card-body">
                                <DataTable v-if="!loading"
                                           class="table table-bordered table-hover table-striped w-100 mb-0"
                                           :options="optionsDatatable" :columns="columns"
                                           :data="DataTable.dataAtendimentosAndamento">
                                  <thead>
                                  <tr>
                                    <th style="white-space: nowrap" class="text-center">*</th>
                                    <th style="white-space: nowrap" class="text-center">Cliente</th>
                                    <th style="white-space: nowrap" class="text-center">Setor</th>
                                    <th style="white-space: nowrap" class="text-center">Data Abertura</th>
                                    <th style="white-space: nowrap" class="text-center">Dias em Aberto</th>
                                  </tr>
                                  </thead>
                                  <tbody style="text-align:center">
                                  </tbody>
                                </DataTable>
                                <Loading v-else/>
                              </div>
                            </div>
                          </div>


                        </div>

                        <div class="col-6 p-1 mb-1">

                          <div class="card h-100">
                            <div class="card-body">
                              <h5 class="card-title">Atendimentos Encerrados</h5>
                              <div class="dt-card-body">
                                <DataTable v-if="!loading"
                                           class="table table-bordered table-hover table-striped w-100 mb-0"
                                           :options="optionsDatatable" :columns="columnsFechamento"
                                           :data="DataTable.dataAtendimentosFechado">
                                  <thead>
                                  <tr>
                                    <th style="white-space: nowrap" class="text-center">*</th>
                                    <th style="white-space: nowrap" class="text-center">Cliente</th>
                                    <th style="white-space: nowrap" class="text-center">Setor</th>
                                    <th style="white-space: nowrap" class="text-center">Data Abertura</th>
                                    <th style="white-space: nowrap" class="text-center">Dias em Aberto</th>
                                    <th style="white-space: nowrap" class="text-center">Data Fechamento</th>
                                  </tr>
                                  </thead>
                                  <tbody style="text-align:center">
                                  </tbody>
                                </DataTable>
                                <Loading v-else/>
                              </div>
                            </div>
                          </div>


                        </div>


                        <div class="col-6 p-1 mb-1">
                          <div class="card">
                            <div class="card-body">
                              <h5 class="card-title">Gráfico de Atendimento do Cliente</h5>
                              <div style="height: 264px;">
                                <Pie v-if="!loading" :data="data" :options="options"/>
                                <Loading v-else/>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'
import Loading from '@/components/generals/LoadingDefault.vue'
import $ from 'jquery'
import { DataTable } from 'datatables.net-vue3'
import { api } from '@/axios'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  components: {
    Pie,
    Loading,
    DataTable
  },
  /**
   * The data properties for the AtendimentoPorCliente component.
   *
   * @property {Object} filter - The filter object for the component.
   * @property {Object[]} data - The data for the pie chart, including labels and datasets.
   * @property {Object} options - The options for the pie chart.
   * @property {Object} DataTable - The data for the data tables, including data for open, in-progress, and closed tickets.
   * @property {Object} optionsDatatable - The options for the data tables.
   * @property {Object[]} columns - The columns for the data tables.
   * @property {Object} form - The form data for the component, including the selected client.
   * @property {boolean} loading - A flag indicating whether the component is currently loading data.
   */
  data () {
    return {
      stringCliente: '',
      filter: {
        cliente: []
      },
      data: {
        labels: ['Abertos', 'Em Andamento', 'Encerrados'],
        datasets: [
          {
            backgroundColor: ['#8d1719', '#ffc241', '#1dc9b7'],
            data: [0, 0, 0]
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      DataTable: {
        dataAtendimentosAberto: [],
        dataAtendimentosAndamento: [],
        dataAtendimentosFechado: [],
      },
      optionsDatatable: {
        paging: false,
        pageLength: -1,
        dom: 't',
        scrollY: 220,
        language: {
          'emptyTable': 'Nenhum Resultado Encontrado',
          'zeroRecords': 'Nenhum Resultado Encontrado',
          'decimal': ',',
          'info': 'Mostrando _START_ a _END_ de _TOTAL_ resultados',
          'infoEmpty': 'Nenhum Resultado Encontrado',
          'thousands': '.',
          'loadingRecords': 'Carregando...',
          'processing': '',
          'search': '',
          'searchPlaceholder': 'Pesquisar',
          'aria': {
            'orderable': 'Ordenar por essa coluna',
            'orderableReverse': 'Ordem inversa desta coluna'
          }
        }
      },
      columns: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + 1
          }
        },
        {
          data: 'NOME',
        },
        {
          data: 'SETOR',
        },
        {
          data: 'DATA_CRIACAO',
        },
        {
          data: 'DIAS_ABERTO',
        }
      ],
      columnsFechamento: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + 1
          }
        },
        {
          data: 'NOME',
        },
        {
          data: 'SETOR',
        },
        {
          data: 'DATA_CRIACAO',
        },
        {
          data: 'DIAS_ABERTO',
        },
        {
          data: 'DATA_FECHAMENTO',
        }
      ],
      form: {
        cliente: 0,
      },
      loading: true,
    }
  },
  methods: {
    /**
     * Limpa o filtro definindo a propriedade `cliente` do objeto `form` para 0.
     * Este método provavelmente é usado para redefinir o formulário e limpar qualquer cliente selecionado anteriormente.
     */
    clearFilter () {
      this.form.cliente = 0
      $('#cardsDados').fadeOut()
      this.stringCliente = ''
    },

    /**
     * Lida com a submissão do formulário, buscando os dados do relatório para o cliente selecionado.
     *
     * Este método é responsável por:
     * - Mostrar um indicador de carregamento enquanto os dados estão sendo buscados
     * - Chamar a API para recuperar os dados do relatório para o cliente selecionado
     * - Armazenar os dados buscados nas propriedades de dados do componente
     * - Atualizar a UI com os dados buscados
     * - Lidar com quaisquer erros que ocorram durante a busca dos dados
     *
     * @returns {Promise<void>} - Uma Promise que é resolvida quando os dados são buscados e armazenados.
     */
    async handleSubmit () {
      this.loading = true
      const cliente = this.form.cliente

      if (cliente == 0) return
      try {
        $('#cardsDados').fadeIn()

        const response = await api.get('/api/relatorio/chamadoscliente/' + cliente)
        const dados = response.data.data

        if (response.data.success) {
          console.log('%c✔ Requisição feita com sucesso! Os dados que irão preencher a tabela serão:', 'color: #148f32')
          console.table(response.data.data)
          console.log('Dados que voltaram da requisição:', response.data.data)
        }

        this.DataTable.dataAtendimentosAberto = dados?.atendimentos_abertos.data || []
        this.DataTable.dataAtendimentosAndamento = dados?.atendimentos_andamento.data || []
        this.DataTable.dataAtendimentosFechado = dados?.atendimentos_encerrados.data || []

        this.data.datasets[0].data = dados.totalizador
        this.loading = false
        this.stringCliente = $('#clientesSelect option:selected').text()
      } catch (error) {
        console.error('Erro ao buscar clientes:', error)
        this.loading = false
      }
    },

    /**
     * Busca a lista de clientes da API e atualiza a propriedade `optionsClientes.values` com os dados da resposta.
     *
     * Este método é usado para preencher as opções de seleção de clientes no formulário.
     *
     * @returns {Promise<void>} - Uma Promise que é resolvida quando os dados dos clientes foram buscados e armazenados.
     */
    async getClientes () {
      try {
        const response = await api.get(`/api/clientes`)
        if (response.data.success) {
          console.log('%c✔ Requisição feita com sucesso! Os dados que irão preencher o select serão:', 'color: #148f32')
          console.table(response.data.data)
          console.log('Dados que voltaram da requisição:', response.data.data)
          this.filter.cliente = response.data.data
        }
      } catch (e) {
        console.log('%c Erro ao buscar clientes: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e)
      }
    },
  },
  mounted () {
    this.loading = false
    $('.select2').select2()

    this.getClientes()

    $('#cardsDados').hide()
  }
}
</script>

<style scoped>
:deep(.dataTables_wrapper) {
  max-height: calc(100vh - 261px) !important;
  overflow: auto;
}

:deep(.fullsize-table) {
  max-height: calc(100vh - 261px);
}

.dt-card-body {
  height: 264px;
}
</style>