<template>
  <div class="login-container">
    <div class="blankpage-form-field">
      <div
          class="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
        <a href="javascript:void(0)" class="page-logo-link press-scale-down d-flex align-items-center">
          <img src="../../assets/img/logo-horizontal.svg" alt="Adriana Leal" aria-roledescription="logo"
               class="img-fluid">
        </a>
      </div>
      <div class="card p-4 border-top-left-radius-0 border-top-right-radius-0">
        <form @submit.prevent="login" class="login-form">
          <div class="form-group">
            <input type="email" v-model="email" id="username" class="form-control" placeholder="exemplo@exemplo.com">
            <span class="help-block">
                        Seu e-mail de acesso ao sistema
                    </span>
          </div>
          <div class="form-group">
            <input type="password" v-model="password" id="password" class="form-control" placeholder="senha"/>
            <span class="help-block">
                        Sua senha de acesso ao sistema
                    </span>
          </div>
          <button type="submit" class="btn btn-default float-right">Entrar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {api as api} from "@/axios.js";
import swal from 'sweetalert2';

api.defaults.withCredentials = true

export default {
  name: 'login-page',
  data()
  {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login()
    {
      try
      {
        const response = await api.post('/api/login', {
          email: this.email,
          password: this.password
        });

        let data = response.data;
        let usuario_data = data.data;

        if (data.success === false){
          console.log(data.message)
          return
        }
        if (usuario_data === null || usuario_data === undefined || usuario_data === ''){
          console.log('Falha ao carregar dados do usuário')
          return
        }

        let logginData = {
          id:   usuario_data.id,
          nome: usuario_data.nome,
          email_user:  usuario_data.email,
          empresa_id:  usuario_data.empresa_id,
          expires_in:  usuario_data.tokenResult,
          access_type: usuario_data.access_type,
        }

        localStorage.setItem('logged_user',JSON.stringify(logginData));
        localStorage.setItem('access_token',usuario_data.access_token);

        this.$store.commit('LOGIN') // Atualiza o state no store para logado
        this.$router.push({name: 'home'}) // Redirect para a rota "home" dentro do sistema

        this.$emit('login-success');

      } catch (error)
      {
        swal.fire({
          title: 'Ops!',
          text: "Credênciais inválidas",
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    }
  },
  mounted()
  {
    this.$emit('login-success');
    document.body.classList.add('login-page-body');
    document.documentElement.classList.add('login-page-html');
  },
  beforeUnmount()
  {
    document.body.classList.remove('login-page-body');
    document.documentElement.classList.remove('login-page-html');
  }
}

</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");

.page-content {
  color: #666666;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  font-weight: 400;
}

strong {
  font-weight: 500;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
  font-weight: 300;
  display: block;
  font-size: 0.9375rem;
  line-height: 1.5;
  margin: 2px 0 1.5rem;
}

h2 small,
h3 small,
.h2 small,
.h3 small {
  font-size: 0.9375rem;
}

h4 small,
.h4 small {
  font-size: 0.875rem;
}

h5 small,
h6 small,
.h5 small,
.h6 small {
  font-size: 0.8125rem;
}

/* contrast text */
.text-contrast {
  color: #333333;
}

/* text-gradient */
.text-gradient {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #6e4e9e), color-stop(50%, #62468d), color-stop(75%, #0c7cd5), to(#0960a5));
  background: linear-gradient(180deg, #6e4e9e 25%, #62468d 50%, #0c7cd5 75%, #0960a5 100%);
  color: var(--theme-primary);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

.page-logo, .page-sidebar, .nav-footer, .bg-brand-gradient {
  background-image: linear-gradient(270deg, rgba(var(--theme-primary), 0.18), transparent);
  background-color: #fff;
}

/*# sourceMappingURL=page-login-alt.css.map */
</style>