<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-content-wrapper">
        <main id="js-page-content" role="main" class="page-content">
          <div class="d-flex flex-grow-1 p-0">
            <div
                class="flex-wrap flex-shrink-0 position-relative slide-on-mobile slide-on-mobile-left bg-body p-3 bg-white mr-2"
                id="js-slide-left">
              <div class="row fullsize-table" style="overflow-y: auto; overflow-x: hidden">
                <!--                <div class="col-12 bg-body stickTop">-->
                <!--                  <div class="col-12 p-0">-->
                <!--                    <button class="btn btn-info w-100" id="clientes-new" @click="showFormCliente = true"><i-->
                <!--                        class="fal fa-1x fa-users"></i> Novo-->
                <!--                      setor-->
                <!--                    </button>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div class="row fullsize-table" style="overflow-y: auto; overflow-x: hidden">
                <div class="col-12 mt-3">
                  <form @submit.prevent="handleSubmit" id="filterForm" class="row">
                    <div class="col-12 mt-2">
                      <label for="filter-search" class="form-label">Setor</label>
                      <select class="select2 form-control" id="situacaoSetor" v-model="filter.setor"
                              v-select="filter.setor">
                        <option value="0">Selecione o Setor</option>
                        <option v-for="setor in select.setor" :value="setor.id" :key="setor.id">
                          {{setor.name }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
                <div class="col-12 stickBottom bg-body mt-2">
                  <div class="row mt-2">
                    <div class="col-8">
                      <button class="btn btn-success mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                              type="submit" form="filterForm" id="btn-filter"><i
                          class="fal fa-search mr-1 mt-1"></i>
                        Pesquisar
                      </button>
                    </div>
                    <div class="col-4">
                      <button class="btn btn-light mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                              data-toggle="tooltip" title="" data-placement="top" type="button" id="btn-clear-filter"
                              data-original-title="Limpar Filtro" @click="clearFilter"><i
                          class="fal fa-times mt-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="slide-backdrop" data-action="toggle" data-class="slide-on-mobile-left-show"
                   data-target="#js-slide-left"></div>
            </div>
            <div class="d-flex flex-column flex-grow-1 overflow-hidden bg-white">
              <div class="row h-100">
                <div class="col-12">
                  <div class="panel h-100">
                    <div class="panel-hdr">
                      <h2>
                        <i class="subheader-icon fal fa-users"></i> <span
                          class="fw-300">Relatório de Atendimentos - Setor {{stringSetor}}</span>
                      </h2>
                    </div>
                    <div class="panel-container show">

                      <div class="panel-content p-0 dataTables_wrapper row m-2" id="cardsDados">

                        <div class="col-6 p-1 mb-1">

                          <div class="card h-100">
                            <div class="card-body">
                              <h5 class="card-title">Atendimentos em Aberto</h5>
                              <div class="dt-card-body">
                                <DataTable v-if="!loading"
                                           class="table table-bordered table-hover table-striped w-100 mb-0"
                                           :options="optionsDatatables" :columns="columns" :data="DataTable.dataAtendimentosAberto">
                                  <thead>
                                  <tr>
                                    <th style="white-space: nowrap" class="text-center">*</th>
                                    <th style="white-space: nowrap" class="text-center">Cliente</th>
                                    <th style="white-space: nowrap" class="text-center">Data Abertura</th>
                                    <th style="white-space: nowrap" class="text-center">Dias em Aberto</th>
                                  </tr>
                                  </thead>
                                  <tbody style="text-align:center">
                                  </tbody>
                                </DataTable>
                                <Loading v-else/>
                              </div>
                            </div>
                          </div>


                        </div>

                        <div class="col-6 p-1 mb-1">

                          <div class="card h-100">
                            <div class="card-body">
                              <h5 class="card-title">Atendimentos em Andamento</h5>
                              <div class="dt-card-body">
                                <DataTable v-if="!loading"
                                           class="table table-bordered table-hover table-striped w-100 mb-0"
                                           :options="optionsDatatables" :data="DataTable.dataAtendimentosAndamento"
                                           :columns="columns">
                                  <thead>
                                  <tr>
                                    <th style="white-space: nowrap" class="text-center">*</th>
                                    <th style="white-space: nowrap" class="text-center">Cliente</th>
                                    <th style="white-space: nowrap" class="text-center">Data Abertura</th>
                                    <th style="white-space: nowrap" class="text-center">Dias em Aberto</th>
                                  </tr>
                                  </thead>
                                  <tbody style="text-align:center">
                                  </tbody>
                                </DataTable>
                                <Loading v-else/>
                              </div>
                            </div>
                          </div>


                        </div>

                        <div class="col-6 p-1 mb-1">

                          <div class="card h-100">
                            <div class="card-body">
                              <h5 class="card-title">Atendimentos Encerrados</h5>
                              <div class="dt-card-body">
                                <DataTable v-if="!loading"
                                           class="table table-bordered table-hover table-striped w-100 mb-0"
                                           :options="optionsDatatables" :columns="columnsFechamento" :data="DataTable.dataAtendimentosFechado">
                                  <thead>
                                  <tr>
                                    <th style="white-space: nowrap" class="text-center">*</th>
                                    <th style="white-space: nowrap" class="text-center">Cliente</th>
                                    <th style="white-space: nowrap" class="text-center">Data Abertura</th>
                                    <th style="white-space: nowrap" class="text-center">Dias em Aberto</th>
                                    <th style="white-space: nowrap" class="text-center">Data Fechamento</th>
                                  </tr>
                                  </thead>
                                  <tbody style="text-align:center">
                                  </tbody>
                                </DataTable>
                                <Loading v-else/>
                              </div>
                            </div>
                          </div>


                        </div>


                        <div class="col-6 p-1 mb-1">
                          <div class="card">
                            <div class="card-body">
                              <h5 class="card-title">Gráfico de Atendimento do Cliente</h5>
                              <div style="height: 264px;">
                                <Pie v-if="!loading" :data="dataGrafico" :options="options" />
                                <Loading v-else/>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
} from 'chart.js'
import {Pie} from 'vue-chartjs'
import Loading from "@/components/generals/LoadingDefault.vue";
import {api} from "@/axios";
import {DataTable} from "datatables.net-vue3";
import $ from "jquery";

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  name: 'App',
  components: {
    DataTable,
    Loading,
    Pie
  },
  data()
  {
    return {
      stringSetor: "",
      select: {
        setor: []
      },
      filter: {
        setor: "0"
      },
      loading: false,
      dataGrafico: {
        labels: ['Aberto', 'Em andamento', 'Fechados'],
        datasets: [
          {
            backgroundColor: ['#8d1719', '#ffc241', '#1dc9b7'],
            data: [25, 40, 35]
          }
        ],
      },
      options:  {
        responsive: true,
        maintainAspectRatio: false
      },
      columns: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + 1;
          }
        },
        {
          data: "NOME",
        },
        {
          data: "DATA_CRIACAO",
        },
        {
          data: "DIAS_ABERTO",
        }
      ],
      columnsFechamento: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + 1;
          }
        },
        {
          data: "NOME",
        },
        {
          data: "DATA_CRIACAO",
        },
        {
          data: "DIAS_ABERTO",
        },
        {
          data: "DATA_FECHAMENTO",
        }
      ],
      optionsDatatables: {
        scrollY: 220,
        paging: false,
        pageLength: -1,
        dom: 't',
        language: {
          "emptyTable": "Nenhum Resultado Encontrado",
          "zeroRecords": "Nenhum Resultado Encontrado",
          "decimal": ",",
          "info": "Mostrando _START_ a _END_ de _TOTAL_ resultados",
          "infoEmpty": "Nenhum Resultado Encontrado",
          "thousands": ".",
          "loadingRecords": "Carregando...",
          "processing": "",
          "search": "",
          "searchPlaceholder": "Pesquisar",
          "aria": {
            "orderable": "Ordenar por essa coluna",
            "orderableReverse": "Ordem inversa desta coluna"
          }
        }
      },
      DataTable: {
        dataAtendimentosAberto: [],
        dataAtendimentosAndamento: [],
        dataAtendimentosFechado: [],
      }
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      const setor = this.filter.setor;

      if(["", "0"].includes(setor)) return;

      try {
        $("#cardsDados").fadeIn();

        const response = await api.get('/api/relatorio/chamadossetor/' + setor);
        const dados = response.data.data;

        if (response.data.success) {
          console.log("%c✔ Requisição feita com sucesso! Os dados que irão preencher a tabela serão:", "color: #148f32");
          console.table(response.data.data);
          console.log('Dados que voltaram da requisição:', response.data.data);
        }

        this.stringSetor = $("#situacaoSetor option:selected").text()

        this.DataTable.dataAtendimentosAberto = dados?.atendimentos_abertos.data || [];
        this.DataTable.dataAtendimentosAndamento = dados?.atendimentos_andamento.data || [];
        this.DataTable.dataAtendimentosFechado = dados?.atendimentos_encerrados.data || [];

        this.dataGrafico.datasets[0].data = dados.totalizador
        this.loading = false;
      } catch (error) {
        console.error('Erro ao buscar clientes:', error);
        this.loading = false;
      }
    },


    /**
     * Limpa todos os valores de filtro no objeto `filter`.
     *
     * Este método itera sobre as chaves do objeto `filter` e define cada valor como uma string vazia.
     * Ele é tipicamente usado para redefinir o estado do filtro no componente.
     */
    clearFilter()
    {
      Object.keys(this.filter).forEach((valor, dado) =>
      {
        this.filter[valor] = "";
      })
      $("#cardsDados").fadeOut();
      this.stringSetor = ""
      this.filter.setor = "0"
    },


    /**
     * Busca a lista de setores da API e popula o array `filter.setor`.
     * Este método é chamado quando o componente é montado.
     *
     * @async
     * @returns {void}
     */
    async getsetoresSelect()
    {
      try
      {
        await api.get('/api/setores').then(response =>
        {
          response.data.data.forEach(element =>
          {
            this.select.setor.push({
              id: element.ID,
              name: element.NOME,
            });
          })
        });
      } catch (e)
      {
        console.log('%c Erro: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e);
      }
    },
  },
  mounted()
  {
    $('.select2').select2();
    $("#cardsDados").hide();

    this.getsetoresSelect();
  }
}
</script>
<style scoped>
:deep(.dataTables_wrapper) {
  max-height: calc(100vh - 261px) !important;
  overflow: auto;
}

:deep(.fullsize-table) {
  max-height: calc(100vh - 261px);
}

.dt-card-body {
  height: 264px;
}
</style>