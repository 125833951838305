<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-content-wrapper">
        <main id="js-page-content" role="main" class="page-content">
          <div class="d-flex flex-grow-1 p-0 border-faded shadow-4">
            <!-- left slider -->
            <div
              id="js-chat-contact"
              class="flex-wrap position-relative slide-on-mobile slide-on-mobile-left border-faded border-left-0 border-top-0 border-bottom-0"
            >
              <!-- BEGIN msgr-list -->
              <div
                v-if="!loading"
                class="d-flex flex-column bg-faded position-absolute pos-top pos-bottom w-100"
              >
                <div class="px-3 py-4">
                  <input
                      type="text"
                      class="form-control bg-white"
                      placeholder="Pesquisar Contato"
                      v-model="searchQuery"
                  />
                  <div class="mt-2" style="margin-left: 1px">
                    <input
                        type="checkbox"
                        id="filterChamadosAbertos"
                        v-model="filterChamadosAbertos"
                    />
                    <label class="ml-2 mb-1" for="filterChamadosAbertos"
                    >Atendimentos em aberto</label
                    >
                  </div>
                </div>
                <div class="flex-1 h-100 custom-scrollbar">
                  <div class="w-100">
                    <!-- <div class="nav-title m-0 px-3 text-muted">Conversas</div> -->
                    <ul
                      v-if="filteredContacts.length > 0"
                      class="list-unstyled m-0"
                    >
                      <ContactList
                        :contacts="filteredContacts"
                        @contactSelected="setContactChat"
                      />
                    </ul>
                    <div
                      v-else
                      class="p-3 text-muted d-flex justify-content-center flex-column align-items-center h-100"
                      style="gap: 10px"
                    >
                      <i class="fal fa-inbox fa-2x"></i>
                      <h4 class="text-center">Nenhum contato encontrado!</h4>
                    </div>
                  </div>
                </div>
              </div>
              <Loading v-else />

              <!-- END msgr-list -->
            </div>
            <div
              class="slide-backdrop"
              data-action="toggle"
              data-class="slide-on-mobile-left-show"
              data-target="#js-chat-contact"
            ></div>
            <!-- end left slider -->
            <!-- inbox container -->
            <div class="d-flex flex-row flex-grow-1 bg-white">
              <ContactChat
                v-if="contact"
                v-model="contact"
                :contact="contact"
                @setMetaDataContato="setMetaDataContato"
              />
              <div
                v-else
                class="p-3 text-muted d-flex justify-content-center flex-column align-items-center h-100 w-100"
                style="gap: 20px"
              >
                <i class="fal fa-inbox fa-4x"></i>
                <h3>Selecione um contato para começar o atendimento</h3>
              </div>
            </div>
            <!-- end inbox container -->
          </div>
        </main>
        <div
          class="page-content-overlay"
          data-action="toggle"
          data-class="mobile-nav-on"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactList from "./ContactList.vue";
import ContactChat from "./ContactChat.vue";
import { api as api } from "@/axios.js";
import Loading from "@/components/generals/LoadingDefault.vue";
import { doc, onSnapshot } from "firebase/firestore";
import db from "@/firebase/init";

export default {
  name: "chat-component",
  components: {
    ContactList,
    ContactChat,
    Loading,
  },
  computed: {
    filteredContacts() {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return this.contacts.filter((contact) => {
        const matchesSearchQuery =
            contact.name?.toLowerCase().includes(searchQueryLower) ||
            contact.phone?.toLowerCase().includes(searchQueryLower);

        const matchesChamadosAbertos =
            !this.filterChamadosAbertos || contact.contato?.chamados_abertos > 0;

        return matchesSearchQuery && matchesChamadosAbertos;
      });
    },
  },

  data() {
    return {
      messages: null,
      contacts: [],
      contact: null,
      breadcrumb: [
        {
          text: "Atendimento",
          href: "#",
          active: true,
        },
      ],
      searchQuery: "",
      loading: true,
      processSnapshot: false,
      filterChamadosAbertos: false,
    };
  },
  created() {
    this.getContacts();
    this.snapShotNewMsgContact();
  },
  methods: {
    getContacts() {
      api
        .get("/api/clientes/atendimento")
        .then((response) => {
          this.contacts = response.data.data;
          this.loading = false;

          // VERIFICA SE POSSUI UM PARAMETRO GET "phone" NA URI E ENTÃO SETA O CONTATO DESSE PARAMETRO NA LISTA DE CONTATOS
          if (this.$route.query.phone) {
            const contact = this.contacts.find(
              (contact) => contact.phone == this.$route.query.phone
            );
            if (contact) {
              this.setContactChat(contact);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            // O servidor respondeu com um código de status fora do intervalo 2xx
            this.errorMessage = `Erro: ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            // A requisição foi feita, mas nenhuma resposta foi recebida
            this.errorMessage = "Erro na conexão com o servidor.";
          } else {
            // Algo aconteceu na configuração da requisição que acionou um erro
            this.errorMessage = `Erro: ${error.message}`;
          }
        });
    },
    setContactChat(contact) {
      this.contact = contact;
    },
    async snapShotNewMsgContact() {
      let session_user = JSON.parse(localStorage.getItem("logged_user"));

      const document = `WEBHOOK_${session_user.empresa_id}`;
      onSnapshot(doc(db, "CONFIG", document), (doc) => {
        // IMPEDINDO O PROCESSAMNTO DA PRIMEIRA CHAMADA, QUE É QUANDO O COMPONENTE É CRIADO
        if (!this.processSnapshot) {
          this.processSnapshot = true;
          return;
        }
        const dataDoc = doc.data();
        const contact = {
          phone: dataDoc.PHONE_ULTIMO_DISPARO,
          name: dataDoc.NOME_CONTATO_ULTIMO_DISPARO,
          profileThumbnail: dataDoc.PHOTO_ULTIMO_DISPARO,
        };
        this.reordenarContatos(contact);
      });
    },
    reordenarContatos(contactFirebase) {
      let contatoExistente = false;

      for (let i = 0; i < this.contacts.length; i++) {
        if (this.contacts[i].phone == contactFirebase.phone) {
          const [contact] = this.contacts.splice(i, 1);
          this.contacts.unshift(contact);
          contatoExistente = true;
          break;
        }
      }

      if (!contatoExistente) {
        this.contacts.unshift(contactFirebase);
      }
    },

    setMetaDataContato(metaDataContato) {
      this.contacts.findIndex((contact) => {
        if (contact.phone === metaDataContato.phone) {
          contact.profileThumbnail = metaDataContato.profileThumbnail;
          contact.name = metaDataContato.name;
        }
      });
    },
  },
};
</script>
