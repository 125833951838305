// select2-plugin.js
import $ from 'jquery';
import 'select2';

export default {
  install(app) {
    app.directive('select2', {
      mounted(el) {
        $(el).select2();
      },
      updated(el) {
        $(el).select2('destroy').select2();
      }
    });
  }
};