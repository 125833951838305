<template>
  <form @submit.prevent="submitForm" id="chamadoForm">
    <div class="row">

      <div class="col-12">
        <div class="row">

          <div class="col-md-4">
            <div class="form-group">
              <label class="form-label" for="inputNome">Nome:</label>
              <input type="text" class="form-control" name="inputNome" id="inputNome" v-model="form.nome" />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label class="form-label" for="inputDocumento">CPF/CNPJ:</label>
              <input type="text" class="form-control" name="inputDocumento" id="inputDocumento"
                v-model="form.documento_id" v-mask="['###.###.###-##', '##.###.###/####-##']" />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label class="form-label" for="inputHaras">Haras/Fazenda:</label>
              <input type="text" class="form-control" name="inputHaras" id="inputHaras" maxlength="100"
                v-model="form.haras" />
            </div>
          </div>

          <div class="col-md-4 mt-2">
            <div class="form-group">
              <label class="form-label" for="inputCelular">Celular*:</label>
              <input required type="phone" class="form-control" name="inputCelular" id="inputCelular"
                v-model="form.celular" v-mask="'+## (##) #####-####'" />
              <span class="text-danger" v-if="errors.phone">{{ errors.phone[0] }}</span>
            </div>
          </div>

          <div class="col-md-4 mt-2">
            <div class="form-group">
              <label class="form-label" for="inputCelular">E-mail:</label>
              <input type="email" class="form-control" name="inputCelular" id="inputCelular" v-model="form.email" />
              <span class="text-danger" v-if="errors.email">{{ errors.email[0] }}</span>
            </div>
          </div>

          <div class="col-md-4 mt-2">
            <label class="form-label" for="clientesVinculados">Clientes Vinculados:</label>
            <select required name="clientesVinculados" id="clientesVinculados" class="select2-cadClientes form-control"
              v-model="form.clientes_vinculados" v-select="form.clientes_vinculados" multiple>
              <option v-for="cliente in optionsClientes.values" :value="cliente.ID" :key="cliente.ID">
                {{ cliente.NOME }} - {{ cliente.TELEFONE }}
              </option>
            </select>
          </div>

          <div class="col-md-12 mt-2">
            <div class="form-group">
              <label class="form-label" for="inputObservacoes">Observações</label>
              <textarea name="inputObservacoes" class="form-control" id="inputObservacoes" v-model="form.observacoes"
                rows="2" />
            </div>
          </div>

        </div>
      </div>

      <div class="col-12 custom-height-kanban mt-3" v-show="toggleKanban">
        <div class="col-12 mt-2 h-100">
          <div class="row h-100" style="height: 100%">
            <div class="col-4 h-100">
              <div class="w-100 p-1 h-100">
                <div>
                  <h5 class="m-0">Abertos</h5>
                  <hr class="m-0">
                </div>
                <div v-if="form.chamados.abertos.length > 0" class="list-card-kanban">
                  <ul id="js-msgr-listfilter" class="list-unstyled m-0 js-list-filter py-2">
                    <AtendimentoCardList v-for="atendimento in form.chamados.abertos" :atendimento="atendimento"
                      :key="atendimento.id" @click="editChamado(atendimento.id)" />
                  </ul>
                </div>
                <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
                <NenhumAtendimentoEncontado v-else />
              </div>
            </div>
            <div class="col-4 h-100">
              <div class="w-100 p-1 h-100">
                <div>
                  <h5 class="m-0">Em Andamento</h5>
                  <hr class="m-0">
                </div>
                <div v-if="form.chamados.andamento.length > 0" class="list-card-kanban">
                  <ul id="js-msgr-listfilter" class="list-unstyled m-0 js-list-filter py-2">
                    <AtendimentoCardList v-for="atendimento in form.chamados.andamento" :atendimento="atendimento"
                      :key="atendimento.id" @click="editChamado(atendimento.id)" />
                  </ul>
                </div>
                <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
                <NenhumAtendimentoEncontado v-else />
              </div>
            </div>
            <div class="col-4 h-100">
              <div class="w-100 p-1 h-100">
                <div>
                  <h5 class="m-0">Fechados</h5>
                  <hr class="m-0">
                </div>
                <div v-if="form.chamados.fechados.length > 0" class="list-card-kanban">
                  <ul id="js-msgr-listfilter" class="list-unstyled m-0 js-list-filter py-2">
                    <AtendimentoCardList v-for="atendimento in form.chamados.fechados" :atendimento="atendimento"
                      :key="atendimento.id" @click="editChamado(atendimento.id)" />
                  </ul>
                </div>
                <!-- <div class="nav-title m-0 px-3 text-muted">Atendimentos</div> -->
                <NenhumAtendimentoEncontado v-else />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { api } from "@/axios";
import $ from "jquery";
import swal from 'sweetalert2';
import AtendimentoCardList from "@/components/Home/AtendimentoCardList.vue";
import NenhumAtendimentoEncontado from "@/components/generals/NenhumAtendimentoEncontado2x.vue";

export default {
  name: 'ClientesForm',
  components: {
    AtendimentoCardList,
    NenhumAtendimentoEncontado
  },
  mounted() {
    this.toggleKanban = ![null, undefined, ''].includes(this.modelValue);

    $(".select2-cadClientes").each(function () {
      $(this).select2({
        dropdownParent: $(this).parent()
      })
    })

    this.getClientes(this.modelValue)

  },
  data() {
    return {
      form: {
        nome: '',
        haras: '',
        celular: '',
        email: '',
        documento_id: '',
        observacoes: '',
        chamados: {
          abertos: [],
          andamento: [],
          fechados: []
        },
        clientes_vinculados: [],
      },
      optionsEmpresa: [],
      toggleKanban: false,
      optionsClientes: { values: [] },
      errors: [],
    }
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
  },

  methods: {
    /**
     * Emite um evento para alternar a exibição de um chamado (caso).
     *
     * @param {Object} chamado - O objeto do chamado (caso) a ser alternado.
     * @emits toggle - Emite o evento 'toggle' com o objeto do chamado.
     */
    editChamado(chamado) {
      this.$emit("toggle", chamado)
    },


    /**
     * Formata um número removendo todos os caracteres não numéricos.
     *
     * @param {string} data - Dado que será formatado.
     * @returns {string} O número do dado informado formatado.
     */
    formatMask(data) {
      return data?.replace(/[+\-\s().]/g, '') || "";
    },


    /**
     * Envia os dados do formulário para o servidor.
     *
     * Se a prop `modelValue` for nula, criará um novo cliente enviando uma requisição POST para o endpoint `/api/clientes`.
     * Se a prop `modelValue` não for nula, atualizará um cliente existente enviando uma requisição PUT para o endpoint `/api/clientes/{modelValue}`.
     *
     * Os dados do formulário incluem o nome do cliente, número de telefone (formatado), ID da empresa e e-mail.
     *
     * Se a requisição for bem-sucedida, emitirá um evento `newCliente` com o número de telefone formatado e o ID do cliente.
     * Se a requisição falhar, exibirá uma mensagem de erro usando `swal.fire`.
     *
     * @returns {Promise<boolean>}
     */
    async submitForm() {
      const objetoRequisicao = {
        nome: this.form.nome,
        phone: this.formatMask(this.form.celular),
        haras: this.form.haras,
        email: this.form.email,
        documento_id: this.formatMask(this.form.documento_id),
        observacoes: this.form.observacoes,
        clientes_vinculados: $("select[name=clientesVinculados]").val()
      }

      try {
        let response = "";

        if ([null, undefined, ''].includes(this.modelValue)) {
          response = await api.post(`/api/clientes`, objetoRequisicao);
        }
        else {
          response = await api.put(`/api/clientes/${this.modelValue}`, objetoRequisicao);
        }

        if (response.data.success) {
          swal.fire({
            title: 'Sucesso!',
            text: response?.data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.$emit("newCliente", response.data.id);
        }
        else {
          swal.fire({
            title: 'Ops!',
            text: response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }

        return true;

      } catch (error) {
        if (error.response) {
          const status = error.response.data.status;
          const errors = error.response.data.errors;

          if (status === 'validation_error') {
            // Exibe erros de validação
            this.handleErrors(errors);
          }

        } else {
          // Outro tipo de erro (ex: rede, timeout)
          console.log('Erro inesperado', error);
        }

        return false;
      }
    },
    handleErrors(errors) {
      this.errors = errors
    },


    /**
     * Recupera os detalhes de um cliente pelo seu ID.
     *
     * @param {string} idCliente - O ID do cliente a ser recuperado.
     * @returns {Promise<boolean>} - Uma Promise que é resolvida com `true` se a busca for bem-sucedida, ou `false` caso contrário.
     */
    async getCliente(idCliente) {
      if ([null, undefined, ""].includes(idCliente)) return true;

      try {
        const response = await api.get(`/api/clientes/${idCliente}`);

        if (!response.data.success) {
          swal.fire({
            title: 'Ops!',
            text: response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          });
          return false;
        }

        const dados = response.data.data;
        console.log("%c✔ Requisição feita com sucesso! Os dados que irão preencher os campos do modal serão:", "color: #148f32");
        console.table(response.data.data);
        console.log('Dados que voltaram da requisição:', response.data.data);

        this.form.nome = dados.nome;
        this.form.haras = dados.haras_fazenda;
        this.form.celular = dados.phone;
        this.form.email = dados.email;
        this.form.documento_id = dados.documento_id;
        this.form.observacoes = dados.observacoes;
        this.form.clientes_vinculados = dados.clientes_vinculados.map(cliente => cliente.cliente_vinculado_id);

        dados.chamados.map(atendimento => atendimento.dateCriacao = new Date(atendimento.created_at));

        this.form.chamados.abertos = dados.chamados.filter(chamado => chamado.status_id == 1);
        this.form.chamados.andamento = dados.chamados.filter(chamado => chamado.status_id == 2);
        this.form.chamados.fechados = dados.chamados.filter(chamado => chamado.status_id == 3);

        return true;
      } catch (e) {
        console.log('%c Erro: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e);
        return false;
      }
    },


    /**
    * Busca a lista de clientes da API e atualiza a propriedade `optionsClientes.values` com os dados da resposta.
    *
    * Este método é usado para preencher as opções de seleção de clientes no formulário.
    *
    * @returns {Promise<void>} - Uma Promise que é resolvida quando os dados dos clientes foram buscados e armazenados.
    */
    async getClientes(idCliente) {
      try {
        const response = await api.get(`/api/clientes`);
        if (response.data.success) {
          console.log("%c✔ Requisição feita com sucesso! Os dados que irão preencher o select serão:", "color: #148f32");
          console.table(response.data.data);
          console.log('Dados que voltaram da requisição:', response.data.data);
          // REMOVENDO O PRÓPRIO CLIENTE DO ARRAY DE CLIENTES PARA AS OPÇÕES DO SELECT NÃO CONTER O MESMO
          this.optionsClientes.values = response.data.data.filter(cliente => cliente.ID != idCliente);

        }
      } catch (e) {
        console.log('%c Erro ao buscar clientes: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e);
      }
    },
  }
}
</script>

<style scoped>
.custom-height-kanban {
  max-height: calc(100vh - 548px);
  min-height: 328px;
}

.custom-height-kanban .list-card-kanban {
  height: 100%;
  overflow-y: scroll;
}
</style>