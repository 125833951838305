<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-content-wrapper">
        <main id="js-page-content" role="main" class="page-content">
          <div class="d-flex flex-grow-1 p-0">
            <div
                class="flex-wrap flex-shrink-0 position-relative slide-on-mobile slide-on-mobile-left bg-body p-3 bg-white mr-2"
                id="js-slide-left">
              <div class="row fullsize-table" style="overflow-y: auto; overflow-x: hidden">
                <div class="col-12 bg-body stickTop">
                  <div class="col-12 p-0">
                    <button class="btn btn-info w-100" id="setores-new" @click="showFormSetor = true; loadingSetor = false">
                      <i class="fal fa-1x fa-sitemap mr-1"></i>
                      Novo Setor
                    </button>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <form @submit.prevent="handleSubmit" id="filterForm" class="row">
                    <div class="col-12 mt-2">
                      <label for="filter-search" class="form-label">Pesquisar por Palavra-Chave</label>
                      <input type="search" autocomplete="off" class="form-control " data-table-api="search" id="filter-search" v-model="searchQuery">
                    </div>
                    <div class="col-12 mt-2">
                      <label class="form-label" for="situacaoChamados">
                        Situação de Chamados
                      </label>
                      <select class="select2 form-control w-100" id="situacaoChamados" v-model="situacao"
                              v-select="situacao">
                        <option value="">Todos</option>
                        <option value="1">Aberto</option>
                        <option value="2">Em Andamento</option>
                        <option value="3">Fechados</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div class="col-12 stickBottom bg-body mt-2">
                  <div class="row mt-2">
                    <div class="col-8">
                      <button class="btn btn-success mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                              type="submit" form="filterForm" id="btn-filter"><i
                          class="fal fa-search mr-1 mt-1"></i>
                        Pesquisar
                      </button>
                    </div>
                    <div class="col-4">
                      <button class="btn btn-light mr-1 mt-2 w-100 waves-effect waves-themed d-block"
                              data-toggle="tooltip" title="" data-placement="top" type="button" id="btn-clear-filter"
                              data-original-title="Limpar Filtro" @click="clearFilter"><i class="fal fa-times mt-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="slide-backdrop" data-action="toggle" data-class="slide-on-mobile-left-show"
                   data-target="#js-slide-left"></div>
            </div>
            <div class="d-flex flex-column flex-grow-1 overflow-hidden bg-white">
              <div class="row h-100">
                <div class="col-12">
                  <div class="panel h-100">
                    <div class="panel-hdr">
                      <h2>
                        <i class="subheader-icon fal fa-sitemap"></i> <span class="fw-300">Setores</span>
                      </h2>
                    </div>
                    <div class="panel-container show h-100">
                      <div class="panel-content p-0 h-100">
                        <DataTable v-if="!loading" class="table table-bordered table-hover table-striped w-100 mb-0" ref="dataTable"
                                   :options="options"
                                   :columns="columns"
                                   :data="data"
                                   @row-reorder="rowreorder">
                          <thead>
                          <tr>
                            <th style="white-space: nowrap" class="text-center">*</th>
                            <th style="white-space: nowrap" class="text-center">Nome</th>
                            <th style="white-space: nowrap" class="text-center">Colaboradores</th>
                            <th style="white-space: nowrap" class="text-center">Chamados Abertos</th>
                            <th style="white-space: nowrap" class="text-center">Chamados em Andamento</th>
                            <th style="white-space: nowrap" class="text-center">Chamados Fechados</th>
                            <th style="white-space: nowrap" class="text-center">Ações</th>
                          </tr>
                          </thead>
                          <tbody style="text-align: center">
                          </tbody>
                        </DataTable>
                        <Loading v-else/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>

  <ModalCenteredLarge :show="showFormSetor" :title="currentSetor ? 'Editar Setor' : 'Novo Setor'"
                      @close="hideForm()" @save="submitSetor" :loading="loadingSetor">
    <SetorForm ref="setorFormRef" :modelValue="currentSetor"
               :submitChamado="submitSetor"/>
  </ModalCenteredLarge>
</template>

<script>
/**
 * Importa os componentes e bibliotecas necessários para o componente SetoresPage.
 * - `defineComponent` do 'vue' é utilizado para definir o componente Vue.
 * - `DataTable` e `DataTablesCore` são importados das bibliotecas 'datatables.net-vue3' e 'datatables.net' respectivamente, que são usadas para criar uma tabela de dados.
 * - `'datatables.net-scroller-bs4'` é importado para funcionalidades adicionais.
 * - `{api}` é importado de '@/axios' para lidar com requisições de API.
 * - `AsideNav`, `HeaderMain` e `BreadcrumbMain` são importados como componentes filhos.
 */
import {defineComponent} from 'vue';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import 'datatables.net-scroller-bs4';
import {api} from "@/axios";
import Loading from '@/components/generals/LoadingDefault.vue';
import $ from "jquery";
import ModalCenteredLarge from "@/components/modals/ModalCenteredLarge.vue";
import SetorForm from "@/components/Setors/forms/SetorForm.vue";
import swal from "sweetalert2";
import 'datatables.net-rowreorder';


DataTable.use(DataTablesCore);

/**
 * Define o componente SetoresPage, responsável por renderizar uma tabela de dados de setores.
 *
 * O componente utiliza o componente DataTable da biblioteca 'datatables.net-vue3' para exibir os dados dos setores.
 * Os dados são buscados da API utilizando o módulo `api` de '@/axios'.
 *
 * O componente possui as seguintes funcionalidades:
 * - Exibe uma tabela com colunas para nome, colaboradores, e chamados abertos/em andamento/fechados.
 * - Fornece botões para editar.
 * - Permite filtrar os dados dos setores por consulta de pesquisa e status.
 * - Gerencia a lógica para buscar e atualizar os dados dos setores.
 */
export default defineComponent({
  name: 'SetoresPage',
  components: {
    SetorForm,
    ModalCenteredLarge,
    DataTable,
    Loading
  },
  /**
   * Define as propriedades de dados e opções para o componente SetoresPage.
   *
   * - `data`: Armazena os dados para a tabela de setores.
   * - `options`: Configura as opções para o componente DataTable, incluindo configurações de idioma.
   * - `columns`: Define as colunas para a tabela de setores, incluindo funções de renderização para cada coluna.
   * - `searchQuery`: Armazena a consulta de pesquisa para filtrar os setores.
   * - `situacao`: Armazena o filtro de status para os setores.
   */
  data()
  {
    return {
      loadingSetor: true,
      currentSetor: "",
      showFormSetor: false,
      setorFormRef: "",
      data: [],
      options: {
        // rowReorder: {
        //   selector: 'td:eq(0)',
        //   update: true
        // },
        paging: false,
        pageLength: -1,
        dom: 't',
        language: {
          "emptyTable": "Nenhum Resultado Encontrado",
          "zeroRecords": "Nenhum Resultado Encontrado",
          "decimal": ",",
          "info": "Mostrando _START_ a _END_ de _TOTAL_ resultados",
          "infoEmpty": "Nenhum Resultado Encontrado",
          "thousands": ".",
          "loadingRecords": "Carregando...",
          "processing": "",
          "search": "",
          "searchPlaceholder": "Pesquisar",
          "aria": {
            "orderable": "Ordenar por essa coluna",
            "orderableReverse": "Ordem inversa desta coluna"
          }
        }
      },
      columns: [
    {
      data: 'ID',
      render: function (data, type, row, meta) {
        return meta.row + 1;
      }
    },
    {
      data: "NOME",
      render: function (data) {
        return data ? data.toUpperCase() : '';
      }
    },
    {
      data: "COLABORADOES",
    },
    {
      data: "CHAMADOS_ABERTOS",
    },
    {
      data: "CHAMADOS_EM_ANDAMENTO",
    },
    {
      data: "CHAMADOS_FECHADOS",
    },
    {
      data: null,
      render: function (data, type, row) {
        return `<div style="white-space: nowrap">
                        <button onclick="window.dispatchEvent(new CustomEvent('edit-setor', { detail: ${row.ID} }))" class="btn btn-sm btn-light btn-icon rounded-circle waves-effect waves-themed mr-2"
                                      data-toggle="tooltip" data-original-title="Editar Setor">
                                <i class="fal fa-pencil"></i>
                        </button>
                        <button onclick="window.dispatchEvent(new CustomEvent('delete-setor', { detail: ${row.ID} }))" class="btn btn-sm btn-outline-danger btn-icon rounded-circle waves-effect waves-themed"
                                    data-toggle="tooltip" data-original-title="Excluir Cliente">
                                <i class="fal fa-times"></i>
                        </button>
                    </div>`;
      }
    },
  ]
      ,
      searchQuery: "",
      situacao: "",
      loading: true,
    }
  },
  /**
   * Inicializa a biblioteca select2 em todos os elementos com a classe 'select2'.
   * Adiciona um ouvinte de evento para o evento 'edit-setor', que chama o método `handleEdit`.
   * Chama o método `handleSubmit` para buscar e atualizar os dados da tabela de Setores.
   */
  mounted()
  {
    $('.select2').select2();

    window.addEventListener('edit-setor', this.handleEdit);
    window.addEventListener('delete-setor', this.handleDelete);

    this.handleSubmit()
  },
  /**
   * Remove o ouvinte de evento para o evento 'edit-setor' antes que o componente seja desmontado.
   */
  beforeUnmount()
  {
    window.removeEventListener('edit-setor', this.handleEdit);
    window.removeEventListener('delete-setor', this.handleDelete);
  },
  methods: {
    /**
     * Manipula o evento de reordenação de linhas em uma tabela.
     * Este método é chamado quando o usuário arrasta e solta uma linha para reordená-la.
     * Ele percorre as linhas que foram reordenadas, extrai o ID da linha
     * e registra a nova posição da linha no console.
     * Este método não atualiza efetivamente os dados nem persiste a nova ordem.
     *
     * @param {Event} e - O objeto do evento de reordenação da linha.
     * @param {Array} diff - Um array de objetos contendo informações sobre as linhas que foram reordenadas.
     */
    rowreorder(e, diff) {
      for ( var i=0, ien=diff.length ; i<ien ; i++ ) {
        console.log(diff[i].node)
        let idQ = diff[i].node.id;
        let idNewQ = idQ.replace("row_", "");
        let position = diff[i].newPosition+1;

        this.atualizaOrdemOpcosMenu(idNewQ, position);
      }
    },


    clearFilter()
    {
      this.situacao = ""
      this.searchQuery = ""

      this.handleSubmit();
    },


    atualizaOrdemOpcosMenu(posicoesAntigas, posicoesAtuais)
    {
      try
      {
        api.post(`/api/setores/ordem`, {
          posicoesAntigas: JSON.stringify(posicoesAntigas) ,
          posicoesAtuais: JSON.stringify(posicoesAtuais)
        }).then(response =>
        {
          if (!response.data.success)
          {
            swal.fire({
              title: 'Ops!',
              text: response.data.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            });
            return;
          }


        })
      } catch (e)
      {
        console.log('%c Erro: ', 'color:hsl(0, 100%, 90%);background-color:hsl(0, 100%, 50%);', e);
      }
    },


    /**
     * Envia o formulário de setor.
     * Se o componente setorFormRef tiver um método submitForm, ele será chamado para enviar o formulário.
     * Caso contrário, um erro será registrado no console.
     */
    submitSetor()
    {
      const setorForm = this.$refs.setorFormRef;
      this.loadingSetor = true;

      if (setorForm && typeof setorForm.submitForm === 'function')
      {
        setorForm.submitForm().then(response => {
          this.loadingSetor = false;
          if(response)
          {
            this.hideForm();
          }
        });
      }
      else
      {
        console.error("submitForm não está disponível no chamadoForm");
      }
    },


    /**
     * Oculta o formulário de Setor e atualiza os dados do Setor.
     * Este método é chamado quando o usuário deseja fechar o formulário de Setor sem salvar as alterações.
     * Ele define a propriedade `currentSetor` como `null`, oculta o formulário definindo `showFormSetor` como `false`,
     * e em seguida chama o método `handleSubmit` para atualizar os dados do Setor exibidos na tabela.
     */
    hideForm()
    {
      this.currentSetor = null;
      this.showFormSetor = false;
      this.loadingSetor = true;

      this.handleSubmit();
    },


    /**
     * Obtém os dados da tabela de Setores e atualiza a propriedade de dados do componente quando o componente é montado.
     * Este método é responsável por fazer a requisição à API para recuperar os dados dos Setores, lidar com quaisquer erros que possam ocorrer,
     * e atualizar a propriedade de dados do componente com os dados da resposta.
     */
    async handleSubmit()
    {
      this.loading = true;
      try
      {
        const keyword = this.searchQuery || '';
        const status_id = this.situacao || '';

        const response = await api.get('/api/setores', {
          params: {
            keyword: keyword,
            status_id: status_id
          }
        });

        if (response.data.success)
        {
          console.log("%c✔ Requisição feita com sucesso! Os dados que irão preencher a tabela serão:", "color: #148f32");
          console.table(response.data.data)
          console.log('Dados que voltaram da requisição:', this.data);
        }

        this.data = response.data.data;

        this.loading = false;
      } catch (error)
      {
        console.error('Erro ao buscar Setors:', error);
      }
    },


    /**
     * Lida com a edição de um Setor.
     * Quando chamado, este método define a propriedade `currentSetor` com os detalhes do `setorId` fornecido e define a propriedade `showFormSetor` como `true` para exibir o formulário de Setor.
     * @param {object} setorId - O ID e os detalhes do Setor a ser editado.
     */
    handleEdit(setorId)
    {
      this.currentSetor = setorId.detail;
      this.showFormSetor = true;

      this.$nextTick(() =>
      {
        this.$refs.setorFormRef.getSetor(this.currentSetor).then(_ =>
        {
          this.loadingSetor = false;
        })
      });
    },


    /**
     * Lida com a exclusão de um setor.
     * Este método exibe uma caixa de diálogo de confirmação para o usuário e, se confirmado, envia uma requisição DELETE para a API para excluir o setor especificado. Se a exclusão for bem-sucedida, chama o método `handleSubmit` para atualizar os dados dos setores.
     * @param {object} setorId - O ID e os detalhes do setor a ser excluído.
     */
    handleDelete(setorId)
    {
      setorId = setorId.detail
      swal.fire({
        title: 'Atenção!',
        text: "Deseja realmente excluir este setor?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        reverseButtons: true,
      }).then(async response =>
      {
        if (!response.value) return;

        const retorno = await api.delete(`/api/setores/${setorId}`, {});

        if (!retorno.data.success)
        {
          swal.fire({
            title: 'Ops!',
            text: retorno.data.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          return;
        }

        swal.fire({
          title: 'Sucesso!',
          text: retorno.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        })

        this.handleSubmit();
      });
    }
  },
});
</script>
<style scoped>
@import 'datatables.net-rowreorder-dt/css/rowReorder.dataTables.css';

:deep(.dataTables_wrapper) {
  max-height: calc(100vh - 245px) !important;
  overflow: auto;
}

:deep(.fullsize-table) {
  max-height: calc(100vh - 205px);
}
</style>